import { useState } from 'react';
import { Box, LoaderOverlay } from '@common-components';
import { useMutatePromptResult } from 'hooks';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import SubmissionEmailEditor from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditor';
import SubmissionEmailEditorWithAI from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditorWithAI';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EmailEditorPageLocationState } from './types';
import useGetEmailEditorProps from './useGetEmailEditorProps';

interface ContentProps {
  locationState: EmailEditorPageLocationState;
}

export default function Content({ locationState }: ContentProps) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { title, subtitle, isLoading, aiFeatureProps, ...emailEditorProps } = useGetEmailEditorProps(
    locationState,
    partialSubmission || undefined,
  );
  const [aiGeneratedBodyContent, setAiGeneratedBodyContent] = useState<string | undefined>(undefined);
  const { createPromptResult } = useMutatePromptResult();

  if (isLoading) {
    return <LoaderOverlay />;
  }

  const innerPageJsx = (
    <InnerPageLayout
      className="cap-email-editor-page"
      title={title}
      subTitle={subtitle}
      footer={(innerPageProps) => <EmailEditorFooter {...innerPageProps} />}
      sx={{ p: 0 }}
    >
      {() => (
        <Box position="relative" height={1}>
          {aiFeatureProps && <AIButtonWithPopup top={148} right={37} {...aiFeatureProps} />}
          <EmailEditor />
        </Box>
      )}
    </InnerPageLayout>
  );

  return aiFeatureProps ? (
    <SubmissionEmailEditorWithAI
      isSkipAllowed
      emailEditorProps={(bodyContent) => ({
        ...emailEditorProps,
        repliedThreadSubject: aiFeatureProps.subject,
        repliedThreadBody: bodyContent,
        postEmailsSent: async (postEmailInformation: PostEmailInformation) => {
          if (aiGeneratedBodyContent !== undefined) {
            await createPromptResult.mutateAsync({
              data: {
                result: aiGeneratedBodyContent,
                input: aiFeatureProps.promptResultFeature.input,
                modifiedResult: postEmailInformation.draftPreview.body,
                feature: aiFeatureProps.promptResultFeature.type,
                submissionId: partialSubmission!.id,
              },
            });
          }
        },
      })}
      aiThread={{
        createThread: aiFeatureProps.createThread,
        callback: (generatedContent) => {
          setAiGeneratedBodyContent(generatedContent);
        },
      }}
      title={aiFeatureProps.popupTitle}
      isLoading={!partialSubmission}
      contentDecorator={aiFeatureProps.contentDecorator}
    >
      {innerPageJsx}
    </SubmissionEmailEditorWithAI>
  ) : (
    <SubmissionEmailEditor emailEditorProps={emailEditorProps}>{innerPageJsx}</SubmissionEmailEditor>
  );
}
