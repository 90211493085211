import moment, { Moment } from 'moment';
import { FocusEvent, memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  DesktopDatePicker,
  DesktopDatePickerProps,
  FormControl,
  FormFieldRequiredLabel,
  FormHelperText,
  OutlinedInput,
} from '@common-components';
import { messages } from 'i18n';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import { datePickerDateFormat, DisplayYearFormat, getTimezoneLessDisplayDate, normalizeError } from 'utils';
import DefaultOverlay from 'components/DefaultOverlay';
import FormFieldSuggestionOverlay from 'components/FormFieldSuggestionOverlay';
import { FormTextFieldProps } from './FormTextField';
import InputLabel from './InputLabel';
import { FormHelperTextStyles } from './styles';
import { SuggestionProps } from './types';

export interface FormDatePickerProps
  extends Omit<FormTextFieldProps, 'onBlur'>,
    Pick<DesktopDatePickerProps, 'disableFuture'> {
  disableFuture?: boolean;
  yearsOnly?: boolean;
  onChange?: () => boolean | undefined;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement>) => boolean | undefined;
  helperText?: string;
  enhancedRequired?: boolean;
}

const FormDatePicker = memo(
  ({
    name,
    id,
    label = 'Date',
    optional = false,
    placeholder = messages.general.inputPlaceholder,
    disableFuture = false,
    yearsOnly = false,
    disabled = false,
    onBlur,
    onChange,
    helperText,
    defaultValueOverlayText,
    suggestion,
    enhancedRequired,
  }: FormDatePickerProps) => {
    const {
      register,
      setValue,
      watch,
      formState: { errors },
      trigger,
    } = useFormContext();

    const [date, setDate] = useState<Date | null>(null);
    const [dateOpen, setDateOpen] = useState(false);

    const value = watch(name);

    const errorMessage = normalizeError(errors, name);
    const formHelperText = errorMessage || helperText;
    const [ownSuggestion, setOwnSuggestion] = useState<SuggestionProps | undefined>(suggestion);
    const [ownDefaultOverlayText, setOwnDefaultOverlayText] = useState<string | undefined>(defaultValueOverlayText);

    useEffect(() => {
      setOwnSuggestion(suggestion);
    }, [suggestion]);

    useEffect(() => {
      setDate(value ? new Date(value) : null);
    }, [value, name, errorMessage]);

    useEffect(() => {
      register(name);
    }, [name, register]);

    const onDateChange = useCallback(
      (nextDate: Moment | null) => {
        const options = { shouldValidate: true, shouldDirty: true };

        if (nextDate) {
          // The DesktopDatePicker provides the date in the local timezone, but we want to treat it as UTC.
          const dateAsString = nextDate.format(datePickerDateFormat);
          const utcDate = moment.utc(dateAsString, datePickerDateFormat);

          setValue(name, yearsOnly ? utcDate.format(DisplayYearFormat) : utcDate.toISOString(), options);
        } else {
          setValue(name, '', options);
        }

        if (errorMessage) {
          trigger(name);
        }
        onChange?.();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [name, setValue, errorMessage],
    );

    const handleClickAway = () => {
      if (dateOpen) {
        setDateOpen(false);
      }
    };

    return (
      <DesktopDatePicker
        views={yearsOnly ? ['year'] : undefined}
        value={moment.utc(date)}
        label={label}
        open={!disabled && dateOpen}
        onClose={() => setDateOpen(false)}
        onChange={onDateChange}
        inputFormat={yearsOnly ? DisplayYearFormat : datePickerDateFormat}
        disableFuture={disableFuture}
        disabled={disabled}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <FormControl
            sx={{
              pb: FormControlPaddingBottom,
              mb: formHelperText ? FormControlHelperTextMarginBottom : 0,
              height: 1,
              justifyContent: 'flex-start',
            }}
            fullWidth
          >
            <InputLabel label={label} error={!!errorMessage} optional={optional} htmlFor={name} />
            <Box
              display={dateOpen ? 'block' : 'none'}
              position="fixed"
              zIndex={(theme) => theme.zIndex.modal - 1}
              top={0}
              left={0}
              right={0}
              bottom={0}
              onClick={handleClickAway}
            />
            <Box position="relative">
              <OutlinedInput
                size="small"
                autoComplete="off"
                onClick={() => setDateOpen(!dateOpen)}
                inputRef={inputRef}
                disabled={disabled}
                sx={{
                  width: '100%',
                  typography: 'body2',
                  '& .Mui-disabled': {
                    color: 'inherit',
                    WebkitTextFillColor: (theme) => theme.palette.common.black,
                    backgroundColor: (theme) => theme.palette.grey[100],
                  },
                }}
                error={!!errorMessage}
                onChange={() => {}}
                placeholder={placeholder}
                inputProps={{
                  ...inputProps,
                  placeholder,
                  id,
                }}
                classes={{ input: 'data-hj-allow' }}
                {...InputProps}
                onBlur={(e) => {
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
              />
              {/* don't render default overlay if we have a suggestion */}
              {!disabled && !suggestion && ownDefaultOverlayText && (
                <DefaultOverlay
                  text={ownDefaultOverlayText}
                  onClick={() => {
                    setOwnDefaultOverlayText(undefined);
                  }}
                />
              )}
              {!disabled && ownSuggestion?.value && (
                <FormFieldSuggestionOverlay
                  text={getTimezoneLessDisplayDate(ownSuggestion.value)}
                  onClick={() => {
                    setOwnSuggestion(undefined);
                  }}
                />
              )}
              {enhancedRequired && !optional && !ownSuggestion?.value && !value && (
                <FormFieldRequiredLabel shiftLeft={24} shiftTop={-2} />
              )}
            </Box>
            {formHelperText && (
              <FormHelperText sx={FormHelperTextStyles} error={!!errorMessage}>
                {formHelperText}
              </FormHelperText>
            )}
            {ownSuggestion?.reason && (
              <FormHelperText sx={FormHelperTextStyles}>{ownSuggestion.reason} </FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  },
);
export default FormDatePicker;
