import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { messages } from 'i18n';
import { getCoverageLinesText, getDisplayDate } from 'utils';
import { MarketingUpdateStatus, marketingUpdateStatusConfig } from 'broker/enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useGetMarketingUpdateItems } from './useGetMarketingUpdateItems';

export function useGenerateTable() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const marketingUpdateItemsMap = useGetMarketingUpdateItems();
  const {
    title,
    header: { market, status, coverageLines, additionalInfo },
  } = messages.marketingUpdate.generateTable;

  const html = useMemo(() => {
    const pdtTitle = `${title} - ${getDisplayDate(new Date())}`;
    const pdfSubTitle = `${partialSubmission?.insuredName || ''} • ${getCoverageLinesText(
      partialSubmission?.coverageLines,
    )}`;

    let tableHtml = `
    <h4 style="margin: 0 0 0 0;">${pdtTitle}</h4>
    <h5 style="margin: 0 0 8px 0;">${pdfSubTitle}</h5>
    <table border="1" cellpadding="10" cellspacing="0">
      <thead>
        <tr>
          <th style="font-weight: bold;">${market}</th>
          <th style="font-weight: bold;">${status}</th>
            <th style="font-weight: bold;">${coverageLines}</th>
          <th style="font-weight: bold;">${additionalInfo}</th>
        </tr>
      </thead>
      <tbody>
  `;

    Object.values(MarketingUpdateStatus).forEach((marketingUpdateStatus) => {
      const statusItems = marketingUpdateItemsMap[marketingUpdateStatus];
      if (!isEmpty(statusItems)) {
        tableHtml += `
        <tr>
          <td style="background-color: #f0f0f0; font-weight: bold;">${marketingUpdateStatusConfig[marketingUpdateStatus].text}</td>
          <td style="background-color: #f0f0f0;"></td>
          <td style="background-color: #f0f0f0;"></td>
          <td style="background-color: #f0f0f0;"></td>
        </tr>
      `;

        statusItems.forEach((item) => {
          tableHtml += `
          <tr>
            <td>${item.marketName}</td>
            <td>${item.details || ''}</td>
            <td>${item.coverageLines || ''}</td>
            <td>${item.additionalInfo || ''}</td>
          </tr>
        `;
        });
      }
    });

    tableHtml += `
      </tbody>
    </table>
  `;

    return tableHtml;
  }, [
    title,
    partialSubmission?.insuredName,
    partialSubmission?.coverageLines,
    market,
    status,
    coverageLines,
    additionalInfo,
    marketingUpdateItemsMap,
  ]);

  return html;
}
