import { SubmissionTask, SubmissionTaskStatus } from 'enums';
import { AICreateThreadOutput, Submission } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export interface SubmissionStatusUpdateData {
  submissionId: string;
  task: SubmissionTask;
  status: SubmissionTaskStatus;
}

export interface ArfSentData {
  submissionId: string;
}

export interface ArfRequestData {
  submissionId: string;
}

export interface AcknowledgmentContentRequestData {
  submissionId: string;
  selectedProductIds: string[];
}

export interface MarketingEmailContentRequestData {
  submissionId: string;
}

export interface SuggestedProductsRequestData {
  submissionId: string;
}

export interface RiskAssessmentRequestData {
  submissionId: string;
}

export interface AcknowledgmentContentResponse {
  generatedContent: string;
  prompt: string;
}

export interface SuggestedProductsResponse {
  productsRecommendation: string;
  prompt: string;
}

export interface MarketingUpdateContentRequestData {
  submissionId: string;
  marketingPdfFileId: string;
}

export class SubmissionClient extends CapitolaClient<Submission> {
  public get resourceName(): string {
    return 'submission';
  }

  public async getBoxFolderEmail(submissionId: string): Promise<{ folderEmailAddress?: string } | null> {
    return this.custom({
      httpMethod: HttpMethods.GET,
      path: `${submissionId}/box-folder-email`,
      fetchOptions: { throwOnError: true },
    });
  }

  public async updateSubmissionTasks(data: SubmissionStatusUpdateData): Promise<{} | null> {
    return this.custom({
      httpMethod: HttpMethods.PATCH,
      body: { ...data },
      path: `tasks`,
      fetchOptions: { throwOnError: true },
    });
  }

  public async sendArfRequest(data: ArfRequestData): Promise<{} | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `arf-request`,
      fetchOptions: { throwOnError: true },
    });
  }

  public async arfSent(data: ArfSentData): Promise<{} | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `arf-sent`,
      fetchOptions: { throwOnError: true },
    });
  }

  public async generateAcknowledgmentContent(
    data: AcknowledgmentContentRequestData,
  ): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `generate-acknowledgment-content`,
      fetchOptions: { throwOnError: false },
    });
  }

  public async generateEmailMarketingContent(
    data: MarketingEmailContentRequestData,
  ): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `generate-marketing-email`,
      fetchOptions: { throwOnError: false },
    });
  }

  public async generateSuggestedProductsContent(
    data: SuggestedProductsRequestData,
  ): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `products-recommendation`,
      fetchOptions: { throwOnError: true },
    });
  }

  public async generateRiskAssessment(data: RiskAssessmentRequestData): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `risk-assessment`,
      fetchOptions: { throwOnError: true },
    });
  }

  public async generateMarketingUpdateContent(
    data: MarketingUpdateContentRequestData,
  ): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `generate-marketing-update-content`,
      fetchOptions: { throwOnError: false },
    });
  }
}
