import { groupBy } from 'lodash';
import { QuoteStatus } from 'enums';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import { excessLayerTitle, formatBigNumber, formatPrice, formatPriceOrNA, sortLayersByAttachmentPoint } from 'utils';
import { filterSingleInLayerQuotes } from 'utils/quotes-utils';
import { layerStatusConfig } from 'broker/configuration-mappers/layer-status-config';
import { ConceptualTowerReportRow } from './types';

export function getConceptualTowerRowsData(
  quotes: Quote[],
  layers: Layer[],
): { layersForReport: ConceptualTowerReportRow[]; showTotalLine: boolean } {
  const activeQuotes = quotes.filter((quote) => quote.status !== QuoteStatus.Rejected);
  const quotesByAttachmentPoint = groupBy(activeQuotes, ({ layer }) => layer.attachmentPoint ?? 0);
  const sortedLayers = sortLayersByAttachmentPoint(layers).reverse();

  // show total line if there is only one quote (or no quotes) for all layers
  // if there is layer with more than one quote, wa don't show the total line
  const showTotalLine = Object.values(quotesByAttachmentPoint).every((quotesList) => quotesList.length <= 1);

  const layersForReport: ConceptualTowerReportRow[] = sortedLayers.flatMap((layer) => {
    const attachmentPoint = layer.attachmentPoint ?? 0;

    // if there is no quote for this layer, create a fake one to show the layer in the report
    if (!quotesByAttachmentPoint[attachmentPoint]) {
      return {
        id: layer.id,
        layer: attachmentPoint
          ? excessLayerTitle(undefined, undefined, undefined, attachmentPoint)
          : messages.general.primary,
        status: layerStatusConfig[layer.status].text,
        limit: '',
        marketName: '',
      };
    }
    const layerQuotes = quotesByAttachmentPoint[attachmentPoint];

    return layerQuotes.map((quote) => {
      const { limit, marketName = '', premium } = quote;

      return {
        id: quote.id,
        layer: attachmentPoint
          ? excessLayerTitle(limit, premium, quote.primaryQuote?.marketName, attachmentPoint)
          : messages.general.primary,
        status: layerStatusConfig[quote.layer.status].text,
        marketName,
        limit: limit ? `$${formatBigNumber(Number(limit))}` : '',
        premium: formatPriceOrNA(Number(premium)),
      };
    });
  });

  const selectedQuotes = filterSingleInLayerQuotes(quotes);

  const totalPremium: number = selectedQuotes
    .map((item) => item.premium)
    .reduce<number>((prev: number, curr: number | undefined) => prev + (curr ?? 0), 0);
  const totalLimit = selectedQuotes.map((item) => item.limit).reduce((prev, curr) => (prev ?? 0) + (curr || 0), 0);

  // add "fake layer" to show total line at the end of the table
  const totalLine: ConceptualTowerReportRow = {
    // every layer must have uniq id
    id: 'total-line',
    layer: 'Total',
    premium: `$${formatPrice(totalPremium)}`,
    marketName: '',
    status: '',
    limit: totalLimit ? `$${formatBigNumber(totalLimit)}` : '',
  };
  if (showTotalLine) {
    layersForReport.push(totalLine);
  }

  return { layersForReport, showTotalLine };
}
