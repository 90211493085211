export enum CitationType {
    InternalBoxFile = 'InternalBoxFile',
    QuoteEmail = 'QuoteEmail',
    DeclinationEmail = 'DeclinationEmail',
    PolicySpecimen = 'PolicySpecimen',
    RiskAppetiteBrochure = 'RiskAppetiteBrochure',
    InboundEmail = 'InboundEmail',
}

export const isFileCitationType = (citationType: CitationType): boolean =>
    [CitationType.InternalBoxFile, CitationType.PolicySpecimen, CitationType.RiskAppetiteBrochure].includes(
        citationType,
    );

export const isEmailCitationType = (citationType: CitationType): boolean =>
    [CitationType.DeclinationEmail, CitationType.QuoteEmail, CitationType.InboundEmail].includes(citationType);
