import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Chip, NewMenu, SvgIcon } from '@common-components';
import { AirplanemodeActive } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';

export enum LabelsMenuAnchorType {
  AddLabel,
  EditLabel,
  EditLabelCoPilot,
}

export interface Option {
  label: string;
  value: string;
  color?: string;
  disabled?: boolean;
  disabledTooltipContent?: string;
  endAdornment?: typeof SvgIcon;
}

export interface LabelsMenuProps {
  options: Option[];
  selectedLabels?: Option[];
  anchorType: LabelsMenuAnchorType;
  addLabelText?: string;
  icon?: typeof SvgIcon;
  onLabelChanged: (value: string) => void | Promise<void>;
  onClearLabel: () => void | Promise<void>;

  // allows overriding isMenuOpen
  isOpenOverride: boolean;
  onClose?: () => void;
  permanentLabel?: boolean;
  readonly?: boolean;
  showTooltip?: boolean;
  multiSelect?: boolean;
  disabled?: boolean;
  disabledTooltipContent?: string;
}

export default function LabelsMenu({
  options,
  selectedLabels,
  anchorType,
  isOpenOverride,
  onClose,
  onLabelChanged,
  onClearLabel,
  permanentLabel,
  readonly,
  addLabelText,
  icon,
  showTooltip,
  multiSelect = false,
  disabled,
  disabledTooltipContent,
}: LabelsMenuProps) {
  const optionsMenu = useOptionsMenu();
  const [search, setSearch] = useState('');

  const filteredLabelsOptions = options.filter(
    (labelsOption) => search.length <= 1 || labelsOption.label.toLowerCase().includes(search.toLowerCase()),
  );

  const { anchorEl, setAnchorEl } = optionsMenu;
  const anchorRef = useRef(null);
  const ownOnClose = () => {
    optionsMenu.closeMenu();
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpenOverride && anchorEl !== anchorRef.current) {
      setAnchorEl(anchorRef.current);
    }
  }, [anchorEl, isOpenOverride, setAnchorEl]);

  const startIcon = icon;

  const isSelectedItem = (value: string) => selectedLabels?.map((item) => item.value).includes(value) ?? false;

  const handleMultiSelectLabelChange = async (value: string) => {
    const selectedLabelsValues = selectedLabels?.map((item) => item.value) ?? [];
    if (selectedLabelsValues.includes(value)) {
      await onLabelChanged(selectedLabelsValues.filter((item) => item !== value).join(','));
    } else {
      await onLabelChanged(selectedLabelsValues.length ? `${selectedLabelsValues},${value}` : value);
    }
  };

  const handleSingleSelectLabelChange = (value: string) => {
    onLabelChanged(value);
    ownOnClose();
  };

  const handleLabelChange = multiSelect ? handleMultiSelectLabelChange : handleSingleSelectLabelChange;

  const selectedLabel = selectedLabels?.map((item) => item.label).join(', ') ?? '';
  const color = selectedLabels?.[0]?.color || 'secondary';

  const renderAnchor = () => {
    if (readonly) {
      return selectedLabel ? (
        <Chip
          ref={anchorRef}
          size="small"
          startIcon={startIcon}
          label={selectedLabel}
          color={color}
          rounded={false}
          disabled={disabled}
          disabledTooltipContent={disabledTooltipContent}
          {...(showTooltip && { tooltipContent: selectedLabel })}
        />
      ) : null;
    }

    if (anchorType === LabelsMenuAnchorType.AddLabel) {
      const labelText = addLabelText || messages.labelsMenu.addLabelButton;
      return (
        <Chip
          ref={anchorRef}
          onClick={optionsMenu.openMenu}
          label={labelText}
          rounded={false}
          size="small"
          startIcon={startIcon}
          variant="outlined"
          color="primary"
          disabled={disabled}
          disabledTooltipContent={disabledTooltipContent}
          {...(showTooltip && { tooltipContent: labelText })}
        />
      );
    }

    if (anchorType === LabelsMenuAnchorType.EditLabelCoPilot) {
      return (
        <Chip
          ref={anchorRef}
          onClick={optionsMenu.openMenu}
          size="small"
          startIcon={AirplanemodeActive}
          label={selectedLabel}
          color={color}
          rounded={false}
          disabled={!!permanentLabel || disabled}
          disabledTooltipContent={permanentLabel ? messages.labelsMenu.permanentLabelDisabled : disabledTooltipContent}
          tooltipContent={messages.labelsMenu.labeledByFlow}
          tooltipPlacement="left"
        />
      );
    }

    return (
      <Chip
        ref={anchorRef}
        onClick={optionsMenu.openMenu}
        size="small"
        startIcon={startIcon}
        label={selectedLabel}
        color={color}
        rounded={false}
        disabled={!!permanentLabel || disabled}
        disabledTooltipContent={permanentLabel ? messages.labelsMenu.permanentLabelDisabled : disabledTooltipContent}
        {...(showTooltip && { tooltipContent: selectedLabel })}
      />
    );
  };

  return (
    <>
      {renderAnchor()}
      <NewMenu
        optionsMenuState={{ ...optionsMenu, closeMenu: ownOnClose }}
        searchProps={{
          placeholder: messages.general.search,
          onChange: setSearch,
        }}
        menuItems={[
          {
            key: 'labels-menu',
            selectionType: multiSelect ? 'checkbox' : undefined,
            itemsLimit: 5,
            items: filteredLabelsOptions.map((option) => ({
              label: option.label,
              isSelected: isSelectedItem(option.value),
              disabled:
                option.disabled || (!multiSelect && selectedLabels?.some((item) => item.value === option.value)),
              disabledTooltipContent: option.disabledTooltipContent,
              tooltipPlacement: 'right',
              onClick: () => {
                handleLabelChange(option.value);
              },
              endAdornment: option.endAdornment,
            })),
            moreActions: {
              items: [
                {
                  text: messages.labelsMenu.clearLabel,
                  onClick: async () => {
                    ownOnClose();
                    await onClearLabel();
                  },
                  disabled: isEmpty(selectedLabels),
                },
              ],
            },
          },
        ]}
      />
    </>
  );
}
