import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import { calculateRPM, formatBigNumber, formatPrice, formatPriceOrNA } from 'utils';
import QuoteCoverageLinesTooltip from 'broker/components/common/QuoteCoverageLinesTooltip';

export interface QuoteDetailsProps {
  quote: Quote;
  layer: Layer;
  hideQuoteActions?: boolean;
}

function AttachmentPoint({ attachmentPoint }: { attachmentPoint: string }) {
  return (
    <>
      <span style={{ fontSize: 10 }}>{` ${messages.general.xs} `}</span>
      {`$${attachmentPoint}`}
    </>
  );
}

export default function QuoteDetails({ quote, layer }: QuoteDetailsProps) {
  const limit = quote.limit ? formatBigNumber(quote.limit) : '';
  const attachmentPoint = formatBigNumber(layer.attachmentPoint || 0);

  const RPM = calculateRPM(quote.premium, quote.limit || 0);
  const quoteCoverageLines = quote.coverageLines;

  return (
    <Stack>
      <Stack direction="row" gap={0.5}>
        <Typography color="text.secondary" variant="caption">
          {`$${limit} `}
        </Typography>
        {layer.attachmentPoint ? (
          <Typography color="text.secondary" variant="caption">
            <AttachmentPoint attachmentPoint={attachmentPoint} />
          </Typography>
        ) : (
          <Typography color="text.secondary" variant="caption" noWrap>
            {messages.general.aggregateLimit}
          </Typography>
        )}
      </Stack>
      <Typography color="text.secondary" variant="caption" noWrap>
        {`${formatPriceOrNA(quote.premium)} (RPM $${formatPrice(RPM)})`}
      </Typography>
      {quoteCoverageLines && quoteCoverageLines.length > 0 && <QuoteCoverageLinesTooltip quote={quote} />}
    </Stack>
  );
}
