import { Box, CircularProgress, Icon, IconButton, Stack } from '@common-components';
import { AutoAwesomeOutlined } from '@icons';
import { useOptionsMenu } from 'hooks';
import { softShadow } from 'themes';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import AIPopup from './AIPopup';

const PopupMinHeight = 720;
const PopupMaxHeight = '80vh';
const PopupWidth = 960;

export interface ButtonWithPopupProps {
  popupTitle: string;
  tooltipText?: string;
  top?: number;
  right?: number;
  withFileLinks?: boolean;
  isRichTextResponse?: boolean;
}

export function AIButtonWithPopup({
  popupTitle,
  tooltipText,
  top = 88,
  right = 24,
  withFileLinks = true,
  isRichTextResponse = true,
}: ButtonWithPopupProps) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();
  const { isLoading, isContentStreaming, threadId } = useAIContext();

  return (
    <Box
      position="absolute"
      top={top}
      right={right}
      zIndex={(theme) => theme.zIndex.tooltip}
      bgcolor="green.300"
      color="common.black"
      borderRadius="50%"
      boxShadow={softShadow}
    >
      {isContentStreaming && (
        <Box position="absolute">
          <CircularProgress sx={{ color: 'text.secondary' }} thickness={2} />
        </Box>
      )}
      <IconButton
        isTooltipOpen={isLoading && !isContentStreaming && !!tooltipText && !isMenuOpen}
        tooltipCustomColor="green.300"
        tooltipPlacement="left"
        tooltipContent={
          <Stack flexDirection="row" flex={1}>
            <Icon component={AutoAwesomeOutlined} sx={{ fontSize: 18, mr: 1 }} />
            <Box>{tooltipText}</Box>
          </Stack>
        }
        loading={isLoading && !isContentStreaming}
        color="inherit"
        icon={AutoAwesomeOutlined}
        onClick={openMenu}
      />

      {isMenuOpen && (
        <AIPopup
          popupTitle={popupTitle}
          withFileLinks={withFileLinks}
          isRichTextResponse={isRichTextResponse}
          anchorEl={anchorEl}
          isMenuOpen={isMenuOpen}
          closeMenu={closeMenu}
          popupMaxHeight={PopupMaxHeight}
          popupMinHeight={PopupMinHeight}
          popupWidth={PopupWidth}
          threadId={threadId}
          openMenu={openMenu}
        />
      )}
    </Box>
  );
}
