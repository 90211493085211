import { ReactNode, useContext } from 'react';
import { AIFeature } from 'enums';
import { AIMessage } from 'types';
import { MultipleAIFeaturesContextProvider } from './MultipleAIFeaturesContextProvider';
import { AIFeatureContext, SingleAIFeatureContextProvider } from './SingleAIFeatureContextProvider';

/**
 * The AI context is feature-based and has boundaries defined by the feature flow.
 * For flows requiring multiple AI features, we use `MultipleAIFeaturesContextProvider` to manage the state of all features.
 * For each single feature within the flow, we use `SingleAIFeatureContextProvider` to manage the state of a single feature.
 * `AIContextProvider` is a wrapper for simplicity, creating a boundary for a single feature within a multiple features scope.
 *
 * Usage:
 * 1. For a flow with a single AI feature:
 *    <AIContextProvider feature={AIFeature.MyFeature}>
 *        <MyComponent />
 *    </AIContextProvider>
 *
 * 2. For a flow with multiple AI features:
 *    <MultipleAIFeaturesContextProvider>
 *        <SingleAIFeatureContextProvider feature={AIFeature.MyFirstFeature}>
 *            <MyComponent />
 *        </SingleAIFeatureContextProvider>
 *        <SingleAIFeatureContextProvider feature={AIFeature.MySecondFeature}>
 *            <MyComponent />
 *        </SingleAIFeatureContextProvider>
 *    </MultipleAIFeaturesContextProvider>
 */
export function AIContextProvider({
  children,
  feature,
  initialMessages,
}: {
  children: ReactNode;
  feature: AIFeature;
  initialMessages?: AIMessage[];
}) {
  return (
    <MultipleAIFeaturesContextProvider>
      <SingleAIFeatureContextProvider feature={feature} initialMessages={initialMessages}>
        {children}
      </SingleAIFeatureContextProvider>
    </MultipleAIFeaturesContextProvider>
  );
}

export const useAIContext = () => {
  const context = useContext(AIFeatureContext);
  if (!context) {
    throw new Error('useAIContext must be used within an AIContextProvider');
  }
  return context;
};
