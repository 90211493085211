import { BoxItem } from 'box-ui-elements/es';
import { isString } from 'lodash';
import { PDFDocument } from 'pdf-lib';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { BoxTemplateLabels, FileType } from 'enums';
import { useBoxApi, useMutateBoxItems } from 'hooks/api/box';
import { logger } from 'utils';
import { useGetBoxPdfRepresentationAsBlob } from 'utils/box-utils';
import { extractExtensionFromFile } from 'utils/files-utils';
import { useGeneratePDF } from './pdf-creator-util';

export class EncryptedFileError extends Error {
  constructor(public fileName: string, public fileId: string) {
    super('Pdf-Lib loading encryption error');
  }
}

export function usePDFCreateAndCombine() {
  const { uploadFileWithMetadata, downloadFileAsBlob } = useMutateBoxItems();
  const { getBoxItemById } = useBoxApi();
  const getBoxPdfRepresentationAsBlob = useGetBoxPdfRepresentationAsBlob();
  const generatePDF = useGeneratePDF();

  async function combineFiles(quoteFileBlobs: { boxItem: BoxItem; blob: Blob }[], coverLetterBlob: Blob) {
    logger.log('info', { message: 'start combineFiles' });
    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.create();

    const pdfDocuments = await Promise.all(
      [{ blob: coverLetterBlob } as { boxItem?: BoxItem; blob: Blob }, ...quoteFileBlobs].map(
        async ({ blob, boxItem }) => {
          const buffer = await blob.arrayBuffer();
          const pdfBytes = new Uint8Array(buffer);
          // Load a PDFDocument from each of the existing PDFs
          try {
            return await PDFDocument.load(pdfBytes);
          } catch (e: any) {
            logger.log('info', { message: 'Failed to load PDF document', boxItem, error: e });
            if (
              boxItem &&
              isString(e.message) &&
              e.message.toLowerCase().includes('input document to `pdfdocument.load` is encrypted')
            ) {
              const encryptedFileError = new EncryptedFileError(boxItem.name, boxItem.id);
              logger.log('info', { message: encryptedFileError.message, boxItem, error: e });
              throw encryptedFileError;
            } else {
              throw e;
            }
          }
        },
      ),
    );

    logger.log('info', { message: 'combineFiles loaded blobs to pdfDocuments' });

    for (const doc of pdfDocuments) {
      // eslint-disable-next-line no-await-in-loop
      const pages = await pdfDoc.copyPages(doc, doc.getPageIndices());
      pages.forEach((page) => {
        pdfDoc.addPage(page);
      });
    }

    logger.log('info', { message: 'combineFiles copied all pages' });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytesToSave = await pdfDoc.save();

    const response = new Blob([pdfBytesToSave], { type: 'application/pdf' });
    logger.log('info', { message: 'end combineFiles' });
    return response;
    // Trigger the browser to download the PDF document
    // download(pdfBytesToSave, 'flow_quote.pdf', 'application/pdf');
  }

  async function generateAndUploadFlowPDF(
    content: Content,
    fileIds: string[],
    generatedFileDetails: { parentFolderId: string; fileName: string; fileType: FileType },
  ) {
    const filePdfBlob = await generatePDF(content);

    logger.log('info', {
      message: 'start generateAndUploadFlowPDF convert quoteFileIds to blobs',
      quoteFileIds: fileIds,
    });
    const pdfBlobs = await Promise.all(
      fileIds.map(async (fileId) => {
        const boxItem = (await getBoxItemById(fileId))!;
        // check if name ends with .pdf
        if (extractExtensionFromFile(boxItem.name) === 'pdf') {
          return { boxItem, blob: await downloadFileAsBlob(boxItem.id) };
        }
        const pdfRepresentationAsBlob = await getBoxPdfRepresentationAsBlob(boxItem.id);
        if (pdfRepresentationAsBlob) {
          return { boxItem, blob: pdfRepresentationAsBlob };
        }

        throw new Error(
          `Failed to get PDF representation for file: ${boxItem.name} (ID: ${boxItem.id}). Unable to generate PDF.`,
        );
      }),
    );
    logger.log('info', { message: 'end generateAndUploadFlowPDF convert  fileIds to blobs' });

    const combinedBlob = await combineFiles(pdfBlobs, filePdfBlob);
    const uploadedFlowQuoteToBoxResponse = await uploadFileWithMetadata({
      parentFolderId: generatedFileDetails.parentFolderId,
      fileName: generatedFileDetails.fileName,
      file: combinedBlob,
      retryWithDate: true,
      metadata: { [BoxTemplateLabels.FileType]: generatedFileDetails.fileType },
    });
    logger.log('log', { message: 'end generateAndUploadFlowPDF', uploadedFlowQuoteToBoxResponse });
    return uploadedFlowQuoteToBoxResponse;
  }

  return generateAndUploadFlowPDF;
}
