import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

type ColumnConfigType = {
  title: string;
  flex: number | string;
  align?: 'left' | 'center' | 'right' | 'inherit' | 'justify';
  paddingX?: number;
};

export enum AgenciesColumns {
  ExpandContacts = 'ExpandContacts',
  Agency = 'Agency',
  Retailers = 'Retailers',
  Domains = 'Domains',
  Assignee = 'Assignee',
  LastUpdate = 'LastUpdate',
  MoreActions = 'MoreActions',
}

type AgenciesColumnsConfig = Record<AgenciesColumns, ColumnConfigType>;

export const columnConfig: AgenciesColumnsConfig = {
  [AgenciesColumns.ExpandContacts]: {
    title: '',
    flex: '0 0 36px',
    align: 'center',
  },
  [AgenciesColumns.Agency]: {
    title: messages.agenciesPage.columnHeaders.agencies,
    flex: '0 0 216px',
    align: 'left',
  },
  [AgenciesColumns.Retailers]: {
    title: messages.agenciesPage.columnHeaders.retailers,
    flex: '0 0 216px',
    align: 'left',
  },
  [AgenciesColumns.Domains]: {
    title: messages.agenciesPage.columnHeaders.domains,
    flex: 1,
    align: 'left',
    paddingX: 1,
  },
  [AgenciesColumns.Assignee]: {
    title: messages.agenciesPage.columnHeaders.assignee,
    flex: '0 0 216px',
    align: 'left',
  },
  [AgenciesColumns.LastUpdate]: {
    title: messages.agenciesPage.columnHeaders.lastUpdated,
    flex: '0 0 97px',
    align: 'left',
  },
  [AgenciesColumns.MoreActions]: {
    title: '',
    flex: '0 0 40px',
    align: 'left',
  },
};

export default function AgenciesTableHeader() {
  return (
    <Stack
      bgcolor="common.white"
      position="sticky"
      top={0}
      zIndex={(theme) => theme.zIndex.appBar}
      direction="row"
      alignItems="center"
      flex={0}
      p={1.5}
      height={52}
      gap={3}
      borderBottom={1}
      borderColor="divider"
    >
      {Object.keys(columnConfig).map((key) => {
        const column = columnConfig[key as AgenciesColumns];
        return (
          <Typography
            variant="body2"
            component="div"
            color="typography.nonEssential"
            align={column.align}
            flex={column.flex}
            paddingX={column.paddingX}
            key={key}
          >
            {column.title}
          </Typography>
        );
      })}
    </Stack>
  );
}
