import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { useRetrieveAgency } from 'hooks/api/agency';
import { useSearchOrganizationDomain } from 'hooks/api/organization-domain';
import { messages } from 'i18n';
import { User } from 'types';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import FormSkeleton from 'broker/components/skeletons/FormSkeleton';
import FormContent from './FormContent';
import { AddAAgencyDialogLocationState, AgencyFormDataProps } from './types';
import { FormMode, UrlParam } from './utils';

export interface ContentProps extends BaseRoutedDialogContentProps {
  onAgentCreated?: (user: User) => void;
  locationState?: AddAAgencyDialogLocationState;
}

export default function Content({
  onClose,
  onAgentCreated,
  setDialogIsDirty,
  closeWithoutPrompt,
  locationState,
}: ContentProps) {
  const [searchParams] = useSearchParams();

  const id = searchParams.get(UrlParam.Id) ?? '';

  const state = locationState;

  const { data: agency, isLoading } = useRetrieveAgency({ id });

  const { items: domains, isLoading: isDomainsLoading } = useSearchOrganizationDomain({
    filter: { organizationId: agency?.organizationId },
    enabled: !!agency?.organizationId,
  });

  const mode = id ? FormMode.Update : FormMode.Create;

  const title =
    mode === FormMode.Update
      ? messages.agenciesPage.agencyForm.title[mode](agency?.organizationName ?? '')
      : messages.agenciesPage.agencyForm.title[mode];

  const defaultValues = useMemo(
    () =>
      ({
        name: agency?.organizationName ?? '',
        domain: agency?.organizationId ? domains.map((domain) => domain.domain) : [],
        assigneeId: agency?.assigneeId ?? '',
        relationshipOriginatorIds: agency?.relationshipOriginatorIds ?? [],
      } as AgencyFormDataProps),
    [agency, domains],
  );
  return (
    <>
      <DialogHeader id="agency-dialog" onClose={onClose} title={title} />

      {!id || (agency && !isLoading && !isDomainsLoading) ? (
        <FormContent
          existingDomains={domains}
          onAgentCreated={onAgentCreated}
          mode={mode}
          setDialogIsDirty={setDialogIsDirty}
          onClose={onClose}
          defaultValues={defaultValues}
          closeWithoutPrompt={closeWithoutPrompt}
          scrollTo={state?.scrollTo}
          agency={agency ?? null}
        />
      ) : (
        <>
          <DialogContent>
            <FormSkeleton />
          </DialogContent>
          <DialogFooter showSkeleton />
        </>
      )}
    </>
  );
}
