import { useMemo } from 'react';
import { Box, Checkbox, Highlight, Radio, Stack, Typography } from '@common-components';
import { AIFeature, AIMessageType, SubmissionMarketRequestStatus, SubmissionMethod, SubmissionStatus } from 'enums';
import { ActivityLog, ExtendedMarketRequest, SubmissionMarket } from 'types';
import MenuBadge from 'broker/components/MenuBadge';
import { getMarketNameWithType } from 'broker/components/UserMarkets/components/utils';
import { ProductDescription } from 'broker/pages/SubmissionWorkspacePage/components/common/ProductDescription';
import { BulkActionsMode } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { marketRequestsStatusConfig } from 'broker/pages/SubmissionWorkspacePage/market-request-status-config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { areHeraldProductsEligibleToCreateSubmission } from 'broker/pages/SubmissionWorkspacePage/utils';
import AvailableSubmitChips from './AvailableSubmitChips';
import { marketRequestConfig } from './config';
import File from './File';
import MarketRequestMetaData from './MarketRequestMetaData';
import MarketSummary from './MarketSummary/MarketSummary';
import Note from './Note';
import { SUBMISSION_MARKET_COLUMNS } from './types';
import useDropdownMenuGroups from './useDropdownMenuItems';
import { mapMarketRequestToEmailActivity } from './utils';

interface MarketItemProps {
  isSelected?: boolean;
  onSelected?: (marketRequestId: string) => void;
  showSelectionCheck?: boolean;
  multipleSelection?: boolean;
  marketRequest: ExtendedMarketRequest;
  activities: ActivityLog[];
  mainAction?: (market: SubmissionMarket) => void;
  searchWord?: string;
  selectedSubmitMethod?: SubmissionMethod;
  setIsSubmittingMarketRequest?: (isSubmitting: boolean) => void;
  bulkActionMode?: BulkActionsMode;
  setSuggestedSubmissionStatus?: (status: SubmissionStatus | null) => void;
}

export default function MarketRequestItem({
  isSelected,
  onSelected,
  showSelectionCheck = true,
  multipleSelection = true,
  marketRequest,
  activities,
  mainAction,
  searchWord,
  selectedSubmitMethod,
  setIsSubmittingMarketRequest,
  bulkActionMode,
}: MarketItemProps) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const emailActivity = mapMarketRequestToEmailActivity(marketRequest, activities);
  const { insuranceProduct } = marketRequest;
  const menuGroups = useDropdownMenuGroups(marketRequest, setIsSubmittingMarketRequest);

  const isItemDisabledForBulkSubmit = () => {
    if (!selectedSubmitMethod) {
      return true;
    }
    // Disable selection for submit method if the market request is not ready to market
    if (marketRequest.status !== SubmissionMarketRequestStatus.ReadyToMarket) {
      return true;
    }

    // Disable selection for submit via api for market request that doesn't support it or the product is not in a completed herald application
    if (selectedSubmitMethod === SubmissionMethod.API) {
      const externalProductId = marketRequest.insuranceProduct?.externalProductId;
      return (
        !externalProductId ||
        !partialSubmission ||
        !areHeraldProductsEligibleToCreateSubmission(partialSubmission, [externalProductId]).eligible
      );
    }
    return false;
  };

  const disabledSelection = () => {
    switch (bulkActionMode) {
      case BulkActionsMode.Submit:
        return isItemDisabledForBulkSubmit();
      case BulkActionsMode.CompareQuotes:
        return marketRequest.status !== SubmissionMarketRequestStatus.QuoteReady;
      default:
        return false;
    }
  };

  const initialMessage = useMemo(
    () =>
      marketRequest.summary
        ? [
            {
              type: AIMessageType.Prompt,
              content: marketRequest.summary.prompt,
              unresolvedPrompt: marketRequest.summary.unresolvedPrompt,
            },
            {
              type: AIMessageType.Response,
              content: marketRequest.summary.response,
            },
          ]
        : [],
    [marketRequest.summary],
  );

  return (
    <AIContextProvider feature={AIFeature.MarketSummary} initialMessages={initialMessage}>
      <Stack
        direction="row"
        px={1}
        alignItems="stretch"
        bgcolor="common.white"
        borderLeft={5}
        borderRadius={1}
        borderColor={marketRequestsStatusConfig[marketRequest.status].color}
        minWidth={1}
        width="fit-content"
        position="relative"
        sx={{
          '&:hover': {
            boxShadow: 4,
          },
        }}
      >
        <Stack minHeight={102} direction="row" alignItems="center" flex={1} minWidth={0} gap={2}>
          <Stack
            alignItems="center"
            minWidth={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Product].width}
            maxWidth={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Product].width}
            height={1}
            justifyContent="flex-start"
            gap={1}
            direction="row"
          >
            {showSelectionCheck &&
              (multipleSelection ? (
                <Checkbox
                  size="small"
                  disableRipple
                  checked={isSelected}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => onSelected!(marketRequest.id)}
                  disabled={disabledSelection()}
                />
              ) : (
                <Radio
                  size="small"
                  disableRipple
                  checked={isSelected}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => onSelected!(marketRequest.id)}
                />
              ))}
            <Stack
              direction="row"
              height={1}
              width={1}
              py={1}
              gap={0.5}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                flex={1}
                minWidth={0}
                height={1}
                gap={0.5}
                onClick={() => mainAction?.(marketRequest.market)}
                sx={{ cursor: mainAction ? 'pointer' : 'default' }}
                position="relative"
                justifyContent="flex-start"
              >
                {insuranceProduct ? (
                  <ProductDescription
                    product={insuranceProduct}
                    searchInput={searchWord ?? ''}
                    submissionMarket={marketRequest.market}
                  />
                ) : (
                  <Typography variant="body2" fontWeight={600}>
                    <Highlight
                      searchWords={[searchWord ?? '']}
                      textToHighlight={getMarketNameWithType(
                        marketRequest.market.marketName,
                        marketRequest.market.marketOrganizationType,
                      )}
                    />
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            gap={1}
            py={1}
            minWidth={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Status].width}
            maxWidth={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Status].width}
            alignSelf="stretch"
          >
            <Stack height={20} gap={0.5}>
              <MenuBadge
                inlined
                alignItem="flex-start"
                color={
                  marketRequestsStatusConfig[marketRequest.status].getContextualColor?.(marketRequest) ??
                  marketRequestsStatusConfig[marketRequest.status].color
                }
                title={marketRequestsStatusConfig[marketRequest.status].title}
                //    subtitle={}
                menuGroups={menuGroups}
              />
            </Stack>
            <Stack width={1} height={1} alignItems="flex-start" gap={1}>
              <MarketRequestMetaData marketRequest={marketRequest} emailActivity={emailActivity} />
              <AvailableSubmitChips
                marketRequest={marketRequest}
                setIsSubmittingMarketRequest={setIsSubmittingMarketRequest}
              />
              {marketRequest.status !== SubmissionMarketRequestStatus.Declined && (
                <Box>
                  <File marketRequest={marketRequest} market={marketRequest.market} />
                </Box>
              )}
            </Stack>
            {marketRequestsStatusConfig[marketRequest.status].getSubtitle?.(marketRequest) && (
              <Typography mt={2} variant="caption" color="text.secondary">
                {marketRequestsStatusConfig[marketRequest.status].getSubtitle?.(marketRequest)}
              </Typography>
            )}
          </Stack>
          <Stack
            py={1}
            alignItems="flex-start"
            flex={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Info].flex}
            height={1}
            minWidth={400}
          >
            <MarketSummary marketRequest={marketRequest} />
          </Stack>
          <Stack
            direction="row"
            gap={2}
            py={1}
            alignItems="flex-start"
            justifyContent="space-between"
            minWidth={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Note].width}
            maxWidth={marketRequestConfig[SUBMISSION_MARKET_COLUMNS.Note].width}
            height={1}
          >
            <Note note={marketRequest.notes} id={marketRequest.id} />
          </Stack>
        </Stack>
      </Stack>
    </AIContextProvider>
  );
}
