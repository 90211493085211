import { Submission } from 'types';
import { isMoreThan24HoursAgo } from 'utils';

// Checks if the draft marketing update is fresh (updated within the last 24 hours).
// Returns the submission as-is if fresh; otherwise, removes the draft marketing update.
export function getSubmissionWithDraft(submission: Submission | null) {
  if (!submission?.draftMarketingUpdate) {
    return submission;
  }
  const isDraftMarketingUpdateFresh =
    submission.draftMarketingUpdate.updatedAt && !isMoreThan24HoursAgo(submission.draftMarketingUpdate.updatedAt);
  if (isDraftMarketingUpdateFresh) {
    return submission;
  }
  return {
    ...submission,
    draftMarketingUpdate: undefined,
  };
}
