import { Box, Collapse, IconButton, Stack, Typography } from '@common-components';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@icons';
import { useBoolean } from 'hooks';
import { AIMessage } from 'types';
import { CopyButton } from './CopyButton';
import MessageActions from './MessageActions';

export const AIPromptMessage = ({ message, maxWidth = 720 }: { message: AIMessage; maxWidth?: number }) => {
  const [isCollapsed, { toggle: toggleCollapsed }] = useBoolean(true);
  const messageContent = message.unresolvedPrompt || message.content;
  return (
    <Box justifySelf="end" overflow="hidden">
      <MessageActions actions={[<CopyButton key="copy" content={messageContent} />]}>
        <Stack
          maxWidth={maxWidth - 50}
          direction="row"
          sx={{ backgroundColor: 'grey.50' }}
          width={1}
          borderRadius={1}
          alignItems="center"
        >
          <Collapse in={!isCollapsed} timeout={300} collapsedSize={32} sx={{ flex: 1 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace={isCollapsed ? 'nowrap' : 'normal'}
              p={1}
              borderRadius={1}
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              {messageContent}
            </Typography>
          </Collapse>
          <Box alignSelf="end" p={1} pl={0}>
            <IconButton
              variant="text"
              size="small"
              color="secondary"
              icon={isCollapsed ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
              onClick={toggleCollapsed}
            />
          </Box>
        </Stack>
      </MessageActions>
    </Box>
  );
};
