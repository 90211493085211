import { Subjectivities } from '@common/types/quote';
import { ArfRequestData, ArfSentData, SubmissionStatusUpdateData } from 'clients';
import { EmailTemplateType } from 'enums';
import {
  ActivityLog,
  ActivityLogCreate,
  ActivityMetadata,
  Contact,
  CreateMarketIssuingCompany,
  CreateSubmissionMarketRequest,
  Layer,
  MarketIssuingCompany,
  PartialSubmission,
  PolicyDetails,
  Quote,
  Submission,
  SubmissionMarket,
  SubmissionMarketCreate,
  SubmissionMarketRequest,
  UpdateSubmissionMarketRequest,
} from 'types';
import { MakeFieldsOptional } from 'utils';

export interface EmailActivity extends Pick<ActivityMetadata, 'layerId' | 'submissionEmailId'> {
  submissionMarketId: string;
  submissionEmailId: string;
}

export enum FetchingState {
  InitLoading = 'InitLoading',
  ReFetching = 'ReFetching',
  Idle = 'Idle',
}
export interface SubmissionWorkspaceState {
  submission: Submission | null;
  partialSubmission: PartialSubmission | null;
  quotes: Quote[];
  layers: Layer[];
  markets: SubmissionMarket[];
  activities: ActivityLog[];
  isReFetching: boolean;
}

export interface SubmissionWorkspaceActions {
  clearCache: () => Promise<void>;
  reFetchWorkspace: () => Promise<void>;
  createQuote: (quote: Partial<Quote>) => Promise<string | undefined>;
  createLayer: (data: Partial<Layer>) => Promise<Layer | null>;
  createMarketIssuingCompany: (data: CreateMarketIssuingCompany) => Promise<MarketIssuingCompany | null>;
  updateQuote: (id: string, data: Partial<Quote>) => Promise<string | undefined>;
  bindQuote: (
    id: string,
    binderFileIds: string[],
    flowBinderFileIds: string[],
    policyDetails?: PolicyDetails,
    flowCommission?: number,
    binderSubjectivities?: Subjectivities,
    binderDate?: Date,
  ) => Promise<void>;
  removeQuote: (id: string) => Promise<void>;
  rejectQuote: (id: string) => Promise<void>;
  issueQuote: (
    id: string,
    policyFileIds: string[],
    policyDetails?: PolicyDetails,
    flowCommission?: number,
  ) => Promise<void>;
  addSubmissionMarket: (
    addedMarket: SubmissionMarketCreate,
    reFetchWorkspace?: boolean,
  ) => Promise<SubmissionMarket | null>;
  addSubmissionMarkets: (
    addedMarkets: SubmissionMarketCreate[],
    reFetchWorkspace?: boolean,
  ) => Promise<SubmissionMarket[]>;
  addSubmissionMarketRequests: (
    marketRequests: CreateSubmissionMarketRequest[],
    reFetchWorkspace?: boolean,
  ) => Promise<SubmissionMarketRequest[]>;
  addSubmissionMarketRequest: (data: CreateSubmissionMarketRequest, reFetchWorkspace?: boolean) => Promise<void>;
  deleteSubmissionMarketRequest: (id: string, reFetchWorkspace?: boolean) => Promise<void>;
  updateSubmissionMarketRequest: (
    id: string,
    data: UpdateSubmissionMarketRequest,
    reFetchWorkspace?: boolean,
  ) => Promise<void>;
  updateSubmissionMarket: (id: string, data: Partial<SubmissionMarket>, reFetchWorkspace?: boolean) => Promise<void>;
  addContactsToSubmissionMarket: (userMarketId: string, contacts: Contact[]) => Promise<void>;

  updateLayer: (id: string, data: Partial<Layer>) => Promise<void>;
  deleteLayer: (id: string) => Promise<void>;
  updateSubmission: (id: string, data: Partial<Submission>, reFetchWorkspace?: boolean) => Promise<void>;
  logEmailActivities: (
    emailTemplateType: EmailTemplateType | undefined,
    activities: EmailActivity[],
    reFetchWorkspace?: boolean,
  ) => Promise<void>;
  logSubmissionActivity: (
    activity: MakeFieldsOptional<ActivityLogCreate, 'actorId' | 'submissionId' | 'metadata'>,
    reFetchWorkspace?: boolean,
  ) => Promise<void>;
  removeContactFromSubmissionMarket: (submissionMarketId: string, contact: Contact) => Promise<void>;
  arfSent: (data: ArfSentData) => Promise<void>;
  arfRequest: (data: ArfRequestData) => Promise<void>;
  updateSubmissionTasks: (data: SubmissionStatusUpdateData) => Promise<void>;
}

export type SubmissionWorkspaceProps = SubmissionWorkspaceActions & SubmissionWorkspaceState;

export const SubmissionWorkspaceInitState: Omit<SubmissionWorkspaceState, 'isReFetching'> = {
  submission: null,
  partialSubmission: null,
  quotes: [],
  layers: [],
  markets: [],
  activities: [],
};
