import { Transition } from 'history';
import { ReactElement, ReactNode, useCallback, useState } from 'react';
import { uuid } from 'short-uuid';
import { Box, Button, ButtonProps, Stack, SxProps } from '@common-components';
import { useMount } from 'hooks';
import { messages } from 'i18n';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { useLocation, useNavigateBack } from 'broker/hooks';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { InnerPageHeader } from './InnerPageHeader';
import { InnerPageProps, innerPageSpacing } from './types';

export type FooterButtonCallback = (innerPageProps: InnerPageProps) => ButtonProps;

interface InnerPageLayoutProps {
  sx?: SxProps;
  title: string;
  subTitle?: string;
  className?: string;
  children: (innerPageProps: InnerPageProps) => ReactElement | null;
  beforeNavigation?: (transition: Transition) => Promise<boolean>;
  footer?: (innerPageProps: InnerPageProps) => ReactNode;
  footerButtons?: {
    cancelButton?: FooterButtonCallback;
    proceedButton: FooterButtonCallback;
  };
  headerSideContent?: (innerPageProps: InnerPageProps) => ReactNode;
  headerTopContent?: (innerPageProps: InnerPageProps) => ReactNode;
  headerBottomContent?: (innerPageProps: InnerPageProps) => ReactNode;
  hideBackButton?: boolean;
  onCloseOverride?: () => void;
  outerDirtyState?: {
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
  };
  onDiscardCallback?: () => void;
}
export default function InnerPageLayout({
  title,
  subTitle,
  className,
  footer,
  footerButtons,
  headerSideContent,
  headerTopContent,
  headerBottomContent,
  children,
  beforeNavigation,
  hideBackButton,
  onCloseOverride,
  outerDirtyState,
  onDiscardCallback,
  sx,
}: InnerPageLayoutProps) {
  const [isInnerDirty, setIsInnerDirty] = useState(false);
  const { isDirty, setIsDirty } = outerDirtyState || { isDirty: isInnerDirty, setIsDirty: setIsInnerDirty };
  const location = useLocation();
  const navigateBack = useNavigateBack();
  const onClose = useCallback(() => {
    if (onCloseOverride) {
      onCloseOverride();
    } else {
      navigateBack();
    }
  }, [navigateBack, onCloseOverride]);

  const { registerInnerPage, unregisterInnerPage } = useUiStoreActions();

  useMount(() => {
    const uniqueId = uuid();
    registerInnerPage(uniqueId);
    return () => {
      unregisterInnerPage(uniqueId);
    };
  });

  const innerPageProps = { isDirty, setIsDirty, onClose };

  const { children: cancelButtonText, ...cancelButtonProps } = footerButtons?.cancelButton?.(innerPageProps) || {
    variant: 'outlined',
    onClick: onClose,
    children: messages.buttons.cancel,
  };
  const { children: proceedButtonText, ...proceedButtonProps } = footerButtons?.proceedButton?.(innerPageProps) || {};

  const ownBeforeNavigation = useCallback(
    async (transition: Transition) => {
      if (await beforeNavigation?.(transition)) {
        return true;
      }

      // Allow navigation if the transition is only about query param changes
      return transition.location.pathname === location.pathname;
    },
    [beforeNavigation, location],
  );

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={ownBeforeNavigation} onDiscardCallback={onDiscardCallback}>
      <Stack className={className} overflow="hidden" height={1}>
        <InnerPageHeader
          hideBackButton={hideBackButton}
          onBack={onClose}
          title={title}
          subTitle={subTitle}
          innerPageProps={innerPageProps}
          headerSideContent={headerSideContent}
          headerBottomContent={headerBottomContent}
          headerTopContent={headerTopContent}
        />

        <Box height={4} bgcolor="grey.100" borderTop={1} borderColor="divider" />
        <Box flex={1} overflow="hidden" bgcolor="grey.100">
          <Box p={innerPageSpacing} overflow="auto" bgcolor="common.white" height={1} sx={sx}>
            {children(innerPageProps)}
          </Box>
        </Box>

        {footer && (
          <Box px={3} py={1} borderTop={1} borderColor="divider">
            {footer(innerPageProps)}
          </Box>
        )}
        {footerButtons && (
          <Box px={3} py={1} borderTop={1} borderColor="divider">
            <Stack direction="row" gap={1} justifyContent="flex-end">
              <Button variant="outlined" {...cancelButtonProps}>
                {cancelButtonText}
              </Button>
              <Button variant="contained" {...proceedButtonProps}>
                {proceedButtonText}
              </Button>
            </Stack>
          </Box>
        )}
      </Stack>
    </DirtyRouteGuard>
  );
}
