import { MouseEvent } from 'react';
import { IconButton, NewMenu, Stack } from '@common-components';
import { Add as AddIcon, EditOutlined as EditOutlinedIcon, MoreVert } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { AgenciesColumns, columnConfig } from './AgenciesTableHeader';
import { AgencyOnClick, ExtendedAgency } from './types';

interface AgenciesTableItemMenuProps {
  extendedAgency: ExtendedAgency;
  onAddAgent: () => void;
  onEdit: AgencyOnClick;
}

export default function AgenciesTableItemMenu({ extendedAgency, onAddAgent, onEdit }: AgenciesTableItemMenuProps) {
  const moreOptionsMenu = useOptionsMenu();

  return (
    <Stack
      flex={columnConfig[AgenciesColumns.MoreActions].flex}
      alignItems={columnConfig[AgenciesColumns.MoreActions].align}
    >
      <IconButton
        variant="text"
        color="secondary"
        icon={MoreVert}
        onClick={(e?: MouseEvent<HTMLButtonElement>) => {
          moreOptionsMenu.openMenu(e);
        }}
      />
      <NewMenu
        optionsMenuState={moreOptionsMenu}
        placement="bottom-right"
        menuItems={[
          {
            key: 'more-actions',
            items: [
              {
                label: messages.agenciesPage.editAgency,
                startAdornment: EditOutlinedIcon,
                onClick: () => {
                  onEdit({ id: extendedAgency.id });
                },
              },
              {
                label: messages.agenciesPage.addAgent,
                startAdornment: AddIcon,
                onClick: () => {
                  onAddAgent();
                },
              },
            ],
          },
        ]}
      />
    </Stack>
  );
}
