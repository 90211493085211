import { EmailTemplateType, SubmissionEmailLabel } from 'enums';
import { EmailEditorPageMode } from 'broker/enums';

type EmailTemplateTypeConfig = {
  [templateType in EmailTemplateType]: EmailTemplateTypeProps;
};

export interface EmailTemplateTypeProps {
  backOfficeTemplate: boolean;
  submissionEmailLabel?: SubmissionEmailLabel;
  // When this mapping is defined, the template will automatically be available
  // in the customer reply menu;
  // The selected email editor mode will be set accordingly when clicked.
  emailEditorModeForCustomerReply?: EmailEditorPageMode;
}

export const EmailTemplateConfig: EmailTemplateTypeConfig = {
  [EmailTemplateType.InformationRequestedRetailer]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.InformationRequest,
  },
  [EmailTemplateType.BindOrder]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.BinderRequested,
  },
  [EmailTemplateType.PolicyOrder]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.PolicyRequested,
  },
  [EmailTemplateType.BinderCorrection]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.BinderRequested,
  },
  [EmailTemplateType.LayerBinder]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.BinderRequested,
  },
  [EmailTemplateType.LayerPolicy]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.PolicyRequested,
  },
  [EmailTemplateType.MarketDeclined]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Declination,
  },
  [EmailTemplateType.MarketingReport]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.MarketingUpdate,
  },
  [EmailTemplateType.RfqGeneral]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.SubmissionMarketing,
  },
  [EmailTemplateType.PolicyCorrection]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.PolicyRequested,
  },
  [EmailTemplateType.RfqAttachmentPoint]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.SubmissionMarketing,
  },
  [EmailTemplateType.ConceptualTower]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.TowerUpdate,
  },
  [EmailTemplateType.SubmissionUpdate]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.SubmissionUpdate,
  },
  [EmailTemplateType.NewBusinessProposal]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Proposal,
  },
  [EmailTemplateType.MissingInformationRequest]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.InformationRequest,
    emailEditorModeForCustomerReply: EmailEditorPageMode.MissingInformationRequest,
  },
  [EmailTemplateType.UserNotification]: {
    backOfficeTemplate: true,
  },
  [EmailTemplateType.PrimaryMarketing]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.SubmissionMarketing,
  },
  [EmailTemplateType.ExcessMarketing]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.SubmissionMarketing,
  },
  [EmailTemplateType.EscalationPolicy]: {
    backOfficeTemplate: true,
  },
  [EmailTemplateType.MarketEmailFollowup]: {
    backOfficeTemplate: false,
  },
  [EmailTemplateType.CustomerEmailFollowup]: {
    backOfficeTemplate: false,
  },
  [EmailTemplateType.CannotPursue]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.CannotPursue,
    emailEditorModeForCustomerReply: EmailEditorPageMode.CannotPursue,
  },
  [EmailTemplateType.PositiveExpectation]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.PositiveExpectation,
    emailEditorModeForCustomerReply: EmailEditorPageMode.PositiveExpectation,
  },
  [EmailTemplateType.NewQuote]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Quote,
  },
  [EmailTemplateType.SubmissionReceived]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Acknowledgment,
  },
  [EmailTemplateType.SubmissionAssigned]: {
    backOfficeTemplate: false,
  },
  [EmailTemplateType.Compose]: {
    backOfficeTemplate: false,
  },
  [EmailTemplateType.SalesDemoAcknowledgment]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Acknowledgment,
  },
  [EmailTemplateType.SalesDemoProposal]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Proposal,
  },
  [EmailTemplateType.SalesDemoDnOAcknowledgment]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Acknowledgment,
  },
  [EmailTemplateType.SalesDemoDnOProposal]: {
    backOfficeTemplate: false,
    submissionEmailLabel: SubmissionEmailLabel.Proposal,
  },
};
