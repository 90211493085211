import Markdown from 'markdown-to-jsx';
import { ReactNode, useMemo, useState } from 'react';
import { AIIconIndicator, Box, IconButton, Stack, Typography } from '@common-components';
import { Close, TagFaces } from '@icons';
import { messages } from 'i18n';
import { AIMessage } from 'types';
import { markdownStyles } from 'utils/markdown-styles';
import { AICitationLink } from './AICitationLink';
import { AICitationName } from './AICitationName';
import { CopyButton } from './CopyButton';
import MessageActions from './MessageActions';
import { FeedbackFormData } from './MessageActions/FeedbackForm/form-utils';

interface AIResponseMessageProps {
  message: AIMessage;
  onFeedbackSubmit: (data: FeedbackFormData, index: number) => Promise<void>;
  index: number;
  withFileLinks: boolean;
  isRichTextResponse: boolean;
}

export const AIResponseMessage = ({
  message,
  onFeedbackSubmit,
  index,
  withFileLinks,
  isRichTextResponse,
}: AIResponseMessageProps) => {
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const hoverActions: ReactNode[] = useMemo(
    () => [
      <IconButton
        size="small"
        key={showFeedbackForm ? 'close' : 'tagFaces'}
        tooltipContent={
          showFeedbackForm ? undefined : messages.submissionWorkspace.aiGeneratedContentPopup.feedback.tooltipContent
        }
        tooltipPlacement={showFeedbackForm ? undefined : 'left'}
        icon={showFeedbackForm ? Close : TagFaces}
        onClick={() => setShowFeedbackForm(!showFeedbackForm)}
        color="info"
      />,
      <CopyButton key="copy" content={message.content} copyAsHtml={isRichTextResponse} />,
    ],
    [showFeedbackForm, message, isRichTextResponse],
  );

  return (
    <MessageActions
      showFeedbackForm={showFeedbackForm}
      actions={message.isStreaming ? [] : hoverActions}
      onFeedbackSubmit={async (data: FeedbackFormData) => {
        await onFeedbackSubmit(data, index);
        // close feedback form after 3 seconds
        setTimeout(() => setShowFeedbackForm(false), 3000);
      }}
    >
      <Stack direction="row" gap={1} pr={2}>
        <Box pt={0.5}>
          <AIIconIndicator />
        </Box>
        <Typography alignSelf="center" variant="body2">
          <Markdown
            options={{
              overrides: {
                ...markdownStyles,
                AICitationLink: { component: withFileLinks ? AICitationLink : AICitationName },
              },
            }}
          >
            {message.content}
          </Markdown>
        </Typography>
      </Stack>
    </MessageActions>
  );
};
