import { fileTypeConfig } from '@common/config';
import { isEmpty } from 'lodash';
import { FileType } from 'enums';
import { useSearchBoxItems } from 'hooks/api/box';
import { useRetrieveInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import { getFileLabel } from 'utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useGetSubmissionCreationEmail } from './useGetSubmissionCreationEmail';

export function useGetMarketingEmailAttachments() {
  const { partialSubmission } = useSubmissionsWorkspace();

  // returns basic submission creation email data, including id and hasAttachments flag but not the attachments themselves
  const { submissionCreationEmail, isLoading: submissionCreationEmailLoading } = useGetSubmissionCreationEmail();

  // returns the full submission creation email data, including attachments
  const { data: expandedSubmissionCreationEmail, isInitialLoading: expandedSubmissionCreationEmailLoading } =
    useRetrieveInboundEmailMessage({
      id: submissionCreationEmail?.id || '',
      enabled: submissionCreationEmail?.hasAttachments,
    });

  const { items: submissionFiles, isLoading: submissionFilesLoading } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: partialSubmission?.boxFolderId || '' },
    enabled: !!partialSubmission?.boxFolderId,
  });

  const isLoading = submissionFilesLoading || submissionCreationEmailLoading || expandedSubmissionCreationEmailLoading;

  const submissionCreationEmailAttachments =
    expandedSubmissionCreationEmail?.attachedBoxFiles.map((file) => file.id) || [];

  // FileType.Submission is the label for the application files
  const applicationFileIds = submissionFiles
    .filter((file) => getFileLabel(file) === fileTypeConfig[FileType.Submission].text)
    .map((file) => file.id);

  const marketingEmailAttachments = !isEmpty(submissionCreationEmailAttachments)
    ? submissionCreationEmailAttachments
    : applicationFileIds;

  return { marketingEmailAttachments, isLoading };
}
