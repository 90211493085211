import { StateCompliance } from '@common/types';
import { getTimezoneLessDisplayDate } from '@common/utils/date-utils';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box, Divider, Grid, Stack, Typography } from '@common-components';
import { CoverageLine, FormMode, QuoteFileIdType } from 'enums';
import { useMount } from 'hooks';
import { messages } from 'i18n';
import { FormFieldsSpacingHorizontal, FormFieldsSpacingVertical } from 'themes';
import { Layer, Quote, SelectProduct, Submission, Suggestion } from 'types';
import { coverageLineConfig, getTodayDisplayDate } from 'utils';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormEditor from 'components/hookFormComponents/FormEditor';
import { FormMultiSelectAutocomplete } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { QuoteFilesInfoTooltip } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/formSteps/components/QuoteFilesInfoTooltip';
import { InsightPopulatedByAI } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/types';
import useGetQuoteInsights from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/useGetQuoteInsights';
import useSetFilesExplorerViewMode from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/useSetFilesExplorerViewMode';
import { QuoteConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/config';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import BinderDetails from './BinderDetails';
import AttachmentPointSelect from './components/AttachmentPointSelect';
import ProductSelect from './components/ProductSelect';
import PolicyDetails from './PolicyDetails';
import SubjectivitiesArrayFields from './SubjectivitiesArray';
import { useAutoInsertStateCompliance } from './useAutoInsertStateCompliance';

interface DetailsProps {
  mode: FormMode;
  fileType: QuoteFileIdType;
  submission: Submission;
  layers: Layer[];
  selectProducts: SelectProduct[];
  productsLoading: boolean;
  initialProductId?: string;
  initialLayer?: Layer;
  onAddNewLayer: (newLayer: Layer) => void;
  hasMarketQuoteFileLabel: boolean;
  extractedDataSuggestions?: Record<string, Suggestion>;
  quoteConfig: QuoteConfig;
  marketQuoteFileIds: string[];
  setInsightPopulatedByAI: (insight: InsightPopulatedByAI) => void;
  selectedQuote?: Quote;
  stateCompliance?: StateCompliance;
  isNonAdmittedProduct?: boolean;
}

const subjectivitiesDefaultValue = [{ value: '' }];

export default function Details({
  mode,
  fileType,
  submission,
  layers,
  selectProducts,
  productsLoading,
  initialProductId,
  initialLayer,
  onAddNewLayer,
  extractedDataSuggestions = {},
  quoteConfig,
  marketQuoteFileIds,
  hasMarketQuoteFileLabel,
  setInsightPopulatedByAI,
  selectedQuote,
  stateCompliance,
  isNonAdmittedProduct,
}: DetailsProps) {
  const { areMarketQuoteFieldsDisabled } = quoteConfig;
  const { watch, setValue } = useFormContext();
  const selectedProductId = watch(detailsFormFieldsConfig[DetailsFieldsNames.InsuranceProductId].id);
  const quoteDate: Date | undefined = watch(detailsFormFieldsConfig[DetailsFieldsNames.Date].id);
  const today = getTodayDisplayDate();
  const initialProduct = selectProducts.find((product) => product.id === initialProductId);
  const selectedProduct = selectProducts.find((product) => product.id === selectedProductId);
  const marketName = selectedProduct?.marketName ?? '';

  useMount(() => {
    setValue(detailsFormFieldsConfig[DetailsFieldsNames.FormType].id, fileType);
  });

  // state compliance is only shown for non-admitted products
  const isStateComplianceShown = !!isNonAdmittedProduct;

  useSetFilesExplorerViewMode(true, mode);
  useAutoInsertStateCompliance({
    selectedQuote,
    stateCompliance,
    isStateComplianceShown,
    mode,
    selectedProduct,
    submission,
  });

  // use marketQuoteFileIds if available; otherwise, fallback to selectedQuote?.marketQuoteFileIds
  const quoteFileIds = isEmpty(marketQuoteFileIds) ? selectedQuote?.marketQuoteFileIds : marketQuoteFileIds;
  const shouldCreateAIThread =
    mode !== FormMode.view &&
    (!isEmpty(selectedQuote?.marketQuoteFileIds) || hasMarketQuoteFileLabel) &&
    !quoteConfig.isBinderMode &&
    !quoteConfig.isPolicyMode;

  // trigger quote insights generation to be ready for the next step (Insights)
  useGetQuoteInsights({
    submission,
    marketQuoteFileIds: quoteFileIds ?? [],
    setInsightPopulatedByAI,
    shouldCreateAIThread,
  });

  return (
    <Box height={1} overflow="hidden" py={1} px={6} sx={{ position: 'relative', overflowY: 'auto' }}>
      <Stack height={1} maxWidth={640} mx="auto">
        {fileType !== QuoteFileIdType.MarketQuote && (
          <>
            <Stack pb={4} pt={2} gap={1}>
              <Typography variant="body1Bold">{quoteConfig.messages.headerTitle}</Typography>
            </Stack>
            <Stack mb={2}>
              {quoteConfig.isPolicyMode && (
                <PolicyDetails mode={mode} extractedDataSuggestions={extractedDataSuggestions} />
              )}
              {quoteConfig.isBinderMode && (
                <BinderDetails mode={mode} isStateComplianceShown={isStateComplianceShown} />
              )}
            </Stack>

            <Divider orientation="horizontal" flexItem variant="fullWidth" />
          </>
        )}

        <Stack gap={1}>
          <Typography variant="h6" my={3}>
            {messages.addQuotePage.fieldLabels.mainDetails}
          </Typography>
          <ProductSelect
            initialProductId={initialProductId}
            selectProducts={selectProducts}
            isLoading={productsLoading}
            isDisabled={!!areMarketQuoteFieldsDisabled}
          />
          <FormMultiSelectAutocomplete
            hideOptionalLabel
            freeSolo
            defaultValue={
              initialProduct?.coverageLines
                .map((productCoverageLine) => productCoverageLine.coverageLine)
                .filter((productCoverageLine) => submission.coverageLines.includes(productCoverageLine)) ?? []
            }
            options={Object.keys(coverageLineConfig)
              .filter((coverageLine) => submission.coverageLines.includes(coverageLine as CoverageLine))
              .map((coverageLine) => ({
                label: coverageLineConfig[coverageLine as CoverageLine].text,
                value: coverageLine,
              }))}
            {...detailsFormFieldsConfig[DetailsFieldsNames.CoverageLines]}
          />
          <AttachmentPointSelect
            layers={layers}
            submission={submission}
            onAddNewLayer={onAddNewLayer}
            selectedLayer={initialLayer}
          />
          <FormTextField
            disabled={areMarketQuoteFieldsDisabled}
            isNumberFormat
            numberFormatProps={{
              min: 1,
            }}
            fullWidth
            suggestion={extractedDataSuggestions[DetailsFieldsNames.Limit]}
            {...detailsFormFieldsConfig[DetailsFieldsNames.Limit]}
          />
          <Grid columnSpacing={FormFieldsSpacingVertical} rowSpacing={FormFieldsSpacingHorizontal} container>
            <Grid item xs={6}>
              <FormDatePicker
                optional
                disabled={areMarketQuoteFieldsDisabled}
                fullWidth
                defaultValueOverlayText={
                  quoteDate && getTimezoneLessDisplayDate(quoteDate) === today ? today : undefined
                }
                {...detailsFormFieldsConfig[DetailsFieldsNames.Date]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormDatePicker
                optional
                disabled={areMarketQuoteFieldsDisabled}
                fullWidth
                suggestion={extractedDataSuggestions[DetailsFieldsNames.ExpiresAt]}
                {...detailsFormFieldsConfig[DetailsFieldsNames.ExpiresAt]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                disabled={areMarketQuoteFieldsDisabled}
                suggestion={extractedDataSuggestions[DetailsFieldsNames.Premium]}
                fullWidth
                isNumberFormat
                numberFormatProps={{
                  min: 1,
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.Premium]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                disabled={areMarketQuoteFieldsDisabled}
                optional
                fullWidth
                isNumberFormat
                numberFormatProps={{
                  prefix: '',
                  suffix: '%',
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.AgentCommission]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                optional
                disabled={areMarketQuoteFieldsDisabled}
                fullWidth
                isNumberFormat
                suggestion={extractedDataSuggestions[DetailsFieldsNames.TriaPremium]}
                numberFormatProps={{
                  min: 1,
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.TriaPremium]}
              />
            </Grid>
          </Grid>
        </Stack>

        <Divider orientation="horizontal" sx={{ mb: 3 }} flexItem variant="fullWidth" />
        <Stack>
          <Typography variant="h6" my={3}>
            {messages.addQuotePage.fieldLabels.limitsAndDeductibles}
          </Typography>
          <FormTextField
            optional
            fullWidth
            disabled={areMarketQuoteFieldsDisabled}
            multiline
            rows={5}
            placeholder={messages.addQuotePage.fieldPlaceholders.subLimits}
            {...detailsFormFieldsConfig[DetailsFieldsNames.SubLimits]}
          />
        </Stack>
        <Divider orientation="horizontal" sx={{ mb: 3 }} flexItem variant="fullWidth" />
        <Stack>
          <Typography variant="h6" my={3}>
            {messages.addQuotePage.fieldLabels.taxesAndFees}
          </Typography>
          <Grid columnSpacing={FormFieldsSpacingVertical} rowSpacing={FormFieldsSpacingHorizontal} container>
            <Grid item xs={6}>
              <FormTextField
                optional
                fullWidth
                disabled={areMarketQuoteFieldsDisabled}
                isNumberFormat
                suggestion={extractedDataSuggestions[DetailsFieldsNames.CarrierFee]}
                numberFormatProps={{
                  min: 0,
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.CarrierFee]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                optional
                fullWidth
                disabled={areMarketQuoteFieldsDisabled}
                isNumberFormat
                numberFormatProps={{
                  min: 0,
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.FlowBrokerFee]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                optional
                fullWidth
                disabled={areMarketQuoteFieldsDisabled}
                isNumberFormat
                numberFormatProps={{
                  min: 0,
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.SurplusLineTax]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                optional
                fullWidth
                disabled={areMarketQuoteFieldsDisabled}
                isNumberFormat
                numberFormatProps={{
                  min: 0,
                }}
                {...detailsFormFieldsConfig[DetailsFieldsNames.SurplusStampingFee]}
              />
            </Grid>
          </Grid>
        </Stack>

        <Divider orientation="horizontal" sx={{ mb: 3 }} flexItem variant="fullWidth" />
        <Stack>
          <Typography variant="h6" my={1}>
            {messages.addQuotePage.fieldLabels.subjectivities}
          </Typography>
          <FormArrayFields
            key={detailsFormFieldsConfig[DetailsFieldsNames.Subjectivities].name}
            name={detailsFormFieldsConfig[DetailsFieldsNames.Subjectivities].name}
            FormArrayFieldComponent={SubjectivitiesArrayFields}
            disabled={areMarketQuoteFieldsDisabled}
            defaultValue={subjectivitiesDefaultValue}
            deleteButtonMargin={0}
          />
          {isStateComplianceShown && (
            <Box position="relative" height={300}>
              <FormEditor
                optional
                disabled={areMarketQuoteFieldsDisabled}
                {...detailsFormFieldsConfig[DetailsFieldsNames.StateCompliance]}
              />
            </Box>
          )}
        </Stack>
        <QuoteFilesInfoTooltip marketName={marketName} fileType={fileType} />
      </Stack>
    </Box>
  );
}
