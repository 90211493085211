import FormTextField from 'components/hookFormComponents/FormTextField';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

interface CommissionTextFieldProps {
  isDisabled: boolean;
  defaultValue?: string;
}

export function CommissionTextField({ isDisabled, defaultValue }: CommissionTextFieldProps) {
  return (
    <FormTextField
      disabled={isDisabled}
      optional
      fullWidth
      isNumberFormat
      numberFormatProps={{
        prefix: '',
        suffix: '%',
      }}
      {...detailsFormFieldsConfig[DetailsFieldsNames.FlowCommission]}
      {...(defaultValue && { defaultValue })}
    />
  );
}
