import { compact, isEmpty, toNumber } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { PartialSubmission } from 'types';
import { formatPrice, getTimezoneLessDisplayDate, htmlToPdfMakeWrapper } from 'utils';
import { FlowBinderLocationState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/FlowBinder/utils';

interface PdfContentProps {
  submission: PartialSubmission;
  details: FlowBinderLocationState;
  marketName: string;
}
const currency = '$';

export const getPdfContent = ({ submission, details, marketName }: PdfContentProps): Content =>
  compact([
    {
      style: 'tableExample',
      layout: 'noBorders',
      table: {
        widths: ['*', '*'],
        body: [
          [
            (submission.user?.firstName || '') + (submission.user?.lastName ? ` ${submission.user?.lastName}` : ''),
            {
              text: details.binderDate ? getTimezoneLessDisplayDate(details.binderDate) : '',
              alignment: 'right',
            },
          ],
          [submission.organizationName || '', ''],
        ],
      },
    },
    {
      text: '\n\n',
      lineHeight: 1.1,
    },
    {
      text: 'Flow Specialty Insurance Services LLC is pleased to provide the following confirmation of binding. Please review this Binder for accuracy and contact me with any questions or discrepancies. If we do not hear from you, it will be understood that this Conditional Binder provides an accurate description of the agreed upon terms of coverage.',
    },
    {
      text: '\n\n',
      lineHeight: 1.1,
    },
    {
      text: [{ text: 'Insured: ', bold: true }, { text: submission.insuredName || '' }],
    },
    submission.insuredPrimaryAddress?.formattedAddress && {
      text: [{ text: 'Insured Address: ', bold: true }, { text: submission.insuredPrimaryAddress.formattedAddress }],
    },
    {
      text: [{ text: 'Capacity Provider: ', bold: true }, { text: marketName }],
    },
    details.policyDetails?.policyNumber && {
      text: [{ text: 'Policy Number: ', bold: true }, { text: details.policyDetails?.policyNumber }],
    },
    details.policyDetails?.effectiveDate && {
      text: [
        { text: 'Effective Date: ', bold: true },
        { text: getTimezoneLessDisplayDate(details.policyDetails?.effectiveDate) },
      ],
    },
    {
      text: [
        { text: 'Premium: ', bold: true },
        { text: currency + formatPrice(Number(details.selectedQuote.premium), 2) },
      ],
    },
    details.selectedQuote.triaPremium && {
      text: [
        { text: 'TRIA premium: ', bold: true },
        { text: currency + formatPrice(Number(details.selectedQuote.triaPremium), 2) },
      ],
    },
    details.selectedQuote.carrierFee && {
      text: [
        { text: 'Capacity provider fee: ', bold: true },
        { text: currency + formatPrice(Number(details.selectedQuote.carrierFee), 2) },
      ],
    },
    details.selectedQuote.flowBrokerFee && {
      text: [
        { text: 'Flow broker fee: ', bold: true },
        { text: currency + formatPrice(Number(details.selectedQuote.flowBrokerFee), 2) },
      ],
    },
    details.selectedQuote.surplusLineTax && {
      text: [
        { text: 'Surplus line tax: ', bold: true },
        { text: currency + formatPrice(Number(details.selectedQuote.surplusLineTax), 2) },
      ],
    },
    details.selectedQuote.surplusStampingFee && {
      text: [
        { text: 'Surplus stamping fee: ', bold: true },
        { text: currency + formatPrice(Number(details.selectedQuote.surplusStampingFee), 2) },
      ],
    },
    details.selectedQuote.subLimits && {
      text: 'Limits & Deductibles: ',
      bold: true,
    },
    details.selectedQuote.subLimits && {
      text: details.selectedQuote.subLimits,
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    details.selectedQuote.agentCommission && {
      text: [{ text: 'Agent Commission: ', bold: true }, { text: `${details.selectedQuote.agentCommission}%` }],
    },

    {
      text: [
        { text: 'Total: ', bold: true },
        {
          text:
            currency +
            formatPrice(
              toNumber(details.selectedQuote.premium) +
                toNumber(details.selectedQuote.triaPremium || 0) +
                toNumber(details.selectedQuote.carrierFee || 0) +
                toNumber(details.selectedQuote.flowBrokerFee || 0) +
                toNumber(details.selectedQuote.surplusLineTax || 0) +
                toNumber(details.selectedQuote.surplusStampingFee || 0),
              2,
            ),
        },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Please refer to the attached for a full breakdown of limits, retentions, and applicable clauses.',
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    details.binderSubjectivities?.some((subjectivity) => !isEmpty(subjectivity)) && {
      text: `Subjectivities:`,
      bold: true,
    },
    {
      ul: compact(details.binderSubjectivities?.map((subjectivity) => subjectivity) || []),
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    details.binderStateCompliance && {
      text: 'State Compliance:',
      bold: true,
    },
    details.binderStateCompliance ? htmlToPdfMakeWrapper(details.binderStateCompliance) : undefined,
    {
      text: '\n',
      lineHeight: 1.1,
    },

    {
      text: [
        {
          text: 'The insurance policy will be issued to incorporate the forms, endorsements, and other details specified in the attached Binder of Insurance, provided that all terms and conditions of this Binder of Insurance have been met. If additional information has been requested and it reflects any change in risk that could be deemed to be a material change in the underwriting exposures, the carrier may, at its option, cancel, rescind or modify this Binder of Insurance or the insurance policy in accordance with all applicable laws. ',
        },
        {
          text: 'We reserve the right to cancel this if the subjectivities/conditions are not received within 10 days.',
          bold: true,
        },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: [
        { text: 'Cancellation: ', bold: true },
        {
          text: 'Insurance coverage cannot be canceled flat; premium must be paid for the time insurance has been in force and may be subject to subject to minimum earned premium and fees.  ',
        },
        {
          text: 'Coverage may be cancelled by either the insured or the insurer by written notice to the other and is subject to cancellation terms specified in the attached.',
          decoration: 'underline',
        },
        {
          text: '\n',
          lineHeight: 1.1,
        },
      ],
    },
    {
      text: 'The person or firm to whom this Binder of Insurance has been issued agrees to be responsible for the payment of all premiums, including any applicable audit premiums, whether collectible or not, and for the return of unearned premium and unearned commission that may become due.  ',
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Thank you for the opportunity to partner with you. We appreciate your business and hope that we can be of further service to you in the future.  Please contact us with any questions.',
    },
  ]);
