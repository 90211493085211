import { Stack } from '@common-components';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import { attachmentPointText, formatPriceOrNA } from 'utils';
import { getQuoteMetaData } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesModal/utils';
import HeaderItem from './HeaderItem';

interface QuoteInfoProps {
  quote: Quote;
  layer: Layer;
}

export function QuoteInfo({ quote, layer }: QuoteInfoProps) {
  const { limitComponent: title } = getQuoteMetaData(quote, layer);
  const { attachmentPoint } = layer;
  const price = quote.premium;

  return (
    <Stack direction="row" gap={1}>
      <HeaderItem title={attachmentPointText(attachmentPoint)} subTitle={messages.quoteItemLabels.attachmentPoint} />
      <HeaderItem title={formatPriceOrNA(price)} subTitle={messages.quoteItemLabels.premium} />
      <HeaderItem title={title} subTitle={messages.quoteItemLabels.limit} />
    </Stack>
  );
}
