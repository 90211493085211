import { uniq } from 'lodash';
import { CoverageLine } from '../enums';
import { lobCoverageLineMapping } from '../config';

export const getLineOfBusinessForCoverageLine = (coverageLine: CoverageLine) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [lob, coverageLines] of Object.entries(lobCoverageLineMapping)) {
        if (coverageLines.includes(coverageLine)) {
            return lob as CoverageLine;
        }
    }
    return undefined;
};

export const getCoverageLinesForLineOfBusiness = (lineOfBusiness: CoverageLine) =>
    lobCoverageLineMapping[lineOfBusiness] || [];

export const areCoverageLinesIncludedInLineOfBusiness = (
    coverageLines: CoverageLine[],
    lineOfBusiness: CoverageLine,
) => {
    const lineOfBusinessCoverageLines = uniq([lineOfBusiness, ...(lobCoverageLineMapping[lineOfBusiness] || [])]);
    return coverageLines.some((coverageLine) => lineOfBusinessCoverageLines.includes(coverageLine as CoverageLine));
};

export const applySpecialLinesOfBusinessCoverageLinesRules = (coverageLines: CoverageLine[]): CoverageLine[] => {
    const updatedCoverageLines = [...coverageLines];
    if (areCoverageLinesIncludedInLineOfBusiness(updatedCoverageLines, CoverageLine.Cyber)) {
        // according to product requirements, if the coverage lines selected are from the Cyber line of business - always add Cyber to the pre-selected filter
        updatedCoverageLines.push(CoverageLine.Cyber);
    }
    return uniq(updatedCoverageLines);
};
