export enum RouteKey {
  WorkspaceTab = 'WorkspaceTab',
  SubmissionAutonomousCreation = 'SubmissionAutonomousCreation',
  SubmissionEdit = 'SubmissionEdit',
  Quote = 'Quote',
  ConceptualTower = 'ConceptualTower',
  ExpiringTower = 'ExpiringTower',
  Home = 'Home',
  EmailReply = 'EmailReply',
  QuoteStatusRequest = 'QuoteStatusRequest',
  Marketing = 'Marketing',
  MarketingReport = 'MarketingReport',
  YoYReport = 'YoYReport',
  TemplatesPage = 'TemplatesPage',
  NewSubmission = 'NewSubmission',
  QuoteStatus = 'QuoteStatus',
  AddMarkets = 'AddMarkets',
  AddProducts = 'AddProducts',
  SelectProducts = 'SelectProducts',
  AcknowledgmentEmail = 'AcknowledgmentEmail',
  UserMarket = 'UserMarket',
  Agency = 'Agency',
  Agent = 'Agent',
  SingleMarket = 'SingleMarket',
  CreateCustomerContact = 'CreateCustomerContact',
  EmailEditor = 'EmailEditor',
  CompareQuotes = 'CompareQuotes',
  InformationRequest = 'InformationRequest',
  Declination = 'Declination',
  QuoteComparisonInsights = 'QuoteComparisonInsights',
  CompareQuotesSideBySide = 'CompareQuotesSideBySide',
  MarketingUpdate = 'MarketingUpdate',
  MarketingUpdateEmail = 'MarketingUpdateEmail',
  ProposalFlow = 'ProposalFlow',
  FlowBinder = 'FlowBinder',
}
