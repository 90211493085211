export enum HeraldProductLine {
    GeneralLiability = 'general_liability',
    MiscellaneousProfessionalLiability = 'miscellaneous_professional_liability',
    ManagementLiability = 'management_liability',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    WorkerCompensation = 'workers_compensation',
    BusinessOwnersPolicy = 'business_owners_policy',
    Property = 'property',
    Cyber = 'cyber',
}
