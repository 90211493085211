import { compact } from 'lodash';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AIFeature, AIMessageType, UserRole } from 'enums';
import { useAIAPI, useBoolean, useCurrentUser, useStateWithServerStreaming } from 'hooks';
import { AICreateThreadOutput, AIMessage, ShareAIThreadInput } from 'types';
import { logger } from 'utils';
import { removeCitations, resolveCitations } from './citations-utils';
import { useMultipleAIFeaturesContext } from './MultipleAIFeaturesContextProvider';
import { ThreadEnrichmentData } from './types';
import { useResolveEnrichmentData } from './useResolveEnrichmentData';
import useShareThreadOnUnmount from './useShareThreadOnUnmount';

export type CreateThreadAction = () => Promise<AICreateThreadOutput | null>;
type CreateThreadActionWithMultipleResponseMessages = () => Promise<
  (Omit<AICreateThreadOutput, 'prompt' | 'response'> & { messages: AIMessage[] }) | null
>;

export interface AIContextData {
  feature: AIFeature;
  messages: AIMessage[];
  initialMessages?: AIMessage[];
  assistantId?: string;
  threadId?: string;
  createAIThread: (action: CreateThreadAction) => Promise<AICreateThreadOutput | null>;
  sendAIThread: (input: Pick<ShareAIThreadInput, 'feedback' | 'messages'>) => Promise<void>;
  addFollowupMessage: (followupQuestion: string) => Promise<void>;
  createThreadFromInitialMessages: (newMessage: string) => Promise<void>;
  isContentStreaming: boolean;
  isLoading: boolean;
  isRegenerating: boolean;
  setCreateThreadAction: (action: () => CreateThreadAction) => void;
  regenerateAIThread: () => Promise<void>;
  enrichmentData?: ThreadEnrichmentData;
}

export const AIFeatureContext = createContext<AIContextData | undefined>(undefined);

export function SingleAIFeatureContextProvider({
  children,
  feature,
  initialMessages,
}: {
  children: ReactNode;
  feature: AIFeature;
  initialMessages?: AIMessage[];
}) {
  const { state, setFeatureState } = useMultipleAIFeaturesContext();
  const featureState = state[feature];
  const { shareAIThread, addMessageAndRun, createThread } = useAIAPI();
  const { me } = useCurrentUser();
  const [createThreadAction, setCreateThreadAction] = useState<CreateThreadAction | undefined>(undefined);

  const [streamedContent, isContentStreaming, stopStreaming] = useStateWithServerStreaming(feature);

  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean(false);
  const [isRegenerating, { on: startRegenerating, off: stopRegenerating }] = useBoolean(false);

  useEffect(() => {
    setFeatureState(feature, (currentState) => {
      if (currentState) {
        return {
          ...currentState,
          messages: initialMessages,
        };
      }
      return {
        messages: initialMessages,
        threadId: undefined,
        assistantId: undefined,
        uploadedFiles: [],
        enrichmentData: undefined,
      };
    });
  }, [feature, initialMessages, setFeatureState]);

  const sendAIThread = useCallback(
    async (input: Pick<ShareAIThreadInput, 'feedback' | 'messages'>) => {
      await shareAIThread({
        ...input,
        feature,
        threadId: featureState?.threadId || '',
        user: me?.email || '',
      });
    },
    [shareAIThread, feature, featureState?.threadId, me?.email],
  );

  useShareThreadOnUnmount({ messages: featureState?.messages, sendAIThread });

  const resolveEnrichmentData = useResolveEnrichmentData();

  const internalCreateAIThread = useCallback(
    async (action: CreateThreadActionWithMultipleResponseMessages, initMessages?: AIMessage[]) => {
      try {
        stopStreaming();
        startLoading();
        setFeatureState(feature, () => ({
          messages: initMessages ?? [],
          threadId: undefined,
          assistantId: undefined,
          uploadedFiles: [],
          enrichmentData: undefined,
        }));

        const result = await action();
        if (!result) {
          return null;
        }

        const newMessages = result.messages.map((message) => {
          if (message.type === AIMessageType.Response) {
            // For now citations is opened on all features for all users, and for recommendations to BackOffice users only
            // TODO: https://capitola-ins.atlassian.net/browse/CAP-4679
            const resolvedResponse =
              feature !== AIFeature.ProductRecommendation || me?.role === UserRole.BackOffice
                ? resolveCitations(message.content, result.citations)
                : removeCitations(message.content, result.citations);

            return { type: AIMessageType.Response, content: resolvedResponse };
          }
          return message;
        });

        const enrichmentData = await resolveEnrichmentData(
          result.uploadedFileIds,
          result.inboundEmailIds,
          result.productsEmailSignals,
        );

        setFeatureState(feature, () => ({
          messages: newMessages,
          threadId: result.threadId,
          assistantId: result.assistantId,
          enrichmentData,
        }));

        return result;
      } finally {
        stopLoading();
      }
    },
    [feature, me?.role, resolveEnrichmentData, setFeatureState, startLoading, stopLoading, stopStreaming],
  );

  const createAIThread = useCallback(
    async (action: CreateThreadAction) => {
      const ownAction = async () => {
        const result = await action();
        if (result) {
          return {
            ...result,
            messages: [
              { type: AIMessageType.Prompt, content: result.prompt },
              { type: AIMessageType.Response, content: result.response },
            ],
          };
        }
        return null;
      };
      const response = await internalCreateAIThread(ownAction);
      if (response) {
        return {
          ...response,
          prompt: response.messages[0].content,
          response: response.messages[1].content,
        };
      }
      return null;
    },
    [internalCreateAIThread],
  );

  const regenerateAIThread = useCallback(async () => {
    try {
      startRegenerating();
      if (createThreadAction) {
        await createAIThread(createThreadAction);
      }
    } finally {
      stopRegenerating();
    }
  }, [createAIThread, createThreadAction, startRegenerating, stopRegenerating]);

  const createThreadFromInitialMessages = useCallback(
    async (newMessage: string) => {
      const messagesToSend = [...(initialMessages || []), { type: AIMessageType.Prompt, content: newMessage }];
      const ownAction = async () => {
        const newThread = await createThread({
          messages: messagesToSend,
          feature,
          streamingContext: feature,
        });
        if (newThread) {
          return {
            ...newThread,
            messages: [...messagesToSend, { type: AIMessageType.Response, content: newThread.response }],
            citations: [],
          };
        }
        return null;
      };
      await internalCreateAIThread(ownAction, messagesToSend);
    },
    [createThread, feature, initialMessages, internalCreateAIThread],
  );

  const addFollowupMessage = useCallback(
    async (followupQuestion: string) => {
      if (!featureState?.threadId || !featureState?.assistantId) {
        logger.log('error', {
          message: 'An attempt to add followup question without an existing thread context',
          feature,
        });
        return;
      }

      try {
        startLoading();
        setFeatureState(feature, (currentState) => ({
          messages: [...(currentState?.messages ?? []), { type: AIMessageType.Prompt, content: followupQuestion }],
        }));
        const addMessageAndRunResult = await addMessageAndRun({
          prompt: followupQuestion,
          streamingContext: feature,
          threadId: featureState.threadId,
          assistantId: featureState.assistantId,
        });
        if (addMessageAndRunResult) {
          setFeatureState(feature, (currentState) => ({
            messages: [
              ...(currentState?.messages ?? []),
              { type: AIMessageType.Response, content: addMessageAndRunResult.response },
            ],
          }));
        }
      } finally {
        stopLoading();
      }
    },
    [addMessageAndRun, feature, featureState, setFeatureState, startLoading, stopLoading],
  );

  const messages = useMemo(
    () =>
      compact([
        ...(featureState?.messages ?? []),
        isContentStreaming ? { content: streamedContent, type: AIMessageType.Response, isStreaming: true } : undefined,
      ]),
    [featureState?.messages, isContentStreaming, streamedContent],
  );

  const contextValue: AIContextData = useMemo(
    () => ({
      feature,
      messages,
      initialMessages,
      enrichmentData: featureState?.enrichmentData,
      createAIThread,
      addFollowupMessage,
      createThreadFromInitialMessages,
      isContentStreaming,
      isLoading,
      sendAIThread,
      isRegenerating,
      setCreateThreadAction,
      regenerateAIThread,
      threadId: featureState?.threadId,
    }),
    [
      feature,
      messages,
      initialMessages,
      featureState,
      createAIThread,
      addFollowupMessage,
      createThreadFromInitialMessages,
      isContentStreaming,
      isLoading,
      sendAIThread,
      isRegenerating,
      regenerateAIThread,
    ],
  );

  return <AIFeatureContext.Provider value={contextValue}>{children}</AIFeatureContext.Provider>;
}
