import { Citation } from '@common/types';
import { AIFeature, AIMessageType } from 'enums';

export interface ThreadExecutionParams {
  assistantId: string;
  threadId: string;
}

export interface CreateFollowupMessageInput {
  threadId: string;
  assistantId: string;
  prompt: string;
  streamingContext?: string;
}

export interface CreateThreadInput {
  messages: AIMessage[];
  feature: AIFeature;
  streamingContext?: string;
}

export interface CreateThreadOutput {
  threadId: string;
  assistantId: string;
  response: string;
}

export interface CreateFollowupMessageOutput {
  response: string;
}

export interface ShareAIThreadInput {
  feedback?: string;
  messages: AIMessage[];
  feature: string;
  threadId: string;
  user: string;
}

export interface ShareAIThreadOutput {}

export enum InsuranceProductFileType {
  DeclinationEmail = 'DeclinationEmail',
  QuoteEmail = 'QuoteEmail',
}

export interface FileDescriptor {
  fileId: string;
  fileName: string;
  fileType: InsuranceProductFileType;
  inboundEmailMessageId?: string;
}

export interface ProductEmailSignals {
  name: string;
  emails: FileDescriptor[];
}

export interface AICreateThreadOutput {
  threadId: string;
  assistantId: string;
  prompt: string;
  response: string;
  uploadedFileIds?: string[];
  inboundEmailIds?: string[];
  productsEmailSignals?: ProductEmailSignals[];
  citations: Citation[];
}

export interface AIMessage {
  content: string;
  unresolvedPrompt?: string;
  type: AIMessageType;
  isStreaming?: boolean;
}
