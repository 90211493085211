import { Stack } from '@common-components';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { DynamicObjectFieldsComponentProps } from './DynamicObjectWrapper';

export default function AreaOfPractice({ onBlur, ownBuildName }: DynamicObjectFieldsComponentProps) {
  return (
    <Stack direction="row" gap={3}>
      <FormTextField enhancedRequired onBlur={onBlur} {...ownBuildName('area', 'Area')} />
      <FormTextField
        enhancedRequired
        isNumberFormat
        numberFormatProps={{
          min: 1,
          max: 100,
          prefix: '',
          suffix: '%',
        }}
        onBlur={onBlur}
        {...ownBuildName('percentage', 'Percentage')}
      />
    </Stack>
  );
}
