import { AutoAwesomeOutlined } from '@icons';
import IconBadge from './IconBadge';
import { Avatar } from './mui-index';

export type AIIconIndicatorSize = 'extraSmall' | 'small' | 'medium' | 'large';
interface AIIconIndicatorProps {
  size?: AIIconIndicatorSize;
  disabled?: boolean;
}

const iconSizeProps: Record<AIIconIndicatorSize, any> = {
  extraSmall: {
    size: 20,
    fontSize: 12,
  },
  small: {
    size: 28,
    fontSize: 18,
  },
  medium: {
    size: 40,
    fontSize: 18,
  },
  large: {
    size: 52,
    fontSize: 28,
  },
};

export function AIIconIndicator({ size = 'small', disabled }: AIIconIndicatorProps) {
  return (
    <Avatar
      sx={{
        bgcolor: 'common.white',
        width: iconSizeProps[size].size + 6,
        height: iconSizeProps[size].size + 6,
      }}
    >
      <IconBadge
        IconComponent={AutoAwesomeOutlined}
        bgcolor={disabled ? 'grey.200' : 'green.300'}
        badgeDimension={{ height: iconSizeProps[size].size, width: iconSizeProps[size].size }}
        size="extraSmall"
        sx={{
          borderBottomLeftRadius: 0,
          ':hover': {
            bgcolor: disabled ? 'grey.200' : 'green.400',
          },
        }}
      />
    </Avatar>
  );
}
