import { isArray } from 'lodash';
import { Stack, Typography } from '@common-components';
import { Suggestion } from 'types';
import { arraysContainSameValues } from 'utils';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import FormCheckbox from 'components/hookFormComponents/FormCheckbox';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormTextField from 'components/hookFormComponents/FormTextField';
import IndustryAutoComplete from 'broker/components/common/IndustryAutoComplete';
import { AIReason } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIReason';
import Address from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/components/Address';
import AreaOfPractice from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/components/AreaOfPractice';
import { DynamicObjectWrapper } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/components/DynamicObjectWrapper';
import HeraldClaimEvent from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/components/HeraldClaimEvent';
import HeraldFormMultiSelectAutocomplete from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/components/HeraldFormMultiSelectAutocomplete';
import {
  FormStateBaseProp,
  FormStateBaseProps,
  heraldIndustryQuestionParameterId,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { heraldInputTypesConfig } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/herald-input-types-config';
import { heraldParametersDefaultValuesConfig } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/herald-parameters-default-values-config';

interface FieldBaseProps {
  name: string;
  label: string;
}

export default function mapHeraldParameterToFormComponent(
  heraldParameter: HeraldNormalizedParameter,
  value: FormStateBaseProps | FormStateBaseProps[],
  fieldBaseProps: FieldBaseProps,
  onBlur: () => boolean | undefined,
  isArrayFields = false,
  suggestion?: Suggestion,
) {
  const config = heraldInputTypesConfig[heraldParameter.input_type];
  const baseProps = {
    name: `${fieldBaseProps.name}.${FormStateBaseProp.Main}`,
    id: `${fieldBaseProps.name}.${FormStateBaseProp.Main}`,
    label: fieldBaseProps.label,
    placeholder: config.placeholder,
    helperText: heraldParameter.helperText,
  };

  const resolvedSuggestion = suggestion
    ? {
        ...suggestion,
        reason: <AIReason suggestion={suggestion} />,
      }
    : undefined;

  let component: JSX.Element | null = null;
  if (heraldParameter.parameter_id === heraldIndustryQuestionParameterId) {
    return (
      <IndustryAutoComplete fieldProps={baseProps} onBlur={onBlur} suggestion={resolvedSuggestion} enhancedRequired />
    );
  }

  // Note: currently, this variable is used only in the basic components, not in the Checkbox, Address and ClaimEvent components
  const isOptional = !heraldParameter.isRequired;
  const heraldDefaultValue = heraldParametersDefaultValuesConfig[heraldParameter.parameter_id];

  const isCurrentValueEqualToDefault = () => {
    // currently we don't support adding default values to array questions (form array fields, not multiple answer questions)
    if (isArrayFields || isArray(value)) {
      return false;
    }
    if (!heraldDefaultValue) {
      return false;
    }

    // if multiple answer question
    if (isArray(value?.[FormStateBaseProp.Main])) {
      if (!isArray(heraldDefaultValue)) {
        return false;
      }
      // check that all question answers are equal to the default value answers
      return arraysContainSameValues(value?.[FormStateBaseProp.Main], heraldDefaultValue);
    }
    return value?.[FormStateBaseProp.Main] === heraldDefaultValue;
  };

  const heraldDeafaultValueAsString = Array.isArray(heraldDefaultValue)
    ? heraldDefaultValue.join(', ')
    : heraldDefaultValue?.toString();
  const defaultOverlayText = isCurrentValueEqualToDefault() ? heraldDeafaultValueAsString : undefined;

  switch (config.fieldType) {
    case 'TextField':
      component = (
        <FormTextField
          {...baseProps}
          isNumberFormat={config.numberFormat}
          numberFormatProps={{
            prefix: config.isMoney ? undefined : '',
            thousandSeparator: config.thousandSeparator ?? (config.isDecimal || config.isInteger),
            decimalScale: config.isDecimal ? undefined : 0,
          }}
          defaultValueOverlayText={defaultOverlayText}
          onBlur={onBlur}
          optional={isOptional}
          suggestion={resolvedSuggestion}
          enhancedRequired
        />
      );
      break;
    case 'AutoComplete':
      component = (
        <FormAutocomplete
          {...baseProps}
          onBlur={onBlur}
          options={heraldParameter.schema.enum?.map((item) => ({ label: item, value: item })) || []}
          optional={isOptional}
          defaultValueOverlayText={defaultOverlayText}
          suggestion={resolvedSuggestion}
          enhancedRequired
        />
      );
      break;
    case 'MultiSelectAutocomplete':
      component = (
        <HeraldFormMultiSelectAutocomplete
          {...baseProps}
          onBlur={onBlur}
          options={heraldParameter.schema.items?.enum?.map((item) => ({ label: item, value: item })) || []}
          optional={isOptional}
          defaultValueOverlayText={defaultOverlayText}
          enhancedRequired
        />
      );
      break;
    case 'DatePicker':
      component = (
        <FormDatePicker
          onChange={onBlur}
          {...baseProps}
          yearsOnly={config.yearsOnly}
          optional={isOptional}
          enhancedRequired
          suggestion={resolvedSuggestion}
        />
      );
      break;
    case 'Checkbox': {
      component = (
        <FormCheckbox onBlur={onBlur} {...baseProps} color="secondary" checkedValue={heraldParameter.schema.enum![0]} />
      );
      // agree to also has additional text above the checkbox
      if (heraldParameter.input_type === 'agree_to') {
        component = (
          <Stack mb={1}>
            <Stack py={2} bgcolor="grey.50">
              <Stack px={2} maxHeight={200} sx={{ overflowY: 'scroll' }}>
                <Typography variant="body2">{heraldParameter.additionalContent}</Typography>
              </Stack>
            </Stack>

            {component}
          </Stack>
        );
        break;
      }
      break;
    }
    case 'Address':
      return (
        <DynamicObjectWrapper
          onBlur={onBlur}
          heraldParameter={heraldParameter}
          fieldNamePrefix={fieldBaseProps.name}
          hideBorder={isArrayFields}
          optional={isOptional}
          suggestion={resolvedSuggestion}
          FieldsComponent={Address}
        />
      );
    case 'ClaimEvent':
      return (
        <DynamicObjectWrapper
          hideBorder={isArrayFields}
          onBlur={onBlur}
          heraldParameter={heraldParameter}
          fieldNamePrefix={fieldBaseProps.name}
          optional={isOptional}
          FieldsComponent={HeraldClaimEvent}
        />
      );
    case 'AreaOfPractice':
      return (
        <DynamicObjectWrapper
          hideBorder={isArrayFields}
          onBlur={onBlur}
          heraldParameter={heraldParameter}
          fieldNamePrefix={fieldBaseProps.name}
          optional={isOptional}
          FieldsComponent={AreaOfPractice}
        />
      );
    default:
      component = <Typography>{heraldParameter.fieldLabel}</Typography>;
  }
  return component;
}
