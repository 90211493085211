import {
  AttachFile,
  Check,
  ContactSupport,
  DeleteOutline,
  EmailOutlined,
  MarkEmailReadOutlined,
  ThumbDownOffAlt,
} from '@icons';
import { ActivityType } from 'enums';
import { messages } from 'i18n';
import { ActivityLog } from 'types';
import { attachmentPointText } from 'utils';
import { ActivityTypeDisplayConfig } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/ActivityLog';
import { SubmissionMarketLevelActivities } from './types';

const addBasedOnPrimarySuffix = (displayText: string, activityLog: ActivityLog) =>
  displayText +
  (activityLog.quote?.primaryQuote
    ? `, ${messages.general.xsOfMarket(activityLog.quote.primaryQuote.marketName ?? '')} `
    : '');

export const submissionMarketActivityConfig: Record<SubmissionMarketLevelActivities, ActivityTypeDisplayConfig> = {
  [ActivityType.BinderAttached]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.BinderAttached],
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.BinderUpdated]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.BinderUpdated],
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.BinderRemoved]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.BinderRemoved],
        activityLog,
      ),
    icon: DeleteOutline,
  },
  [ActivityType.AddedManually]: {
    displayText: messages.submissionMarketActivityLog.activitiesTitles[ActivityType.AddedManually],
    icon: Check,
  },
  [ActivityType.AddedAsIncumbent]: {
    displayText: messages.submissionMarketActivityLog.activitiesTitles[ActivityType.AddedAsIncumbent],
    icon: Check,
  },
  [ActivityType.MarketDeclined]: {
    displayText: messages.submissionMarketActivityLog.activitiesTitles[ActivityType.MarketDeclined],
    icon: ThumbDownOffAlt,
  },
  [ActivityType.QuoteIndication]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.QuoteIndication],
    icon: EmailOutlined,
  },
  [ActivityType.InformationRequested]: {
    displayText: messages.submissionMarketActivityLog.activitiesTitles[ActivityType.InformationRequested],
    icon: ContactSupport,
  },
  [ActivityType.PolicyIssued]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.PolicyIssued],
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.PolicyRemoved]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.PolicyRemoved],
        activityLog,
      ),
    icon: DeleteOutline,
  },
  [ActivityType.PolicyUpdated]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.PolicyUpdated],
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.QuoteAttached]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.QuoteAttached],
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.QuoteNotAccepted]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.QuoteNotAccepted],
        activityLog,
      ),
    icon: ThumbDownOffAlt,
  },
  [ActivityType.QuoteRemoved]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.QuoteRemoved],
        activityLog,
      ),
    icon: DeleteOutline,
  },
  [ActivityType.QuoteUpdated]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionMarketActivityLog.activitiesTitles[ActivityType.QuoteUpdated],
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.RequestForQuote]: {
    displayText: (activityLog) =>
      `${messages.submissionMarketActivityLog.activitiesTitles[ActivityType.RequestForQuote]}${
        activityLog.layer ? `, ${attachmentPointText(activityLog.layer.attachmentPoint)}` : ''
      }`,
    icon: MarkEmailReadOutlined,
  },
  [ActivityType.MarketEmailSent]: {
    displayText: messages.submissionMarketActivityLog.activitiesTitles[ActivityType.MarketEmailSent],
    icon: MarkEmailReadOutlined,
  },
  [ActivityType.MarketEmailReceived]: {
    displayText: messages.submissionMarketActivityLog.activitiesTitles[ActivityType.MarketEmailReceived],
    icon: EmailOutlined,
  },
};
