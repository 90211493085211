import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { LoaderOverlay } from '@common-components';
import { HeraldApplication, HeraldFormType, HeraldIndexEntry, QuoteExits } from 'clients/types/herald-types';
import { CoverageLine } from 'enums';
import { useScrollSections } from 'hooks';
import { useHeraldIndustryClassificationApi, useRetrieveHeraldApplication } from 'hooks/api/herald-api';
import { PartialSubmission } from 'types';
import { MainAreaDimensionsState } from 'broker/components/common/VerticalFormStepper/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import DynamicForm from './DynamicForm';
import heraldApplicationNormalizer from './DynamicForm/mappers/herald-application-normalizer';
import {
  DynamicFormImperativeHandle,
  FormStateBaseProp,
  HeraldApplicationNormalized,
  heraldIndustryQuestionParameterId,
} from './DynamicForm/types';
import { OnHeraldApplicationInternalUpdateParams, OnHeraldApplicationUpdateParams } from './types';
import { useGetExtractedData } from './useGetExtractedData';

interface HeraldFormProps {
  heraldApplicationId: string;
  formType: HeraldFormType;
  setIsDirty?: (isDirty: boolean) => void;
  onUpdate: (OnHeraldApplicationUpdateParams: OnHeraldApplicationUpdateParams) => Promise<void>;
  onClose: () => void;
  firstVisit: boolean;
  mainAreaDimensionsState?: MainAreaDimensionsState;
  isAcknowledgmentFlow: boolean;
  forceShowQuestions?: string[];
  setNextStep: (props?: SetNextStepsProps) => void;
  coverageLines?: CoverageLine[];
  submission: PartialSubmission;
}

export const HeraldForm = forwardRef<DynamicFormImperativeHandle, HeraldFormProps>(
  (
    {
      heraldApplicationId,
      formType,
      setIsDirty,
      onUpdate,
      onClose,
      mainAreaDimensionsState,
      isAcknowledgmentFlow,
      forceShowQuestions,
      firstVisit,
      coverageLines,
      setNextStep,
      submission,
    },
    ref,
  ) => {
    // state that increments on every herald api update in order to rebuild the DynamicForm component and populate the form with updated default values returned from herald
    const [key, setKey] = useState(0);
    const [applicationIndustryDetails, setApplicationIndustryDetails] = useState<undefined | HeraldIndexEntry | null>(
      undefined,
    );
    // impacted by submitting a conditional question that triggers the dynamicForm to mount again, it will scroll to the last position of the form so that there will be no scroll and the user will stay at the same place
    const [scrollPositionOnMount, setScrollPositionOnMount] = useState<number | undefined>();
    // impacted by changing section it changes the scroll position to the top of the section and then goes back to be undefined
    const [scrollPosition, setScrollPosition] = useState<number | undefined>();

    const [heraldApplication, setHeraldApplication] = useState<HeraldApplication | undefined>();
    const [quoteExits, setQuoteExists] = useState<QuoteExits[] | undefined>();
    const sendPromptReport = useSendPromptReport();

    const scrollableDivRef = useRef<HTMLDivElement | null>(null);

    const { refetch: fetchHeraldApplication, isLoading: isLoadingHeraldApplication } = useRetrieveHeraldApplication({
      id: heraldApplicationId,
      enabled: false,
    });
    const { retrieveIndustryClassificationByHeraldId } = useHeraldIndustryClassificationApi();
    const [activeSection, setActiveSection] = useState<string | undefined>(undefined);

    // Ref to the scrollable container of the form
    const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});

    useScrollSections({
      scrollableDivRef,
      sectionRefs,
      activeSection,
      setActiveSection,
    });

    // Use onSetActiveSection for sidebar stepper click
    const onSetActiveSection = (section?: string) => {
      setActiveSection(section);
      if (section && sectionRefs.current[section]) {
        sectionRefs.current[section]?.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const isLoading = isLoadingHeraldApplication;

    const heraldApplicationNormalized: HeraldApplicationNormalized | undefined = useMemo(
      () =>
        heraldApplication
          ? heraldApplicationNormalizer(
              heraldApplication,
              formType,
              applicationIndustryDetails || undefined,
              forceShowQuestions,
            )
          : undefined,
      [applicationIndustryDetails, formType, heraldApplication, forceShowQuestions],
    );

    const heraldNormalizedParameters = useMemo(
      () => [
        ...(heraldApplicationNormalized?.risk_values || []),
        ...(heraldApplicationNormalized?.coverage_values || []),
      ],
      [heraldApplicationNormalized?.coverage_values, heraldApplicationNormalized?.risk_values],
    );

    const submissionExtractedData = useGetExtractedData(heraldNormalizedParameters, submission);

    const updateHeraldApplicationStates = async (
      heraldApplicationItem: HeraldApplication,
      industryIndexEntry: HeraldIndexEntry | null,
      heraldQuoteExits?: QuoteExits[],
    ) => {
      setApplicationIndustryDetails(industryIndexEntry);
      setQuoteExists(heraldQuoteExits);
      setHeraldApplication(heraldApplicationItem);
      setKey((prev) => prev + 1);
      return {
        heraldApplication: heraldApplicationItem,
        industryIndexEntry,
      };
    };

    const getIndustryIndexEntry = async (heraldApplicationItem: HeraldApplication) => {
      let industryIndexEntry: HeraldIndexEntry | null = null;
      const industry = heraldApplicationItem.risk_values.find(
        (riskValue) => riskValue.risk_parameter_id === heraldIndustryQuestionParameterId,
      );
      if (industry?.value) {
        industryIndexEntry = await retrieveIndustryClassificationByHeraldId(industry.value);
      }

      return industryIndexEntry;
    };

    useEffect(() => {
      fetchHeraldApplication().then(async (response) => {
        if (response.data) {
          const industryIndexEntry = await getIndustryIndexEntry(response.data.application);
          updateHeraldApplicationStates(response.data.application, industryIndexEntry, response.data.quote_exits);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heraldApplicationId, fetchHeraldApplication]);

    const onInternalUpdate = async (
      onHeraldApplicationInternalUpdateParams: OnHeraldApplicationInternalUpdateParams,
    ) => {
      const industryIndexEntry = await getIndustryIndexEntry(onHeraldApplicationInternalUpdateParams.app);
      await onUpdate({ ...onHeraldApplicationInternalUpdateParams, industry: industryIndexEntry });
      if (submissionExtractedData) {
        // no need to await for this as it should not block the submission update
        sendPromptReport({
          formState: onHeraldApplicationInternalUpdateParams.formState,
          suggestedValueProps: submissionExtractedData,
          entityId: submission.id,
          submissionId: submission.id,
          getValue: (formValue) => {
            if (Array.isArray(formValue)) {
              return formValue.map((value) => value[FormStateBaseProp.Main]);
            }
            return formValue?.main;
          },
        });
      }
      await updateHeraldApplicationStates(
        onHeraldApplicationInternalUpdateParams.app,
        industryIndexEntry,
        onHeraldApplicationInternalUpdateParams.quoteExits,
      );
    };

    if (isLoading) {
      return <LoaderOverlay hideOverlay />;
    }

    return heraldApplication && applicationIndustryDetails !== undefined && heraldApplicationNormalized ? (
      <DynamicForm
        setNextStep={setNextStep}
        coverageLines={coverageLines}
        submissionExtractedData={submissionExtractedData}
        isAcknowledgmentFlow={isAcknowledgmentFlow}
        formType={formType}
        key={key}
        heraldApplication={heraldApplication}
        heraldApplicationNormalized={heraldApplicationNormalized}
        onUpdate={onInternalUpdate}
        setIsDirty={setIsDirty}
        sectionRefs={sectionRefs} // Pass refs to DynamicForm
        activeSection={activeSection}
        setActiveSection={onSetActiveSection} // Use onSetActiveSection for stepper clicks
        onClose={onClose}
        mainAreaDimensionsState={mainAreaDimensionsState}
        scrollPositionOnMount={scrollPositionOnMount}
        setScrollPositionOnMount={setScrollPositionOnMount}
        scrollPosition={scrollPosition}
        setScrollPosition={setScrollPosition}
        firstVisit={firstVisit}
        applySuggestionsOnEmptyFields={key === 1 && firstVisit}
        ref={ref}
        scrollableDivRef={scrollableDivRef}
        quoteExits={quoteExits}
        submission={submission}
      />
    ) : null;
  },
);

export default HeraldForm;
