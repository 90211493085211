import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import EmailEditorInnerPageContentWrapper from './EmailEditorInnerPageContentWrapper';

export default function EmailEditorInnerPage() {
  const { partialSubmission } = useSubmissionsWorkspace();

  return (
    <WithRouteLocationState routeKey={RouteKey.EmailEditor} isStateRequired>
      {(locationState) => (
        <EmailEditorInnerPageContentWrapper
          locationState={locationState}
          partialSubmission={partialSubmission || undefined}
        />
      )}
    </WithRouteLocationState>
  );
}
