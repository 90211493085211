import { CoverageLine } from '../enums';

/**
 * This mapping correlates coverage lines to their respective lines of business (which are also coverage lines),
 * you can think of a line of business as a family of coverage lines :)
 *
 * lobCoverageLineMapping serves several different purposes:
 *
 * 1. When coverage lines are selected for a submission, we add the relevant Herald products to the Herald application;
 *    This mapping allows adding Herald products based on the selected coverage lines and their "family" members.
 *
 * 2. When selecting products for marketing (AddProducts.tsx, SelectProducts.tsx) as well as when generating product recommendations
 *   we filter relevant products based on the selected coverage lines of the submission,
 *   with some custom logic for certain lines of business members, based on this mapping.
 */
export const lobCoverageLineMapping: Partial<Record<CoverageLine, CoverageLine[]>> = {
    [CoverageLine.ProfessionalLiability]: [
        CoverageLine.AccountantsPl,
        CoverageLine.EmploymentOrganizationsPl,
        CoverageLine.InsuranceAgentsBrokersEnO,
        CoverageLine.ManufacturersEnO,
        CoverageLine.LawyersProfessionalLiability,
        CoverageLine.FinancialAdvisorsInvestmentAdvisorsPl,
        CoverageLine.MediaMultimedia,
        CoverageLine.FranchisorsEnO,
        CoverageLine.Rnd,
        CoverageLine.SpecifiedProfessionalLiability,
        CoverageLine.MiscellaneousProfessionalLiability,
        CoverageLine.ArchitectEngineersPl,
        CoverageLine.ContractorsPollutionsLiability,
        CoverageLine.AlliedHealthcareProfessionalLiability,
    ],
    [CoverageLine.ManagementLiability]: [
        CoverageLine.PrivateDnO,
        CoverageLine.PrivateEmploymentPracticesLiability,
        CoverageLine.PrivateCrime,
        CoverageLine.PrivateFiduciary,
        CoverageLine.PublicDnO,
        CoverageLine.PublicEmploymentPracticesLiability,
        CoverageLine.PublicCrime,
        CoverageLine.PublicFiduciary,
        CoverageLine.NonProfitEmploymentPracticesLiability,
        CoverageLine.NonProfitCrime,
        CoverageLine.NonProfitFiduciary,
        CoverageLine.ExcessManagementLiability,
        CoverageLine.DirectorsAndOfficersLiability,
        CoverageLine.Fiduciary,
        CoverageLine.EPL,
        CoverageLine.Crime,
    ],
    [CoverageLine.Cyber]: [CoverageLine.Cyber, CoverageLine.ExcessCyber],
    [CoverageLine.EnvironmentalLiability]: [
        CoverageLine.EnvironmentalProfessionalsPackage,
        CoverageLine.ContractorsPollutionsLiability,
        CoverageLine.StorageTankPollutionLiability,
        CoverageLine.SitePollutionLiability,
    ],
    [CoverageLine.UniqueOfferings]: [
        CoverageLine.IntellectualProperty,
        CoverageLine.SexualAbuseMolestation,
        CoverageLine.KidnapRansomExecutiveProtection,
        CoverageLine.TerrorismActiveAssailantShooter,
        CoverageLine.ExcessLiability,
    ],
};
