import { BoxItem } from 'box-ui-elements/es';
import { Dialog, DialogContent } from '@common-components';
import { BoxTemplateLabels } from 'enums';
import { ModalBaseProps, useBoolean, useConditionallyRenderedModal, useToast } from 'hooks';
import { messages } from 'i18n';
import { extractErrorMessage } from 'utils';
import { fileExtensionToIcon } from 'utils/files-utils';
import { ConfirmationDialog } from 'components/Dialog';
import ContentPreview from 'broker/components/box/ContentPreview';
import { ExtendedBoxItem, FilesExplorerProps, GetFileSecondaryActions } from 'broker/components/FilesExplorer/types';
import ContainedFileViewerModalHeader from './ContainedFileViewerModalHeader';
import ScanningAnimation from './ScanningOverlay';

interface ContainedFileViewerModalProps
  extends Pick<
    FilesExplorerProps,
    'selectionMode' | 'onFileSelected' | 'selectedFiles' | 'labelsMetadata' | 'viewOnlyMode'
  > {
  containerId: string;
  readonly?: boolean;
  onDeleteClicked?: (item: BoxItem) => Promise<void>;
  onDownloadClicked: (item: BoxItem) => void;
  onLabelUpdate?: (
    extendedBoxItem: ExtendedBoxItem,
    labelFieldName: BoxTemplateLabels,
    selectedLabel?: string,
  ) => Promise<void>;
}

export interface OpenContainedFileViewerModalProps {
  file: BoxItem;
  getFileSecondaryActions?: GetFileSecondaryActions;
  isScanning?: boolean;
  selectedFilePage?: number;
}

export default function ContainedFileViewerModal({
  containerId,
  onDeleteClicked,
  onDownloadClicked,
  file,
  isOpened,
  onClose,
  readonly = false,
  getFileSecondaryActions,
  isScanning,
  selectionMode,
  onFileSelected,
  selectedFiles,
  labelsMetadata,
  onLabelUpdate,
  viewOnlyMode,
  selectedFilePage,
}: ContainedFileViewerModalProps &
  OpenContainedFileViewerModalProps &
  ModalBaseProps & {
    onLabelUpdate?: (
      extendedBoxItem: ExtendedBoxItem,
      labelFieldName: BoxTemplateLabels,
      selectedLabel?: string,
    ) => Promise<void>;
  }) {
  const containerElement = document.getElementById(containerId);
  const [isSubmittingDelete, { on: showSubmittingDeleteLoader, off: hideSubmittingDeleteLoader }] = useBoolean(false);
  const { showToast } = useToast();

  const fileName = file.name;
  const fileExtension = fileName.split('.').slice(-1)[0].toLowerCase();

  const {
    openModal: openDeleteConfirmation,
    closeModal: closeDeleteConfirmation,
    modalState: { isOpen: isDeleteConfirmationOpen, shouldRender: shouldRenderDeleteConfirmation },
  } = useConditionallyRenderedModal({});

  const onDeleteConfirmationSubmit = async () => {
    showSubmittingDeleteLoader();
    try {
      await onDeleteClicked?.(file);
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    } finally {
      hideSubmittingDeleteLoader();
      closeDeleteConfirmation();
    }
  };

  const onDeleteConfirmationClose = () => {
    closeDeleteConfirmation();
  };

  return (
    <Dialog
      className="cap-contained-file-viewer-modal"
      container={containerElement}
      open={isOpened}
      onClose={onClose}
      fullScreen
      sx={{ position: 'absolute', mt: '0 !important', zIndex: 1 }}
      BackdropProps={{ sx: { position: 'absolute' } }}
      disableEnforceFocus
    >
      <ContainedFileViewerModalHeader
        title={fileName}
        icon={fileExtensionToIcon(fileExtension)}
        onClose={onClose}
        getFileSecondaryActions={getFileSecondaryActions}
        file={file}
        onDeleteClicked={openDeleteConfirmation}
        onDownloadClicked={onDownloadClicked}
        readonly={readonly || isScanning}
        hideHeaderMenuActions={isScanning}
        selectionMode={selectionMode}
        onFileSelected={onFileSelected}
        selectedFiles={selectedFiles}
        labelsMetadata={labelsMetadata}
        onLabelUpdate={onLabelUpdate}
        viewOnlyMode={viewOnlyMode}
      />
      <DialogContent sx={{ padding: 0 }}>
        {isScanning && <ScanningAnimation />}
        <ContentPreview selectedFilePage={selectedFilePage} fileId={file.id} hasHeader={false} sx={{ height: 1 }} />
      </DialogContent>

      {shouldRenderDeleteConfirmation && (
        <ConfirmationDialog
          message={messages.deleteFileConfirmation.description(file.name)}
          title={messages.deleteFileConfirmation.title}
          isOpen={isDeleteConfirmationOpen}
          onSubmit={onDeleteConfirmationSubmit}
          onCancel={onDeleteConfirmationClose}
          isSubmitting={isSubmittingDelete}
        />
      )}
    </Dialog>
  );
}
