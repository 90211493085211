import Markdown from 'markdown-to-jsx';
import { ReactElement } from 'react';
import { Stack, Tooltip, Typography } from '@common-components';
import { markdownStyles } from 'utils/markdown-styles';

interface CitationTooltipProps {
  children: ReactElement<any, any>;
  title: string;
  content?: string;
}

export default function CitationTooltip({ children, title, content }: CitationTooltipProps) {
  return (
    <Tooltip
      tooltipContent={
        <Stack textAlign="left">
          <Typography mb={1}>{title}</Typography>
          <Markdown
            options={{
              overrides: {
                ...markdownStyles,
              },
            }}
          >
            {content || ''}
          </Markdown>
        </Stack>
      }
      placement="bottom-start"
    >
      {children}
    </Tooltip>
  );
}
