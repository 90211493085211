import { Transition } from 'history';
import { ReactElement, useEffect } from 'react';
import { useBlockNavigation, useRefState } from 'hooks';
import DirtyPageDialog from 'broker/dialogs/DirtyPageDialog';
import { NavigationBlock, useDirtyContentGuard } from './useDirtyContentGuard';

type DirtyRouteGuardProps = {
  children: ReactElement;
  beforeNavigation?: (transition: Transition) => Promise<boolean>;
  onDiscardCallback?: () => void;
  isDirty: boolean;
};

// A Guard for components with dirty state that are not rendered in a dialog, but have a dedicated route.
// Upon navigating away from the route, if the content is dirty, we trigger the beforeNavigation callback (usually a save action)
// or if not provided, display the dirty prompt dialog
export function DirtyRouteGuard({ children, beforeNavigation, isDirty, onDiscardCallback }: DirtyRouteGuardProps) {
  const { isDirtyDialogOpen, closeDirtyDialog, closeWithoutPrompt, closeAttempt, setIsContentDirty, setOnClose } =
    useDirtyContentGuard({ navigationBlock: NavigationBlock.None });

  const [isRunningBeforeNavigation, setRunningBeforeNavigation] = useRefState(false);

  // Sync the content guard flag with the externally managed dirty state
  useEffect(() => {
    setIsContentDirty(isDirty);
  }, [isDirty, setIsContentDirty]);

  useBlockNavigation({
    shouldBlock: isDirty,
    onBlock: async (transition, unblockNavigation) => {
      // Prevent running the beforeNavigation callback multiple times
      if (isRunningBeforeNavigation()) {
        return;
      }

      // Attempting auto navigation using the beforeNavigation callback
      setRunningBeforeNavigation(true);
      const success = await beforeNavigation?.(transition);
      setRunningBeforeNavigation(false);

      const navigate = () => {
        unblockNavigation();
        transition.retry();
      };

      // If the callback was successful, allow the navigation
      // If the callback doesn't exist or fails, display the dirty prompt
      if (success) {
        navigate();
      } else {
        // Set the onClose callback to retry the transition if the user chooses to discard
        setOnClose(navigate);
        closeAttempt();
      }
    },
  });

  const onDiscard = () => {
    onDiscardCallback?.();
    closeWithoutPrompt();
  };

  return (
    <>
      {children}
      <DirtyPageDialog onContinueEditing={closeDirtyDialog} onDiscard={onDiscard} open={isDirtyDialogOpen} />
    </>
  );
}
