import { BoxItem } from 'box-ui-elements/es';
import { CitationType } from 'enums';
import { useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { getFileLabel } from 'utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

const { citationTypes } = messages.submissionWorkspace.aiGeneratedContentPopup;

const CitationTypeConfig: Record<CitationType, { label: string }> = {
  [CitationType.DeclinationEmail]: { label: citationTypes.declinationEmail },
  [CitationType.QuoteEmail]: { label: citationTypes.quoteEmail },
  [CitationType.PolicySpecimen]: { label: citationTypes.policySpecimen },
  [CitationType.RiskAppetiteBrochure]: { label: citationTypes.brochure },
  [CitationType.InternalBoxFile]: { label: citationTypes.file },
  [CitationType.InboundEmail]: { label: citationTypes.inboundEmail },
};

function getInternalBoxFileType(sourceFile?: BoxItem) {
  if (!sourceFile) {
    return undefined;
  }

  return getFileLabel(sourceFile);
}

export default function useCitationLabel(sourceType: CitationType, sourceId: string) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { items: files } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: partialSubmission!.boxFolderId },
    enabled: !!partialSubmission && sourceType === CitationType.InternalBoxFile,
  });

  if (sourceType !== CitationType.InternalBoxFile) {
    return CitationTypeConfig[sourceType].label;
  }

  const sourceFile = files.find((file) => file.id === sourceId);

  return getInternalBoxFileType(sourceFile) ?? CitationTypeConfig[sourceType].label;
}
