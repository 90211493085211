import { Address, ExtractedAttribute, ExtractedData, ExtractedDataAttributesKeys } from '@common/types';
import { isEmpty, isEqual, isNil, isPlainObject, isUndefined, omitBy } from 'lodash';
import { useMemo } from 'react';
import { LegalEntity, PromptResultFeature, PromptResultFeatureType } from 'enums';
import { ExtractedDataFields, SuggestedValueProps } from 'hooks';
import { PartialSubmission, Suggestion } from 'types';
import { isValidDomain, logger, parseTimezoneLessDate } from 'utils';
import { convertIndustryObjectToOption } from 'broker/components/common/IndustryAutoComplete/utils';
import {
  FormStateBaseProp,
  FormStateBaseProps,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { FlowStaticQuestionId } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/flow-static-questions/flow-static-question-id';
import {
  getValidAddressSuggestion,
  getValidDateSuggestion,
  getValidNumberSuggestion,
  getValidNumericStringSuggestion,
  getValidOptionSuggestion,
} from 'broker/utils';
import { flowStaticQuestionsConfig } from './DynamicForm/utils/flow-static-questions/flow-static-questions-config';

const areExistingObjectPropsEqual = (suggestion: Suggestion, formValue: FormStateBaseProps): boolean =>
  // if an object verify that comparing only properties that are participated in the form (not nil)
  isPlainObject(formValue.main) &&
  isPlainObject(suggestion.value) &&
  Object.keys(formValue.main).every(
    (key) =>
      isNil(formValue.main[key]) ||
      (isEmpty(formValue.main[key]) && isEmpty(suggestion.value[key])) ||
      isEqual(formValue.main[key], suggestion.value[key]),
  );

function buildSuggestedValueProps(
  extractedAttribute: ExtractedAttribute<any>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps {
  function setFormFieldSuggestedValue(value: any, oldValue?: FormStateBaseProps): FormStateBaseProps {
    if (oldValue) {
      return {
        ...oldValue,
        [FormStateBaseProp.Main]: value,
      };
    }
    return {
      [FormStateBaseProp.Main]: value,
    };
  }

  function isCurrentFormFieldEmpty(value?: FormStateBaseProps): any {
    return isPlainObject(value?.[FormStateBaseProp.Main])
      ? // check if all properties in object are empty
        Object.values(value?.[FormStateBaseProp.Main]).every((val) => isEmpty(val))
      : // check if value is not a number and is empty (number returns true for isEmpty)
        typeof value?.[FormStateBaseProp.Main] !== 'number' && isEmpty(value?.[FormStateBaseProp.Main]);
  }

  function isEqualToFormValue(suggestion: Suggestion, formValue?: FormStateBaseProps): boolean {
    return !!formValue && isPlainObject(formValue.main)
      ? areExistingObjectPropsEqual(suggestion, formValue)
      : isEqual(suggestion.value, formValue?.main);
  }

  return {
    suggestion: extractedAttribute,
    setSuggestedValue: setFormFieldSuggestedValue,
    isFormFieldEmpty: isCurrentFormFieldEmpty,
    isEqualToFormValue,
    promptResultFeature,
  };
}

function buildSuggestedValueForArrayProps(
  extractedAttribute: ExtractedAttribute<any>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps {
  function setFormArrayFieldSuggestedValue(value: any, oldValue?: FormStateBaseProps[]): FormStateBaseProps[] {
    const valueElement = {
      [FormStateBaseProp.Main]: value,
    };
    return oldValue ? [valueElement, ...oldValue] : [valueElement];
  }

  function isFormArrayFieldInArray(suggestion: Suggestion, formValues?: FormStateBaseProps[]): boolean {
    return (
      Array.isArray(formValues) &&
      formValues.some((formValue) =>
        isPlainObject(formValue.main)
          ? areExistingObjectPropsEqual(suggestion, formValue)
          : isEqual(suggestion.value, formValue.main),
      )
    );
  }

  function isCurrentFormArrayFieldEmpty(value?: FormStateBaseProps[]): any {
    return !value || value.length === 0;
  }

  return {
    suggestion: extractedAttribute,
    setSuggestedValue: setFormArrayFieldSuggestedValue,
    isFormFieldEmpty: isCurrentFormArrayFieldEmpty,
    isEqualToFormValue: isFormArrayFieldInArray,
    promptResultFeature,
  };
}

function extractValidNumberSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
  // check schema type of question id in herald appendix, only if it is number it allows decimal https://www.heraldapi.com/docs/input-types#number
  integerSchemaType = true,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidNumberSuggestion(extractedAttribute, integerSchemaType);
  if (validSuggestion) {
    return buildSuggestedValueProps(validSuggestion, promptResultFeature);
  }
  return undefined;
}

function extractValidNumericStringSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidNumericStringSuggestion(extractedAttribute);
  if (validSuggestion) {
    return buildSuggestedValueProps(validSuggestion, promptResultFeature);
  }

  return undefined;
}

const FORM_FIELD_DATE_FORMAT = 'YYYY-MM-DD';
function extractValidDateSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidDateSuggestion(extractedAttribute);
  if (validSuggestion) {
    const suggestedValueProps = buildSuggestedValueProps(validSuggestion, promptResultFeature);
    return {
      ...suggestedValueProps,
      promptResultFeature,
      isEqualToFormValue: (suggestion: Suggestion, formValue?: FormStateBaseProps): boolean =>
        // Compare dates without timezones
        isEqual(suggestion.value, parseTimezoneLessDate(formValue?.main, FORM_FIELD_DATE_FORMAT)?.toISOString()),
    };
  }

  return undefined;
}

function extractValidOptionSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  options: string[],
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidOptionSuggestion(extractedAttribute, options);
  if (validSuggestion) {
    return buildSuggestedValueProps(validSuggestion, promptResultFeature);
  }

  return undefined;
}

function extractValidAddressSuggestion(
  extractedAttribute: ExtractedAttribute<Address>,
  isArray: boolean,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidAddressSuggestion(extractedAttribute);
  if (validSuggestion) {
    return isArray
      ? buildSuggestedValueForArrayProps(validSuggestion, promptResultFeature)
      : buildSuggestedValueProps(validSuggestion, promptResultFeature);
  }

  return undefined;
}

// the extracted data can come from two sources (document extraction, web extraction)
// document extraction takes precedence over web extraction. If no document extraction is available, the web extraction is used.
// Note that currently, the precedence logic is shared among all attributes
function normalizeExtractedData(extractedData: ExtractedData | undefined): ExtractedData | undefined {
  if (!extractedData) {
    return undefined;
  }

  return Object.entries(extractedData).reduce((acc, [key, extractedAttribute]) => {
    if (extractedAttribute?.value) {
      acc[key as ExtractedDataAttributesKeys] = extractedAttribute as ExtractedAttribute<any>;
    } else if (!isEmpty(extractedAttribute?.additionalResults)) {
      acc[key as ExtractedDataAttributesKeys] = extractedAttribute?.additionalResults?.[0] as ExtractedAttribute<any>;
    }
    return acc;
  }, {} as ExtractedData);
}

export function useGetExtractedData(
  normalizedParameters: HeraldNormalizedParameter[],
  submission: PartialSubmission,
): ExtractedDataFields | undefined {
  return useMemo(() => {
    const normalizedExtractedData = normalizeExtractedData(submission.submissionExtractedData?.extractedData);
    // top level or additional values
    if (normalizedExtractedData) {
      try {
        const mailingAddressSuggestion = normalizedExtractedData.address?.value?.mailing
          ? extractValidAddressSuggestion(
              {
                value: normalizedExtractedData.address.value.mailing,
                reason: normalizedExtractedData.address.reason,
                citations: normalizedExtractedData.address.citations,
              },
              false,
              PromptResultFeature.SubmissionApplicantMailingAddress,
            )
          : undefined;
        const getPrimaryAddressSuggestion = (promptResultFeature: PromptResultFeatureType, isArrayQuestion: boolean) =>
          normalizedExtractedData.address?.value?.primary
            ? extractValidAddressSuggestion(
                {
                  value: normalizedExtractedData.address.value.primary,
                  reason: normalizedExtractedData.address.reason,
                  citations: normalizedExtractedData.address.citations,
                },
                isArrayQuestion,
                promptResultFeature,
              )
            : undefined;

        const isFlowQuestionHidden = (flowStaticQuestionId: FlowStaticQuestionId) =>
          flowStaticQuestionsConfig
            .find((flowStaticQuestionConfig) => flowStaticQuestionConfig.parameter_id === flowStaticQuestionId)
            ?.isHidden?.(normalizedParameters, submission);

        const suggestedValueProps: Record<string, SuggestedValueProps | undefined> = {
          rsk_jb26_cyb_has_claims_history: normalizedExtractedData.lossRunIndicatorCyber
            ? extractValidOptionSuggestion(
                normalizedExtractedData.lossRunIndicatorCyber,
                ['yes', 'no'],
                PromptResultFeature.SubmissionCyberLossRunIndicator,
              )
            : undefined,
          rsk_2aep_pl_has_claim_history: normalizedExtractedData.lossRunIndicatorPL
            ? extractValidOptionSuggestion(
                normalizedExtractedData.lossRunIndicatorPL,
                ['yes', 'no'],
                PromptResultFeature.SubmissionPLLossRunIndicator,
              )
            : undefined,
          rsk_m4p9_insured_name: normalizedExtractedData.insuredName
            ? buildSuggestedValueProps(normalizedExtractedData.insuredName, PromptResultFeature.SubmissionInsuredName)
            : undefined,
          rsk_16rg_number_of_pte: normalizedExtractedData.partTimeEmployees
            ? extractValidNumberSuggestion(
                normalizedExtractedData.partTimeEmployees,
                PromptResultFeature.SubmissionPartTimeEmployeesNumber,
              )
            : undefined,
          rsk_0ie7_number_of_fte: normalizedExtractedData.fullTimeEmployees
            ? extractValidNumberSuggestion(
                normalizedExtractedData.fullTimeEmployees,
                PromptResultFeature.SubmissionFullTimeEmployeesNumber,
              )
            : undefined,
          rsk_k39d_number_of_employees: normalizedExtractedData.employeesNumber
            ? extractValidNumberSuggestion(
                normalizedExtractedData.employeesNumber,
                PromptResultFeature.SubmissionEmployeesNumber,
              )
            : undefined,
          rsk_a7he_total_payroll: normalizedExtractedData.totalPayroll
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalPayroll,
                PromptResultFeature.SubmissionTotalPayroll,
              )
            : undefined,
          rsk_vrb1_total_annual_revenue: normalizedExtractedData.totalAnnualRevenue
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalAnnualRevenue,
                PromptResultFeature.SubmissionTotalAnnualRevenue,
              )
            : undefined,
          rsk_cog2_total_assets: normalizedExtractedData.totalAssets
            ? extractValidNumberSuggestion(
                normalizedExtractedData.totalAssets,
                PromptResultFeature.SubmissionTotalAssets,
              )
            : undefined,
          rsk_4b4x_years_of_operation: normalizedExtractedData.yearsOfOperation
            ? extractValidNumberSuggestion(
                normalizedExtractedData.yearsOfOperation,
                PromptResultFeature.SubmissionYearsOfOperation,
              )
            : undefined,
          rsk_7ahp_has_domain:
            normalizedExtractedData.applicationDomainName &&
            isValidDomain(normalizedExtractedData.applicationDomainName.value)
              ? buildSuggestedValueProps(
                  {
                    value: 'yes',
                    reason: normalizedExtractedData.applicationDomainName.reason,
                    citations: normalizedExtractedData.applicationDomainName.citations,
                  },
                  PromptResultFeature.SubmissionHasDomain,
                )
              : undefined,
          rsk_dy7r_domain_names:
            normalizedExtractedData.applicationDomainName &&
            isValidDomain(normalizedExtractedData.applicationDomainName.value)
              ? buildSuggestedValueForArrayProps(
                  normalizedExtractedData.applicationDomainName,
                  PromptResultFeature.SubmissionApplicationDomainName,
                )
              : undefined,
          rsk_b3jm_2017_naics_index: normalizedExtractedData.industry?.value?.mappedIndexEntry?.[0]?.heraldId
            ? buildSuggestedValueProps(
                {
                  value: convertIndustryObjectToOption({
                    heraldId: normalizedExtractedData.industry?.value.mappedIndexEntry?.[0].heraldId,
                    naicsCode: normalizedExtractedData.industry?.value.mappedIndexEntry?.[0].naicsCode,
                    description: normalizedExtractedData.industry?.value.mappedIndexEntry?.[0].indexEntryDescription,
                  }),
                  reason: normalizedExtractedData.industry.reason,
                  citations: normalizedExtractedData.industry.citations,
                },
                PromptResultFeature.SubmissionIndustryClassification,
              )
            : undefined,
          rsk_837r_legal_entity: normalizedExtractedData.legalEntity
            ? extractValidOptionSuggestion(
                normalizedExtractedData.legalEntity,
                Object.values(LegalEntity),
                PromptResultFeature.SubmissionLegalEntity,
              )
            : undefined,
          rsk_s7wq_corporate_structure: normalizedExtractedData.organizationsCorporateStructure
            ? extractValidOptionSuggestion(
                normalizedExtractedData.organizationsCorporateStructure,
                ['Private', 'Not for profit'],
                PromptResultFeature.SubmissionOrganizationsCorporateStructure,
              )
            : undefined,
          [isFlowQuestionHidden(FlowStaticQuestionId.PrimaryAddress)
            ? 'rsk_jsy2_primary_address'
            : FlowStaticQuestionId.PrimaryAddress]: getPrimaryAddressSuggestion(
            PromptResultFeature.SubmissionApplicantPrimaryAddress,
            false,
          ),
          rsk_yor8_location: getPrimaryAddressSuggestion(PromptResultFeature.SubmissionApplicantLocation, true),
          [isFlowQuestionHidden(FlowStaticQuestionId.MailingAddress)
            ? 'rsk_tvm3_mailing_address'
            : FlowStaticQuestionId.PrimaryAddress]: mailingAddressSuggestion,
          rsk_14kt_insured_contact_phone: normalizedExtractedData.phoneNumber
            ? extractValidNumericStringSuggestion(
                normalizedExtractedData.phoneNumber,
                PromptResultFeature.SubmissionPhoneNumber,
              )
            : undefined,
          cvg_m18u_pl_effective_date: normalizedExtractedData.effectiveDatePL
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDatePL,
                PromptResultFeature.SubmissionPlEffectiveDate,
              )
            : undefined,
          cvg_48oo_gl_effective_date: normalizedExtractedData.effectiveDateGL
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDateGL,
                PromptResultFeature.SubmissionGlEffectiveDate,
              )
            : undefined,
          cvg_o3mw_cyb_effective_date: normalizedExtractedData.effectiveDateCyber
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDateCyber,
                PromptResultFeature.SubmissionCyberEffectiveDate,
              )
            : undefined,
          cvg_0em0_ml_effective_date: normalizedExtractedData.effectiveDateML
            ? extractValidDateSuggestion(
                normalizedExtractedData.effectiveDateML,
                PromptResultFeature.SubmissionMlEffectiveDate,
              )
            : undefined,
          rsk_t79b_insured_contact_name: normalizedExtractedData.applicantContactName
            ? buildSuggestedValueProps(
                {
                  value: normalizedExtractedData.applicantContactName.value,
                  reason: normalizedExtractedData.applicantContactName.reason,
                  citations: normalizedExtractedData.applicantContactName.citations,
                },
                PromptResultFeature.SubmissionDataApplicantContactName,
              )
            : undefined,
          rsk_5p6w_insured_contact_email: normalizedExtractedData.applicantEmailAddress
            ? buildSuggestedValueProps(
                {
                  value: normalizedExtractedData.applicantEmailAddress.value,
                  reason: normalizedExtractedData.applicantEmailAddress.reason,
                  citations: normalizedExtractedData.applicantEmailAddress.citations,
                },
                PromptResultFeature.SubmissionDataApplicantEmailAddress,
              )
            : undefined,
          [FlowStaticQuestionId.NeedByDate]: normalizedExtractedData.needByDate
            ? extractValidDateSuggestion(normalizedExtractedData.needByDate, PromptResultFeature.SubmissionNeedByDate)
            : undefined,
          [FlowStaticQuestionId.AttorneyNumber]: normalizedExtractedData.attorneyNumber
            ? extractValidNumberSuggestion(
                normalizedExtractedData.attorneyNumber,
                PromptResultFeature.SubmissionAttorneyNumber,
              )
            : undefined,
        };
        return omitBy(suggestedValueProps, isUndefined) as ExtractedDataFields;
      } catch (error) {
        logger.log('error', {
          message: 'useGetExtractedData: unexpected error, extracted data will not be used',
          error,
          extractedData: normalizedExtractedData,
        });
        return undefined;
      }
    }
    return undefined;
  }, [normalizedParameters, submission]);
}
