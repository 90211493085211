import { Agency, OffsetLimitPagination } from 'types';
import { CapitolaClient } from './base';

export type AgencyQuery = OffsetLimitPagination;

export class AgencyClient extends CapitolaClient<Agency> {
  public get resourceName(): string {
    return 'agency';
  }
}
