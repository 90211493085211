import { AIClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import {
  CreateFollowupMessageInput,
  CreateFollowupMessageOutput,
  CreateThreadInput,
  CreateThreadOutput,
  ShareAIThreadInput,
  ShareAIThreadOutput,
} from 'types';
import { useImperativeQuery } from './query/useQuery';

export function useAIAPI() {
  const aiClient = useClient(AIClient);

  const createFollowupMessage = useImperativeQuery<CreateFollowupMessageOutput | null, CreateFollowupMessageInput>({
    queryFn: (input) => aiClient.createFollowupMessage(input),
    queryKey: [QueryKeys.AI, 'create-followup-message'],
  });

  const createThread = useImperativeQuery<CreateThreadOutput | null, CreateThreadInput>({
    queryFn: (input) => aiClient.createThread(input),
    queryKey: [QueryKeys.AI, 'create-thread'],
  });

  const shareAIThread = useImperativeQuery<ShareAIThreadOutput | null, ShareAIThreadInput>({
    queryFn: (input) => aiClient.shareAIThread(input),
    queryKey: [QueryKeys.AI, 'add-feedback'],
  });
  return { addMessageAndRun: createFollowupMessage, shareAIThread, createThread };
}
