import { Quote } from 'types';

const getQuoteFileIds = (quote: Quote) => {
  if (quote.policyFileIds.length > 0) {
    return quote.policyFileIds;
  }

  if (quote.binderFileIds.length > 0) {
    return quote.binderFileIds;
  }

  return quote.capitolaQuoteFileIds;
};

export const getQuotesFileIds = (quotes: Quote[]) => quotes.map(getQuoteFileIds).flat();

export const sendRfqAiContentDecorator = (generatedContent: string) =>
  `<span>Hey @UNDERWRITERS-FIRST-NAMES,</span><br><br><div>${generatedContent}</div><br><br><div>@SIGNATURE</div>`;
