import { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-internal-modules
import { useUnmount } from 'hooks/useUnmount';
import { AIMessage, ShareAIThreadInput } from 'types';

interface ShareThreadOnUnmountProps {
  messages?: AIMessage[];
  sendAIThread: (input: Pick<ShareAIThreadInput, 'feedback' | 'messages'>) => Promise<void>;
}

export default function useShareThreadOnUnmount({ messages, sendAIThread }: ShareThreadOnUnmountProps) {
  const isUnmounting = useRef(false);
  // only if the user started a conversation send Full Thread Feedback
  const didUserStartConversation = messages && messages.length > 2;
  // !!! there is a meaning for the order of the hooks, this hook should be called first, so it will run first and update the isUnmounting ref
  useUnmount(() => {
    isUnmounting.current = true;
  });

  useEffect(
    () => () => {
      if (messages && isUnmounting.current) {
        // only if the user started a conversation send Full Thread Feedback
        if (didUserStartConversation) {
          sendAIThread({ messages });
        }
      }
    },
    [didUserStartConversation, messages, sendAIThread],
  );

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (messages && didUserStartConversation) {
        sendAIThread({ messages });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [didUserStartConversation, messages, sendAIThread]);
}
