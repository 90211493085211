export enum DynamicInputType {
  SelectOne = 'select_one',
  SelectMany = 'select_many',
  AgreeTo = 'agree_to',
  ShortText = 'short_text',
  LongText = 'long_text',
  Number = 'number',
  Integer = 'integer',
  Currency = 'currency',
  Date = 'date',
  Address = 'address',
  ClaimEvent = 'claim_event',
  AreaOfPractice = 'area_of_practice',
  Phone = 'phone',
  Email = 'email',
  Domain = 'domain',
  File = 'file',
  Year = 'year',
}
