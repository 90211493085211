import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { getOneYearFromDate } from 'utils';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

export function useSetPolicyExpirationDefaultValue() {
  const { watch, setValue, trigger } = useFormContext();

  const policyEffectiveDate: Date | undefined = watch(
    detailsFormFieldsConfig[DetailsFieldsNames.PolicyEffectiveDate].id,
  );
  const policyExpirationDate: Date | undefined = watch(
    detailsFormFieldsConfig[DetailsFieldsNames.PolicyExpirationDate].id,
  );

  useEffect(
    () => {
      if (policyEffectiveDate && !policyExpirationDate) {
        const dateYearFromDate = getOneYearFromDate(policyEffectiveDate);
        setValue(detailsFormFieldsConfig[DetailsFieldsNames.PolicyExpirationDate].id, dateYearFromDate);

        // Make the "Required" validation error go away if it was displayed
        trigger();
      }
    },
    // Only when changing policyEffectiveDate we want this effect to run
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, policyEffectiveDate, trigger],
  );
}
