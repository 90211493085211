import { Link } from '@common-components';
import { CitationType, isEmailCitationType } from 'enums';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import useCitationLabel from 'broker/pages/SubmissionWorkspacePage/hooks/useCitationLabel';
import CitationTooltip from './CitationTooltip';

interface AICitationLinkProps {
  sourceId: string;
  sourceType: CitationType;
  title: string;
  quotedText?: string;
  quotedPage?: string;
}

export const AICitationLink = ({ sourceId, sourceType, title, quotedText, quotedPage }: AICitationLinkProps) => {
  const updateQueryParam = useUpdateQueryParam();
  const citationLabel = useCitationLabel(sourceType, sourceId);

  const activeTab = isEmailCitationType(sourceType) ? activeTabUrlParamValues.EMAIL : activeTabUrlParamValues.FILES;
  const paramName = isEmailCitationType(sourceType) ? BrokerUrlParams.SELECTED_EMAIL : BrokerUrlParams.SELECTED_FILE;

  return (
    <CitationTooltip title={title} content={quotedText}>
      <Link
        underline="hover"
        ml={0.5}
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          const addParams = [
            { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTab },
            { queryParam: paramName, value: sourceId },
          ];

          // Add the page parameter if sourceType is FILE and quotedText.page exists
          if (!isEmailCitationType(sourceType) && quotedPage) {
            addParams.push({ queryParam: BrokerUrlParams.PAGE_NUMBER, value: quotedPage });
          }

          updateQueryParam({
            removeParams: [BrokerUrlParams.SELECTED_EMAIL, BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.PAGE_NUMBER],
            addParams,
          });
        }}
      >
        ({citationLabel})
      </Link>
    </CitationTooltip>
  );
};
