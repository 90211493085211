import { createContext } from 'react';
import { LocalStorage, NotificationStatus } from 'enums';
import { MarketSortTypes } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';
import { UiStore, UiStoreActions } from './types';

const initSearchFilter = {
  isAllFilters: true,
  filters: [],
  search: {
    value: '',
    debouncedValue: '',
  },
  sortType: (localStorage.getItem(LocalStorage.MarketRequestSortType) as MarketSortTypes) || MarketSortTypes.Asc,
};

const initFilesExplorer = {
  preSelectedFileIds: [],
  selectedFiles: [],
  isSelectedFilesDirty: false,
  selectionMode: false,
  isPreviewMode: false,
  isScanning: false,
  isLoaded: false,
  viewOnlyMode: false,
  highlightFileTypes: [],
};

export const UiStoreInitState: UiStore = {
  marketingFilters: initSearchFilter,
  filesExplorer: initFilesExplorer,
  toolboxState: {
    isExpanded: false,
    emailMarketFilter: undefined,
    tasksActiveTab: NotificationStatus.New,
  },
  toolboxOverlay: {
    selectedEmailView: undefined,
  },
  startMarketingIndicator: {
    showStartMarketingIndicator: false,
  },
  pasteSmartObjectIndicator: false,
  suggestedSubmissionStatus: null,
  innerPageState: [],
  selectedProductsSubmitApi: [],
};

export const UiStoreStateContext = createContext<UiStore>(UiStoreInitState);
export const UiStoreActionsContext = createContext<UiStoreActions | undefined>(undefined);
