import { Typography } from '@common-components';
import { AIFeature } from 'enums';
import { messages } from 'i18n';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { BulletedContent } from './BulletedContent';
import { EmailsSection } from './EmailsSection';
import { FilesSection } from './FilesSection';
import { ProductsDataSection } from './ProductsDataSection';
import { SubmissionDataDetails } from './types';

/**
 * This config maps the submission data details to the AI features that uses them to enrich the prompt.
 * Files and emails are not included here as they are added dynamically based on the actual data that was uploaded to the thread.
 *
 * This config needs to be in sync with the `enricherByAiFeatureConfig` in the backend.
 */
const submissionDataPerFeatureConfig: Record<SubmissionDataDetails, AIFeature[]> = {
  [SubmissionDataDetails.ApplicationData]: [
    AIFeature.SubmissionAcknowledgment,
    AIFeature.ProductRecommendation,
    AIFeature.MarketingEmailGeneration,
  ],
  [SubmissionDataDetails.SelectedProducts]: [AIFeature.SubmissionAcknowledgment],
  [SubmissionDataDetails.ProductsData]: [AIFeature.ProductRecommendation],
};

export const AIThreadDataInput = ({ withFileLinks }: { withFileLinks: boolean }) => {
  const { feature } = useAIContext();
  return (
    <Typography variant="caption" whiteSpace="pre-line" lineHeight="2em" textAlign="left" display="block">
      {submissionDataPerFeatureConfig[SubmissionDataDetails.ApplicationData].includes(feature) && (
        <BulletedContent level={1} content={messages.submissionWorkspace.aiGeneratedContentPopup.applicationData}>
          <BulletedContent level={2} content={messages.submissionWorkspace.aiGeneratedContentPopup.insuredData} />
          <BulletedContent
            level={2}
            content={messages.submissionWorkspace.aiGeneratedContentPopup.submissionCoverageLines}
          />
        </BulletedContent>
      )}
      <FilesSection withFileLinks={withFileLinks} />
      <EmailsSection />
      {submissionDataPerFeatureConfig[SubmissionDataDetails.ProductsData].includes(feature) && <ProductsDataSection />}
      {submissionDataPerFeatureConfig[SubmissionDataDetails.SelectedProducts].includes(feature) && (
        <BulletedContent level={1} content={messages.submissionWorkspace.aiGeneratedContentPopup.selectedProducts} />
      )}
    </Typography>
  );
};
