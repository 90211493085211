import { isEmpty } from 'lodash';
import {
  backdropClasses,
  Chip,
  ClickAwayListener,
  IconButton,
  Link,
  Popover,
  Stack,
  Theme,
  Typography,
} from '@common-components';
import { Close, Link as LinkIcon } from '@icons';
import { UserRole } from 'enums';
import { OptionsMenuState, useCurrentUser, useEnvironment } from 'hooks';
import { messages } from 'i18n';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { AIGeneratedContent } from './AIGeneratedContent';
import { RegenerateButton } from './RegenerateButton';
import { AIThreadInputDataButton } from './thread-input-data/AIThreadInputDataButton';

const HeaderHeight = 36;
const openAIPlatformUrl = 'https://platform.openai.com/threads/';

interface AIPopupProps extends Omit<OptionsMenuState, 'setAnchorEl'> {
  popupTitle: string;
  withFileLinks?: boolean;
  isRichTextResponse?: boolean;
  popupWidth?: number;
  popupMinHeight?: number;
  popupMaxHeight?: string;
  hideRegenerateButton?: boolean;
  threadId?: string;
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}
export default function AIPopup({
  popupTitle,
  withFileLinks = true,
  isRichTextResponse = true,
  anchorEl,
  isMenuOpen,
  closeMenu,
  popupMaxHeight,
  popupMinHeight,
  popupWidth,
  threadId,
  hideRegenerateButton = false,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}: AIPopupProps) {
  const { me } = useCurrentUser();
  const { initialMessages } = useAIContext();
  const isInitThreadIdle = !isEmpty(initialMessages);
  const environment = useEnvironment();
  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Popover
        disableEnforceFocus
        disableScrollLock
        autoFocus={false}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{
          sx: {
            p: 1,
            width: popupWidth,
            minHeight: popupMinHeight,
            maxHeight: popupMaxHeight,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        sx={{
          zIndex: (theme: Theme) => theme.zIndex.tooltip,
          // 1. a hack to hide the backdrop and allow to scroll the content
          bottom: 'auto',
          // 2. a hack to hide the backdrop and allow to scroll the content
          [`& .${backdropClasses.root}`]: {
            display: 'none',
          },
        }}
      >
        <Stack
          direction="row"
          position="sticky"
          top={0}
          justifyContent="space-between"
          alignItems="center"
          height={HeaderHeight}
        >
          <Stack direction="row" gap={2}>
            <Typography ml={1} variant="h5">
              {popupTitle}
            </Typography>
            <AIThreadInputDataButton withFileLinks={withFileLinks} />
            {threadId && (environment !== 'prod' || me?.role === UserRole.BackOffice) && (
              <Chip
                variant="outlined"
                endIcon={LinkIcon}
                color="secondary"
                size="medium"
                tooltipContent={
                  <Link href={`${openAIPlatformUrl}${threadId}`} target="_blank" rel="noopener noreferrer">
                    {messages.submissionWorkspace.aiGeneratedContentPopup.openThreadInOpenAIPlatform}
                  </Link>
                }
                tooltipPlacement="bottom"
                disableFocusListener
              />
            )}
          </Stack>
          {!isInitThreadIdle && (
            <Stack direction="row" alignItems="center">
              {!hideRegenerateButton && <RegenerateButton />}
              <IconButton icon={Close} onClick={closeMenu} variant="text" color="secondary" />
            </Stack>
          )}
        </Stack>
        <Stack flex={1} overflow="auto" textAlign="left">
          <AIGeneratedContent
            maxWidth={popupWidth}
            withFileLinks={withFileLinks}
            isRichTextResponse={isRichTextResponse}
            initialMessages={initialMessages}
          />
        </Stack>
      </Popover>
    </ClickAwayListener>
  );
}
