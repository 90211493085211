import { PartialSubmission } from 'types';
import { ConditionalWrap } from 'utils';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import Content from './Content';
import { EmailEditorPageLocationState } from './types';
import useGetEmailEditorProps from './useGetEmailEditorProps';

interface EmailEditorInnerPageContentWrapperProps {
  locationState: EmailEditorPageLocationState;
  partialSubmission?: PartialSubmission;
}

export default function EmailEditorInnerPageContentWrapper({
  locationState,
  partialSubmission,
}: EmailEditorInnerPageContentWrapperProps) {
  const { aiFeatureProps } = useGetEmailEditorProps(locationState, partialSubmission);

  const aiFeature = aiFeatureProps?.feature;

  return (
    <ConditionalWrap
      condition={!!aiFeature}
      wrap={(wrappedChildren) => <AIContextProvider feature={aiFeature!}>{wrappedChildren}</AIContextProvider>}
    >
      <Content locationState={locationState} />
    </ConditionalWrap>
  );
}
