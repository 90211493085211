import { includes } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { InsuranceProduct } from 'types';
import { FilterType, UseProductFilterAttributes } from './types';
import { useCoverageLineFilter } from './useCoverageLineFilter';
import { useIsAdmittedFilter } from './useIsAdmittedFilter';
import { useIsPrimaryFilter } from './useIsPrimaryFilter';

export function useProductFilters(
  products: InsuranceProduct[],
  isLoading: boolean,
  paginationReset: () => void,
  includeExtraFilters: boolean,
): UseProductFilterAttributes {
  const { coverageLineFilter, preSelectedCoverageLines } = useCoverageLineFilter({
    paginationReset,
  });

  const isAdmittedFilter = useIsAdmittedFilter({
    products,
    productsLoaded: !isLoading,
    paginationReset,
  });

  const isPrimaryFilter = useIsPrimaryFilter({
    products,
    productsLoaded: !isLoading,
    paginationReset,
  });

  const [searchInput, setSearchInput] = useState<string>('');

  // Filter out chips that are not part of the pre-selected coverage lines
  const initialCoverageLineFilters = useMemo(
    () => coverageLineFilter.filters.filter((filter) => includes(preSelectedCoverageLines, filter.key)),
    [coverageLineFilter.filters, preSelectedCoverageLines],
  );

  // Function to toggle chip selection state (checked/unchecked)
  const handleVisibleCoverageChange = useCallback(
    (key: string) => {
      coverageLineFilter.setFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) =>
          filter.key === key ? { ...filter, checked: !filter.checked } : filter,
        );

        // Check if all chips are deselected
        const allDeselected = updatedFilters.every((filter) => !filter.checked);
        if (allDeselected) {
          // Set "all selected" if all chips are deselected
          coverageLineFilter.handleSelectAll();
        }

        return updatedFilters;
      });
    },
    [coverageLineFilter],
  );

  const isPrimaryProduct = (product: InsuranceProduct) =>
    product.coverageLayer?.isPrimary || !product.coverageLayer?.isExcess;

  const filteredProducts = useMemo(
    () =>
      [...products]
        .sort((a, b) => {
          if (a.marketName !== b.marketName) {
            return a.marketName.localeCompare(b.marketName);
          }
          if (isPrimaryProduct(a) !== isPrimaryProduct(b)) {
            return isPrimaryProduct(a) ? -1 : 1;
          }
          return a.isAdmitted ? -1 : 1;
        })
        .filter((product) => {
          if (coverageLineFilter.isAllSelected) {
            return true; // No filters applied
          }
          return coverageLineFilter.filters.some(
            (filter) => filter.checked && product.coverageLines.some((line) => line.coverageLine === filter.key),
          );
        })
        .filter((product) => {
          if (isAdmittedFilter.isAllSelected || !includeExtraFilters) {
            return true;
          }
          if (isAdmittedFilter.filters.every((filter) => filter.checked)) {
            return true;
          }
          if (isAdmittedFilter.filters[0].checked) {
            return product.isAdmitted;
          }
          if (isAdmittedFilter.filters[1].checked) {
            return !product.isAdmitted;
          }
          return true;
        })
        .filter((product) => {
          if (isPrimaryFilter.isAllSelected || !includeExtraFilters) {
            return true;
          }
          if (isPrimaryFilter.filters.every((filter) => filter.checked)) {
            return true;
          }
          if (isPrimaryFilter.filters[1].checked) {
            return product.coverageLayer?.isExcess;
          }
          if (isPrimaryFilter.filters[0].checked) {
            return isPrimaryProduct(product);
          }
          return true;
        })
        .filter((product) => {
          if (searchInput.length < 2) {
            return true;
          }
          const productTerms = [product.marketName].filter(Boolean);
          return productTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));
        }),
    [
      products,
      coverageLineFilter.isAllSelected,
      coverageLineFilter.filters,
      isAdmittedFilter.isAllSelected,
      isAdmittedFilter.filters,
      includeExtraFilters,
      isPrimaryFilter.isAllSelected,
      isPrimaryFilter.filters,
      searchInput,
    ],
  );

  const setSearch = useCallback(
    (value: string) => {
      paginationReset();
      setSearchInput(value);
    },
    [paginationReset],
  );

  const clearFilters = useCallback(() => {
    coverageLineFilter.handleSelectAll();
    isAdmittedFilter.handleSelectAll();
    isPrimaryFilter.handleSelectAll();
  }, [coverageLineFilter, isAdmittedFilter, isPrimaryFilter]);

  const isFilterApplied =
    !coverageLineFilter.isAllSelected || !isAdmittedFilter.isAllSelected || !isPrimaryFilter.isAllSelected;

  const filters = {
    [FilterType.CoverageLine]: coverageLineFilter,
    [FilterType.IsAdmitted]: isAdmittedFilter,
    [FilterType.IsPrimary]: isPrimaryFilter,
  };

  return {
    sortedAndFilteredItems: filteredProducts,
    filters,
    clearFilters,
    isFilterApplied,
    search: searchInput,
    setSearch,
    coverageLineFilters: initialCoverageLineFilters, // Chips filtered by pre-selected coverage lines
    handleVisibleCoverageChange, // Toggle chip selection
  };
}
