import { keyframes } from '@emotion/react';
import { Box } from './mui-index';

export function ProcessingAnimation() {
  const rotateAnimation = keyframes`
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
`;

  return (
    <>
      <Box
        sx={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 4,
          bgcolor: 'grey.200',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 4,
          bgcolor: 'green.300',
          zIndex: 2,
          animation: `${rotateAnimation} 2s linear infinite`,
        }}
      />
    </>
  );
}
