import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import { calculateRPM, formatBigNumber, formatPrice, formatPriceOrNA } from 'utils';

const getLimitComponent = (quote: Quote, layer: Layer) => {
  const limit = quote.limit ? `$${formatBigNumber(quote.limit)}` : '';

  if (!layer.attachmentPoint) {
    return limit;
  }

  return `${limit} ${messages.general.xs} $${formatBigNumber(layer.attachmentPoint)}`;
};

export function getQuoteMetaData(quote: Quote, layer: Layer) {
  const RPM = calculateRPM(quote.premium, quote.limit || 0);
  const premium = `${formatPriceOrNA(quote.premium)} (RPM $${formatPrice(RPM)})`;
  const limitComponent = getLimitComponent(quote, layer);

  return {
    limitComponent,
    premium,
  };
}
