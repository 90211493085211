import moment from 'moment';
import pluralize from 'pluralize';
import { ActivityType, ContactRole, EmailTemplateType, OrganizationType, SubmissionStatus } from 'enums';
import { ActivityLog, BulkCreateError, Organization } from 'types';
import { getDisplayDateWithHoursAmPm } from 'utils/date-utils';
import { en as boxEn } from './box-messages';
import coverageItems from './coverage-items';
import { en as submissionWorkspaceEn } from './pages/submissionWorkspace';

export const en = {
  buttons: {
    start: 'Start',
    createSubmission: 'CREATE A SUBMISSION',
    reports: 'Reports',
    dismiss: 'Dismiss',
    previewMode: 'Preview mode',
    clearFilters: 'Clear Filters',
    logOut: 'Logout',
    logIn: 'Login',
    moreInfo: 'More information',
    lessInfo: 'Less information',
    more: 'More',
    less: 'Less',
    add: 'Add',
    edit: 'Edit',
    view: 'View',
    details: 'Details',
    file: 'File',
    viewAndUpdate: 'View & Update',
    selectAll: 'Select all',
    clear: 'Clear',
    select: 'Select',
    deselect: 'Deselect',
    addSelectedMarkets: 'Add selected markets',
    addCategory: 'Add category',
    addCoverageLines: 'Add coverage lines',
    addProducts: 'Add products',
    addMarkets: (number?: number) => `Add Markets${number ? ` (${number})` : ''}`,
    addToWorkspace: 'Add to Workspace',
    submitMarkets: (number?: number) => `Compose Email to ${number ? ` (${number})` : ''} Markets`,
    updateMarkets: (number: number) => `Update Markets${number ? ` (${number})` : ''}`,
    send: 'Send',
    sent: 'Sent',
    save: 'Save',
    saveForLater: 'Save for Later',
    saveFlowBinder: 'Save Flow Binder',
    cancel: 'Cancel',
    submit: 'Submit',
    submitViaApi: 'Submit via API',
    back: 'Back',
    backToFiles: 'Back to Files',
    delete: 'Delete',
    disabled: 'Disabled',
    remove: 'Remove',
    rename: 'Rename',
    choose: 'Choose',
    addAnother: (title: string) => `Add another ${pluralize(title, 1)}`,
    addFirst: (title: string) => `Add ${pluralize(title, 1)}`,
    sort: 'Sort',
    update: 'Update',
    upload: 'Upload',
    dragAndDrop: 'Drag and drop',
    close: 'Close',
    continue: 'Continue',
    gotIt: 'Got it',
    done: 'Done',
    SubmitToMarkets: 'Submit to Markets',
    addMarketsManually: 'Add Markets Manually',
    saveSignature: 'Save Signature',
    addNewMarket: 'Add New Market',
    copy: 'Copy',
    yes: 'Yes',
    conceptualTower: 'Send Conceptual Tower',
    marketingReport: 'Send Marketing Report',
    outbox: 'Outbox',
    inbox: 'Inbox',
    create: 'Create',
    regenerate: 'Regenerate',
  },
  overlayChip: {
    default: 'Default',
    suggested: 'Extracted',
  },
  errors: {
    errorLoadingData: 'Error loading data',
    duplicateContact: 'A contact with the same email already exists',
    duplicateDomain: 'A domain with the same name already exists',
    checkInternetConnection: 'Please check your internet connection',
    youAreOffline: 'You are offline',

    minChars: (min: number) => `must be at least ${min} characters`,
    maxChars: (max: number) => `must be at most ${max} characters`,
    patternTestExactDigits: (digits: string) => `Should contain ${digits} digits`,
  },
  autonomousFlow: {
    success: (name: string) => `Co-Pilot activated for ${name} submission`,
    error: 'Co-pilot activation failed',
    createFlow: 'Start Capitola Co-Pilot',
    editFlow: 'View Co-Pilot Settings',
    flowDisabled: 'Available only for renewal submissions in Pre-marketing status.',
    banner: {
      deactivated: (name: string) => `Co-Pilot deactivated for ${name} submission`,
      title: 'Capitola Co-Pilot Enabled',
      description: 'The submission workspace is currently in read-only mode while Capitola Co-Pilot is active.',
      cta: 'Settings',
      toggleLabel: 'Enabled',
      confirmationDialog: {
        header: 'Capitola Co-Pilot',
        title: 'Take back control',
        description: (name: string) => `Capitola Co-Pilot will stop and ${name} will
          take back control of this Submission`,
        confirm: 'Yes, Take Control',
      },
    },

    settingsModal: {
      sliderHeader: 'Processes chosen by your organization to be handled by Capitola Co-pilot',
      disclaimer:
        "for changes to your organization's Co-pilot configuration. The Marketing Strategy is configured for each account.",
      steps: {
        preMarketing: {
          title: 'Pre-Marketing',
          subTitle: 'Prepare the submission for Marketing.',
          bullets: {
            daysBeforeInfoRequest: {
              prefix: 'Request application',
              bold: (days: number) => `${days} days`,
              suffix: 'prior to the renewal date.',
            },
            daysBeforeInfoFollowUp: {
              prefix: 'Send a follow-up email',
              bold: (days: number) => `${days} days`,
              suffix: 'after the initial application request.',
            },
            daysBeforeLossRunRequest: {
              prefix: 'Initiate Loss Run request',
              bold: (days: number) => `${days} days`,
              suffix: 'prior to the renewal date.',
            },
            daysBeforeAssigneeNotification: {
              prefix: 'Notify the assignee if the application and / or Loss Run were not obtained',
              bold: (days: number) => `${days} days`,
              suffix: 'prior to the renewal date.',
            },
          },
        },
        marketing: {
          title: 'Marketing',
          title2: 'Marketing Strategy',
          subTitle1: 'Send the submission to markets, track responses, and manage quotes.',
          bullets: {
            title: 'Notify the assignee about changes in premium and / or coverages, compared to prior term:',
            notifiesTheAssignee: {
              bold: 'Any changes',
              suffix: 'in coverages, limits, and / or retentions.',
            },
            premiumPercentChangeNotificationThreshold: {
              prefix: 'Premium change greater than',
              bold: (percentage: number) => `${percentage}%.`,
            },
          },
          form: {
            title: 'Co-Pilot will send this submission to all incumbent markets and to additional markets added below.',
            incumbent: 'Incumbent',
            newMarket: 'New Market',
          },
        },
        proposal: {
          title: 'Proposal',
          subTitle: (name: string) => `Send the program proposal to the submission assignee (${name}).`,
          bullets: {
            proposalIsReadyForApproval: {
              prefix: 'Send the final proposal to',
              bold: 'the assignee / the Insured',
            },
            unrespondedProposalDaysNotificationThreshold: {
              prefix: 'Notify me when the insured has not responded to the proposal',
              bold: (days: number) => `${days} days`,
              suffix: 'prior to the renewal date.',
            },
          },
        },
        binding: {
          title: 'Binding',
          subTitle: 'Collect binders and policies from the markets and compare them to the respective quote.',
          bullets: {
            binderDelayedDaysNotificationThreshold: {
              prefix: 'Notify the assignee when the binder has not been issued within',
              bold: (days: number) => `${days} days`,
              suffix: 'of the renewal date.',
            },
          },
        },
      },
      confirmButton: 'Start Co-Pilot',
      viewButton: 'View Settings',
      basic: {
        title: (insuredName: string, coverages: string) => `Capitola Co-Pilot Settings: ${insuredName} - ${coverages}`,
      },
      advanced: {
        title: `Capitola Co-Pilot Setup`,
        formFields: {
          market: {
            placeholder: 'Add Market',
            optionalPlaceholder: 'Add Market - Optional',
          },
        },
      },
    },
  },
  submission: {
    status: {
      review: 'Review',
      new: 'Pre-Marketing',
      quote: 'Marketing',
      issued: 'Issued',
      binding: 'Binding',
      issuing: 'Issuing',
      archived: 'Archived',
      closed: 'Closed',
      pendingClient: 'Pending Client',
      missingInformation: 'Missing Information',
      processing: 'Processing',
      quoted: 'Quoted',
    },
    reviewSubtitle: 'Click to override data extraction.',
    type: {
      newBusiness: 'New Business',
      renewal: 'Renewal',
    },
    datesRange: {
      next90Days: 'Next 90 Days',
      next60Days: 'Next 60 Days',
      next30Days: 'Next 30 Days',
      thisAndNext2Months: 'This and Next 2 Months',
      thisAndNextMonth: 'This and Next Month',
      thisMonth: 'This Month',
    },
    reachedLimitText: (reachedLimit: string, targetLimit: string | null) =>
      `$${reachedLimit} out of ${targetLimit ? `$${targetLimit}` : 'unspecified'}`,
  },
  coverageLine: {
    publicDnO: 'Public Directors & Officers',
    privateDnO: 'Private Directors & Officers',
    nonProfitDnO: 'Non-Profit Directors & Officers',
    eno: 'Errors & Omissions',
    cyber: 'Cyber',
    epl: 'Employment Practices Liability',
    property: 'Property',
    casualty: 'Casualty',
    KidnapRansom: 'Kidnap & Ransom',
    Fiduciary: 'Fiduciary Liability',
    Crime: 'Crime',
    managementLiability: 'Management Liability',
    professionalLiability: 'Professional Liability',
    CommercialAutomobile: 'Commercial Automobile',
    ExcessCommercialAuto: 'Excess Commercial Auto',
    ExcessLiability: 'Excess Liability',
    ExcessUmbrella: 'Excess Umbrella',
    GeneralLiability: 'General Liability',
    GeneralLiabilityAndPollutionLiability: 'General Liability & Pollution Liability',
    GeneralLiabilityAndProfessionalLiability: 'General Liability & Professional Liability',
    ProductsLiability: 'Products Liability',
    ProductsRecallLiability: 'Products Recall Liability',
    Umbrella: 'Umbrella',
    WorkersCompOutOfState: "Workers' Compensation Out of State",
    WorkersCompensation: "Workers' Compensation",
    PollutionLiability: 'Pollution Liability',
    HiredNonOwnedAuto: 'Hired Non Owned Auto',
    MiscellaneousCommercial: 'Miscellaneous Commercial',
    BoilerAndMachinery: 'Boiler & Machinery',
    BuildersRiskInstallation: 'Builders Risk/Installation',
    Cargo: 'Cargo',
    DICDifferenceInCondition: 'DIC Difference In Condition',
    EarthquakeCommercial: 'Earthquake-Commercial',
    EquipmentFloater: 'Equipment Floater',
    ExcessProperty: 'Excess Property',
    MotorTruckCargo: 'Motor Truck Cargo',
    OceanMarine: 'Ocean Marine',
    Terrorism: 'Terrorism',
    FloodCommercial: 'Flood Commercial',
    ExportImportNavigationTransitOfGoodsSPECIAL: 'Export/Import-navigation-transit of goods-SPECIAL',
    ForeignPackagePolicy: 'Foreign Package Policy',
    BusinessAndManagementPackage: 'Business & Management Package',
    DirectorsAndOfficersLiability: "Director's & Officer's Liability",
    ElectronicDataProcessing: 'Electronic Data Processing',
    ERISAOnCrimePolicyOrERISABond: 'ERISA On Crime Policy Or ERISA Bond',
    ExcessDirectorsAndOfficersLiability: 'Excess Directors & Officers Liability',
    BoatCommercial: 'Boat-Commercial',
    GarageAndDealers: 'Garage And Dealers',
    MarineLiability: 'Marine Liability',
    ExcessMarineLiability: 'Excess Marine Liability',
    CommercialPackage: 'Commercial Package',
    ExcessProfessionalLiability: 'Excess Professional Liability',
    Bond: 'Bond',
    BusinessOwnersPackage: 'Business Owners Package',
    EmployedLawyersLiability: 'Employed Lawyers Liability',
    MiscellaneousProfessionalLiability: 'Miscellaneous Professional Liability',
    AccountantsPl: 'Accountants PL (APL)',
    ArchitectEngineersPl: 'Architect & Engineers PL (A&E)',
    ContractorsProfessional: "Contractor's Professional",
    EmploymentOrganizationsPl: 'Employment Organizations PL',
    InsuranceAgentsBrokersEnO: 'Insurance Agents & Brokers E&O (IAB)',
    ManufacturersEnO: 'Manufacturers E&O',
    LawyersProfessionalLiability: 'Lawyers Professional Liability (LPL)',
    FinancialAdvisorsInvestmentAdvisorsPl: 'Financial Advisors / Investment Advisors PL',
    MediaMultimedia: 'Media/Multimedia',
    TechnologyEnO: 'Technology E&O',
    FranchisorsEnO: 'Francisors E&O',
    Rnd: 'R&D (Research & Development)',
    SpecifiedProfessionalLiability: 'Specified Professional Liability (Other)',
    PhysiciansMedicalMalpractice: 'Physicians Medical Malpractice',
    AlliedHealthcareProfessionalLiability: 'Allied Healthcare Professional Liability',
    ManagementLiability: 'Management Liability',
    PrivateEmploymentPracticesLiability: 'Private Employment Practices Liability',
    PrivateCrime: 'Private Crime',
    PrivateFiduciary: 'Private Fiduciary',
    PublicEmploymentPracticesLiability: 'Public Employment Practices Liability',
    PublicCrime: 'Public Crime',
    PublicFiduciary: 'Public Fiduciary',
    NonProfitEmploymentPracticesLiability: 'Non profit  Employment Practices Liability',
    NonProfitCrime: 'Non profit Crime',
    NonProfitFiduciary: 'Non profit Fiduciary',
    ExcessManagementLiability: 'Excess Management Liability',
    ExcessCyber: 'Excess Cyber',
    EnvironmentalLiability: 'Environmental Liability',
    EnvironmentalProfessionalsPackage: 'Environmental Professionals Package',
    ContractorsPollutionsLiability: 'Contractors Pollutions Liability',
    StorageTankPollutionLiability: 'Storage Tank Pollution Liability',
    SitePollutionLiability: 'Site Pollution Liability',
    UniqueOfferings: 'Unique Offerings',
    IntellectualProperty: 'Intellectual Property (IP)',
    SexualAbuseMolestation: 'Sexual Abuse & Molestation (SAM)',
    KidnapRansomExecutiveProtection: 'Kidnap & Ransom or Executive Protection (K&R)',
    TerrorismActiveAssailantShooter: 'Terrorism / Active Assailant/Shooter',
  },
  openItems: {
    PrimaryCarrierCurrentlyDatedApplications: "Primary carrier's currently dated applications",
    TriggeringMarketApplicationDatedWithin30DaysEffectiveDateCoverage: (market: string) =>
      `${market} Application dated within 30 days of the effective date for coverage`,
    ConfirmationUnderlyingSubjectivitiesClosedByUnderwriterSubmittedToMarket: (market: string) =>
      `Confirmation that all underlying subjectivities have been closed by the underwriter(s) and submitted to ${market}`,
    CurrentLossRunsPast5Years: 'Current loss runs for the past 5 years',
    ConfirmOpenRDPPortsClosedAddressed: 'Confirm that open RDP ports are closed/addressed',
    RiskControlCallWithMarketCyberRiskControlTeam: (market: string) =>
      `Risk Control call with ${market}'s Cyber risk control team`,
    DoesApplicantPermitRemoteAccessToComputerSystems:
      'Does the applicant permit remote access to its computer systems? Yes or No; If Yes, is multifactor authentication required for all endpoints and users?',
    WarrantyForNewLayer: 'Warranty for new layer (otherwise prior acts exclusion may be used)',
    CopyOfUnderlyingQuoteInsurance: 'Copy of Underlying Quote(s) of Insurance',
    CopyOfUnderlyingBinderInsurance: 'Copy of Underlying Binder(s) of Insurance',
    CopyOfUnderlyingPolicyInsurance: 'Copy of Underlying Policy(ies) once available',
    FirmHadMicrosoftExchangeServerTheLast365Days:
      'Has the firm had Microsoft Exchange Server software in its operating environment in the last 365 days? If YES, please provide an overview of actions taken with regards to the vulnerabilities, including the related "web shell" hacking tool, applicable to the Microsoft Exchange Server versions 2013 through 2019 announced by Microsoft on or about March 2, 2021',
    RansomwareApplication: 'Ransomware application',
    ConsummationOfferingTermsSubstantiallySimilarOutlinedCurrentS1Dated:
      'Consummation of offering in terms substantially similar to those outlined in the current S-1',
    FinalCopyS1: 'Final Copy of the S-1',
    IndividualProducerNameNationalProducerNumberRequiredPrior:
      'The individual Producer name and National Producer Number assigned to this specific submission is required prior to binding the submission and issuing the policy',
    ReceiptTerrorismPolicyholderDisclosureStatement:
      'Receipt of Terrorism Policyholder Disclosure Statement signed and dated by the Insured (Return of the Terrorism Disclosure Statement is only required if the Insured chooses to reject TRIA)',
    IPOCloseWithin5DaysBinding: 'IPO must close within 5 days of binding',
    ProvideMostRecentAuditedFinancialStatements: 'Provide the most recent audited financial statements',
    ProvideInterimYearDateProfitLossIncomeStatements:
      'Provide the interim year to date profit/loss and income statements',
    DescribeDetailProfessionalServicesWhichCoverageDesired:
      'Describe in detail the professional services for which coverage is desired. Are you engaged in any business or profession other than described in this question?\n' +
      'If “Yes”, provide an explanation below and an estimate of total revenues derived from such other business or\n' +
      'profession\n',
    OpenItemsOnApplicationPleaseProvideAnswers:
      'Open Items on the Application: Please provide answers to the following',
    List5LargestJobsProjectsPast3Years:
      'List your five (5) largest jobs or projects during the past three (3) years, including these details:Project or Client Name; Date Services Began; Nature of Services Performed; Revenue YTD; and % of Total Gross Revenue',
  },
  coverageItems,
  coverageCategories: {
    Cyber: 'Cyber',
    TechEnO: 'Technology Errors & Omissions',
    PLI: 'Professional Liability',
    DnO: 'Directors and Officers',
    PublicDnO: 'Public Directors and Officers',
    PrivateDno: 'Private Directors and Officers',
    EPL: 'Employment Practices Liability',
    Crime: 'Crime',
    FLI: 'Fiduciary Liability',
    KidnapRansom: 'Kidnap & Ransom',
    Other: 'Other',
  },
  layer: {
    status: {
      quote: 'Pre-marketing',
      reviewing: 'Marketing',
      bound: 'Bound',
      policyIssued: 'Policy Issued',
    },
  },
  market: {
    status: {
      readyToMarket: 'Ready to Market',
      awaitingQuote: 'Awaiting Quote',
      indication: 'Indication',
      pendingQuote: 'Pending Quote',
      informationRequested: 'Information Requested',
      notInSubmission: 'Not in Submission',
      marketQuoteReceived: 'Market Quote Received',
      QuoteReady: 'Quoted',
      bound: 'Bound',
      policyIssued: 'Policy Issued',
      unaccepted: 'Unaccepted',
      declined: 'Declined',
      pendingCustomer: 'Pending Customer',
      removed: 'Removed',
      incumbent: 'Incumbent',
      quoteShared: 'Quote Shared',
      awaitingBinder: 'Awaiting Binder',
      awaitingPolicy: 'Awaiting Policy',

      getIncumbentText: (isPrimary: boolean) => `Incumbent | ${isPrimary ? 'Primary' : 'XS'}`,
      done: 'Done',
    },
    filesTypeName: {
      marketQuoteFileIds: 'Market Quote',
      binderFileIds: 'Binder',
      policyFileIds: 'Policy',
    },
    iconButtonTooltip: {
      contacts: 'Contacts',
      note: 'Note',
      files: 'Files',
      marketInfo: 'Market Info',
    },
    expiresToday: 'Expires today',

    expiredOn: (date: Date) => `Expired on ${moment(date).format('MM/DD/YYYY')}`,
    expiresIn: (days: number) => `Expires in ${days} ${pluralize('day', days)}`,
    days: 'days',
  },
  marketRequest: {
    note: 'Notes',
  },
  indicators: {
    capitola: 'Capitola Co-Pilot',
  },
  insuranceProductUtils: {
    primary: 'Primary',
    excess: 'Excess',
    primaryAndExcess: 'Primary & Excess',
  },
  general: {
    flowBinder: 'Flow Binder',
    marketAiSummary: 'Market AI Summary',
    openSummeryAndChat: 'Open Summary and Chat',
    clearSummery: 'Got It 👍 (Clear until next update)',
    noSummary: 'No Market AI summary yet...',
    marketSummaryComingSoon: 'Market Summary coming soon...',
    skipEmailGenerationLoading: 'Continue without generated AI email content',
    emailContentLoadingText: 'Composing Email...',
    submissionInformationIncomplete: 'Submission Information Incomplete',
    switchOn: 'On',
    switchOff: 'Off',
    required: 'Required',
    unanswered: 'Unanswered',
    unknownUserName: 'Unknown Sender',
    noReminders: 'No reminders',
    markAsComplete: 'Mark as Complete',
    markAsActive: 'Mark as Active',
    taskIsDisabled: 'You cannot start another task while the current task is in progress',
    abort: 'Abort',
    quote: 'Quote',
    binder: 'Binder',
    policy: 'Policy',
    attach: 'Attach',
    proposal: 'Proposal',
    email: 'Email',
    next: 'Next',
    saveAndLeave: 'Save & Leave',
    nextAddProducts: 'Next: Add Products',
    nextAddRiskAssessment: 'Next: Submission Risk Assessment',
    complete: 'Complete',
    back: 'Back',
    premium: 'Premium',
    attachmentPoint: 'Att. Point',
    aggregateLimit: 'Agg. Limit',
    admission: 'Admission',
    xs: 'XS',
    issuingCompany: 'Issuing Company',
    loading: 'Loading...',
    added: 'Added',
    uploaded: 'Uploaded',
    updated: 'Updated',
    or: 'or',
    on: 'on',
    empty: 'Empty',
    noResults: 'No Results',
    none: 'None',
    neededBy: 'Needed By',
    searchMarkets: 'Search for Markets',
    search: 'Search',
    searchPlaceholder: 'Search...',
    retention: 'Retention',
    coverageLines: 'Coverage Lines',
    details: 'Details',
    notSpecified: 'Not Specified',
    millionSymbol: 'MM',
    selectPlaceholder: 'Select',
    inputPlaceholder: 'Please enter text',
    optional: 'Optional',
    newLabel: 'New',
    primary: 'Primary',
    excess: 'Excess',
    submissionBox: 'Submission Box',
    noContacts: 'No Contacts',
    noIndicationReason: 'No Indication Reason',
    allContacts: 'All contacts',
    submissionBoxInitials: 'SB',
    numberOfContactSelected: (amount: number) => `${pluralize('Contact', amount)} Selected`,
    xsOfMarket: (marketName: string) => `xs of ${marketName}`,
    inSubmission: 'In Submission',
    noContactsSelected: 'No Contacts Selected',
    noResultsFound: 'No results found',
    tryDifferentFilter: 'Please try different filtering text set or',
    resetFilters: 'reset filters',
    savedSuccessfully: 'Saved Successfully',
    addNote: 'Add Note',
    errorMessage: (error: string, statusCode?: number) => `Error: ${error}${statusCode ? ` (${statusCode})` : ''}`,
    addNew: 'Add New',
    continue: 'Continue',
    resolutionAlert: 'For an optimal experience, please zoom out',
    widthAlert: 'For an optimal experience, expend your screen',
    admitted: 'Admitted',
    nonAdmitted: 'Non-Admitted',
    fetchError: 'Network issue. Please refresh',
    operationForbidden: 'Unauthorized operation, Please contact support',
    operationFailed: 'Operation failed, Please try again',
    underwriter: 'Underwriter',
    contactUs: 'Contact Us',
    filtersEmptyState: (filterName?: string) => `No ${filterName} yet`,
    filtersNotFound: (filterName?: string) => (filterName ? `No ${pluralize(filterName)} found` : 'No filters found'),
    filtersNotFoundSubTitle: 'Try different keywords',
    outOfMarkets: (x: string, y: string) => `${x} out of ${y} markets`,
    outOfAgencies: (x: string, y: string) => `${x} out of ${y} agencies`,
    outOfSubmissions: (x: string, y: string) => `${x} out of ${y} submissions`,
    filter: {
      status: 'Status',
    },
    unknown: 'Unknown',
    here: 'here',
    supportMail: 'support@flowspecialty.com',
    moreActions: 'More Actions',
    noItemsFound: 'No Items Found',
    yes: 'Yes',
    no: 'No',
    removed: 'Removed',
  },
  tasks: {
    emptyState: {
      active: 'No Tasks Yet',
      completed: 'No Completed Tasks Yet',
    },
  },

  endUserIndicator: {
    indicatorText: (email?: string) => (email ? `Viewing as ${email}` : 'No end user selected'),
    signOut: 'Sign Out',
  },

  sync: {
    sync: 'Sync',
    syncTo: 'Sync to',
    syncNotAvailable: 'Sync Not Available',
    syncRequested: (name: string) => `Sync of ${name} request sent`,
    syncApproved: (name: string) => `Sync of ${name} approved`,
    synced: 'Synced',
    syncing: 'Syncing',
    syncRequestedTo: 'Sync Requested to',
    syncedTo: 'Synced to',
    file: 'Files',
    email: 'Email',
    accounting: 'Accounting',
    markAsSynced: 'Mark as Synced',
    syncToSystem: (type: string, systemName: string) => `Sync ${type} to ${systemName}`,
    syncedToOn: (syncedSystem: string, date: string) => `Synced to ${syncedSystem} on ${date}`,
    syncRequestedToOn: (syncedSystem: string, date: string) => `Sync Requested to ${syncedSystem} on ${date}`,
  },

  menu: {
    home: 'Home',
    markets: 'Markets',
    agencies: 'Agencies',
    mySubmissions: 'Submissions',
    templates: 'Templates',
    myTeam: 'My Team',
    submissions: 'Submissions',
    submissionBox: 'Submission Box',
    organizations: 'Organizations',
    bi: 'BI',
    defaultTemplates: 'Templates',
    dlqStatus: 'DLQ Status',
    users: 'Users',
    contacts: 'Contacts',
    myFiles: 'My Files',
    allStatuses: 'All Statuses',
    uploadContacts: 'Upload Contacts',
    backOffice: 'Back Office',
  },
  userMenu: {
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
  },
  submissionsView: {
    noResults: {
      title: 'No Submissions Found.',
      subtitle: 'Try different keywords or filters.',
    },
    emptyState: 'Once you start creating submissions, they will appear here.',
    search: 'Submissions, Customer, etc...',
    assigneeSuccessUpdate: 'Assignee Successfully Updated',
    assigneeErrorUpdate: 'Assignee Failed To Update',
    unassigned: 'Unassigned',
    assignee: 'Assignee: ',
    header: {
      title: 'Team Submissions',
      submissionBoxTitle: 'Submission Box',
      retailersTitle: 'My Submissions',
      createSubmission: 'Create Submission',
      newBusiness: 'New Business',
    },
    columnHeaders: {
      insured: 'Insured/Coverage Lines',
      type: 'Type',
      status: 'Status',
      customers: 'Customer/Agency',
      contacts: 'Contacts',
      coverageLines: 'Coverage Lines',
      reminder: 'Reminder',
      effectiveDate: 'Effective Date',
      lastUpdated: 'Last Updated',
      insuredAnnualRevenue: 'Insured Annual Projected Revenue',
      assignee: 'Assignee',
      flowTeamMember: 'Flow Team Member',
    },
    filterHeaders: {
      insured: 'Insured',
      type: 'Type',
      status: 'Status',
      customers: 'Agency',
      contacts: 'Contacts',
      coverageLines: 'Coverage Lines',
      reminder: 'Reminder',
      effectiveDate: 'Effective Date',
      lastUpdated: 'Last Updated',
      insuredAnnualRevenue: 'Insured Annual Projected Revenue',
      assignee: 'Assignee',
      flowTeamMember: 'Flow Team Member',
    },
    sorter: {
      updated: 'Updated',
      insuredAsc: 'Insured (Asc)',
      insuredDesc: 'Insured (Desc)',
      effectiveDateNewest: 'Effective Date (Newest)',
      effectiveDateOldest: 'Effective Date (Oldest)',
      createdDateNewest: 'Created Date (Newest)',
      createdDateOldest: 'Created Date (Oldest)',
      reminders: 'Reminders (Newest)',
      remindersOldest: 'Reminders (Oldest)',
    },
    maxPinnedAlert: (maxPinnedRows: number) => `You can only pin up to ${maxPinnedRows} submissions`,
  },
  myTeamPage: {
    InviteButton: 'Invite Teammates',
    nameLabel: 'Teammate’s Full Name',
    emailLabel: 'Teammate’s Email',
    ContactInformation: 'Contact Information',
    placeHolders: {
      email: 'Email',
      phoneNumber: 'Phone Number',
      note: 'What I Do',
      userTitle: 'Job Title',
    },
    teamInboundEmailInfo: 'Submissions can be automatically added to Flow via',
  },
  agenciesPage: {
    header: {
      title: 'Agencies',
    },
    agentForm: {
      labels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        title: 'Job Title',
      },
      submit: {
        create: 'Create Agent',
        update: 'Update Agent',
      },

      title: {
        create: 'Create Agent',
        update: (agentName: string) => `Edit ${agentName}`,
      },
    },
    agencyForm: {
      labels: {
        name: 'Agency Name',
        domain: 'Domain',
        assignee: 'Assignee',
      },
      placeholders: {
        name: 'Add Agency',
        domain: 'Add Domain',
        assignee: 'Select Assignee',
      },
      submit: {
        create: 'Create Agency',
        update: 'Update Agency',
      },
      title: {
        create: 'Create Agency',
        update: (agencyName: string) => `Edit ${agencyName}`,
      },
    },
    search: 'Search Agencies, Domains, Retailers, etc...',

    unknownSender: 'Unknown Sender',
    emptyState: 'No agencies here just yet.',
    emptyStateSubTitle: 'Click here to create new agency',
    columnHeaders: {
      agencies: 'Agencies',
      retailers: 'Retailers',
      domains: 'Domains',
      assignee: 'Assignee',
      lastUpdated: 'Last Updated',
    },
    addAgent: 'Add Agent',
    createAgency: 'Create Agency',
    addDomain: 'Add Domain',
    editAgency: 'Edit Agency',
    sorter: {
      updated: 'Updated',
      asc: 'A - Z',
      desc: 'Z - A',
    },
  },
  marketsPage: {
    incumbent: 'Incumbent',
    getSelectedText: (number: number) => `${number} Selected`,
    emptyState: 'No markets here just yet.',
    emptyStateSubTitle: 'Click here to create new market',
    search: 'Search Markets, Contacts, etc...',
    coverageLines: 'Coverage Lines',
    riskAppetiteCTA: 'Click To Add Risk Appetite',
    riskAppetite: 'Risk Appetite',
    memo: 'Memo',
    memoCTA: 'Click To Add Memo',
    addSubmissionBox: 'Add Submission Box',
    addUnderWriter: 'Add Underwriter',
    addWholesaler: 'Add Wholesaler',
    deleteMarket: 'Delete Market',
    edit: 'Edit',
    header: {
      title: 'My Markets',
      createMarket: 'Create Market',
    },
    sorter: {
      updated: 'Updated',
      asc: 'A - Z',
      desc: 'Z - A',
      coverageLine: 'Line of business',
    },
    additionalFilters: {
      submissionStatus: {
        inSubmission: 'In submission',
        notInSubmission: 'Not In submission',
        title: 'Market Status',
      },
      coverageLines: {
        title: 'Coverage Lines',
      },
      marketType: {
        title: 'Market Type',
      },
    },
    coverageLinesFilter: {
      search: 'Search Coverage Lines...',
    },
    categoriesFilter: {
      search: 'Search categories...',
      moreTooltip: 'More categories',
    },
    contactsFilter: {
      search: 'Search contacts...',
    },
    columnHeaders: {
      marketName: 'Market Name',
      contacts: 'Contacts',
      coverageLines: 'Coverage Lines',
      categories: 'Categories',
      riskAppetite: 'Risk appetite',
      memo: 'Memo',
      lastUpdate: 'Last Updated',
      select: 'Select',
      marketType: 'Market Type',
      matchReason: 'Match Reason',
    },
    marketType: {
      carrier: 'Carrier',
      wholesaler: 'Wholesaler',
      directMarket: 'Direct Market',
    },
    deleteDialog: {
      title: 'Delete Market',
      confirmation: (marketName: string) => `Delete ${marketName}?`,
      unableToDelete: (marketName: string, submissionsCount: number) =>
        `${marketName} is in ${submissionsCount} ${pluralize('submission')}. It can't be deleted.`,
    },
    productListTooltip: {
      coverageLines: 'Coverage Lines',
      admitted: 'Admitted',
      isPrimary: 'Is Primary',
      coverageLayer: 'Coverage Layer',
      offeringLimit: 'Offering Limit',
      lastUpdated: 'Last Updated',
      updatedBy: 'Updated By',
    },
  },
  templatesPage: {
    emails: 'Email Templates',
    emailTemplateSaving: 'Saving email template...',
    emailTemplateSaved: 'Email template has been saved successfully',
    proposal: 'Proposal Templates',
  },
  backofficePage: {
    title: 'Back Office',
    emailFieldLabel: 'End User Email',
    emailFieldPlaceholder: 'john@company.com',
    setUser: 'Set user',
    signOut: 'Sign Out',
    userNotFound: (email: string) => `User ${email} not found`,
  },
  submissionWorkspace: {
    ...submissionWorkspaceEn,
  },
  nylasAuthentications: {
    popupBlockedMessage: 'To authenticate your email click',
    postAuthentication: {
      title: 'Flow is now connected',
      subtitle: 'Go back to the Flow tab and start sending emails directly from your inbox.',
    },
  },
  roles: {
    userRoles: {
      broker: 'Wholesaler',
      retailer: 'Retailer',
      admin: 'Admin',
      machine: 'Machine',
      bi: 'BI',
      backOffice: 'BackOffice',
      capitolaBroker: 'Flow Broker',
    },
    contactRoles: {
      broker: 'Wholesaler',
      underwriter: 'Underwriter',
      retailer: 'Retailer',
      insured: 'Insured',
      submissionBox: 'SubmissionBox',
    },
    organizationTypes: {
      capitola: 'Flow',
      broker: 'Wholesaler',
      carrier: 'Carrier',
      retailer: 'Retailer',
      insured: 'Insured',
      issuer: 'Issuer',
    },
  },
  admin: {
    admin: 'Admin',
    organizationList: {
      title: 'Organizations',
      uploadMarkets: 'Upload Markets',
    },
    organizationPage: {
      uploadInsuredAction: 'Upload Insureds',
      addTeamAction: 'Add Team',
      convertMarketTypeAction: (organization: Organization) =>
        `Convert to ${organization.type === OrganizationType.Carrier ? 'Wholesaler' : 'Carrier'}`,
      usersGridTitle: 'Users',
      teamsGridTitle: 'Teams',
      contactsGridTitle: 'Contacts',
      contactsGridSubtitle: (organizationName: string) =>
        `These are the contacts that work in ${organizationName}, not the ones that are used by it`,
      convertMarketTypeDialog: {
        convert: 'Convert',
        title: (organization: Organization) =>
          `Convert ${organization.name} to ${
            organization.type === OrganizationType.Carrier ? 'Wholesaler' : 'Carrier'
          }`,
        message: (organization: Organization) =>
          `This will effect all users and teams that have contacts in ${organization.name} `,
      },
    },
    userOrganizationConfiguration: {
      letterheadTitle: 'Company Letterhead',
      emailIntegrationTitle: 'Email Integration',
      whitelistedDomainsTitle: 'Whitelisted Domain List',
      addDomain: 'New domain',
      placeholder: 'example.com',
    },
    contactsPage: {
      gridTitle: 'Contacts',
    },
    emailTemplates: {
      gridTitle: 'Email Templates',
      editTitle: (isNew: boolean) => `${isNew ? 'Add' : 'Edit'} Email Template`,
      templateUpsert: (isNew: boolean) => `Email template has been ${isNew ? 'added' : 'updated'} successfully`,
    },
    defaultTemplates: {
      listView: {
        title: 'Default Email Templates',
        subtitle: 'These templates are automatically created for newly added users',
        tableHeaders: {
          name: 'Name',
          type: 'Type',
          roles: 'User Roles',
        },
      },
      singleView: {
        nameLabel: 'Name',
        typeLabel: 'Type',
        rolesLabel: 'Applies to user roles',
        subjectLabel: 'Subject',
        bodyLabel: 'Body',
      },
    },
    submissionBulkUploadDialog: {
      header: 'Upload Submissions From CSV',
      userEmail: (email: string) => `user: ${email}`,
      uploadFileButton: 'Upload File',
      exampleFile: 'Example File',
      successMessage: (length: number) => `File uploaded successfully (${length} Submissions)`,
      submissionsLink: 'Go to submissions',
      errorsTitle: 'Errors',
      errorMessage: (error: BulkCreateError) => `Line ${error.rowNumber}: ${error.error}`,
    },
    insuredsBulkUploadDialog: {
      header: 'Upload Insured entities From CSV',
      title: (organizationName: string) => `organization: ${organizationName}`,
      uploadFileButton: 'Upload File',
      exampleFile: 'Example File',
      successMessage: (length: number) => `File uploaded successfully (${length} insureds)`,
      errorsTitle: 'Errors',
      errorMessage: (error: BulkCreateError) => `Line ${error.rowNumber}: ${error.error}`,
    },
    userCreatedDialog: {
      loadingMessage: 'Create a new user. Please wait',
      userCreatedSuccessfully: 'User Created Successfully',
      invalidIntegrationEmailAddress: 'email address with "+" will not work in email integration',
      itemsUploadedSuccessfully: (num: number, item: string) => `${num} ${item} uploaded successfully`,
      uploadFailed: (num: number, item: string) => (num > 0 ? `${num} ${item} failed due to errors` : `No errors`),
      errorMessage: (error: BulkCreateError) => `Line ${error.rowNumber}: ${error.error}`,
      backButton: 'Back to Users',
      enterNewUser: 'Enter new user',
    },
    teamCreatedDialog: {
      loadingMessage: 'Create a new team. Please wait',
      teamCreatedSuccessfully: 'Team Created Successfully',
      enterNewTeam: 'Enter new team',
    },
    deleteTeamDataDialog: {
      titleDeleteTeamData: 'Delete Team Data',
      warningMessageDeleteTeamData: 'Warning! This will delete all team data: Contacts and User Markets.',
      successDeleteTeamData: 'Team Data Deleted Successfully',
      deleteTeamDataMessage: (users: number) =>
        `Warning: There are ${users} users in the team. This will delete all the team data (Contacts and User Markets)`,
      deleteTeamDataButton: 'Delete Team Data',
      confirmDeleteTeamDataMessage: "Please type the team's name",
    },
    confirmationDialog: {
      confirmStepTitle: 'Confirm',
      confirmWarning: 'This operation cannot be undone!',
      cancelLabel: 'Cancel',
      deleteTeamDataCheckBoxMessage: 'Yes. Delete all the team data',
    },
    disableEmailIntegrationDialog: {
      title: 'Disable Email Integration',
      warning: 'This operation cannot be undone!',
      confirmLabel: 'Disable',
      confirmDisableMessage: 'Please type the organization name',
    },
  },
  addMissingCoverageLines: {
    title: 'Add Missing Coverage Lines',
    subtitle: (coverageLines: string) =>
      `You are about to add products that do not match the current coverage lines. The new coverage lines (${coverageLines}) will be added to the submission. Please return to the submission information to update it accordingly`,
    submit: "I'm Sure",
  },
  createSubmissionModal: {
    title: 'Create Submission',
    submit: 'Create',
    retailerPlaceholder: 'Retailer contact name',
    retailerLabel: 'Customer',
  },
  deleteFileConfirmation: {
    title: 'Delete File',
    description: (fileName: string) => `Are you sure you want to delete ${fileName}?`,
  },
  marketingUpdate: {
    title: 'Marketing Update',
    loading: 'Loading',
    buttons: {
      saveForLater: 'Save for Later',
      nextGeneratePDF: 'Next: Generate PDF',
      nextComposeEmail: 'Next: Compose Email',
      saveMarketingUpdate: 'Save Marketing Update',
      sendToClient: 'Send to Client',
    },
    generatePdfError: {
      title: 'Error Creating Marketing Update PDF',
      description:
        'We have a technical issue with generating the Marketing Update PDF.\n' +
        'Please get in touch with the DEV team.',
    },
    generateTable: {
      title: 'Marketing Update',
      header: {
        market: 'Market',
        status: 'Status',
        coverageLines: 'Coverage Lines',
        additionalInfo: 'Additional Info',
      },
      regenerate: 'Regenerate',
    },
    marketingUpdateItem: {
      admitted: 'Admitted',
      nonAdmitted: 'Non-Admitted',
      primary: 'Primary',
      excess: 'Excess',
      limit: 'Limit',
      totalPremium: 'Total Premium',
    },
    marketingUpdateEmail: {
      title: 'Marketing Update',
      successMessage: 'Marketing Update Email Sent Successfully',
      aiPopupTitle: 'FlowAI Marketing Update Email 🪄',
      aiTooltipText: 'Generating Marketing Update Email...',
    },
    status: {
      bound: 'Bound',
      quoted: 'Quoted',
      inReview: 'In Review',
      declined: 'Declined',
    },
  },
  marketingReportPage: {
    generateReport: 'Generate Marketing Report',
    marketingReport: 'Marketing Report',
    marketingReportWithCount: (count: number) => `Marketing Report (${count})`,
    composeMarketingReport: 'Compose Marketing Report',
    incumbentSubtitle: 'Incumbent',
    save: 'Save',
    edit: 'Edit',
    cancel: 'Cancel',
    addNote: 'Add Note',
    editNote: 'Edit Note',
    columnHeaders: {
      marketName: 'Market',
      status: 'Status',
      coverageLines: 'Coverage Lines',
      additionalInfo: 'Additional Info',
    },
    insured: 'Insured',
    dueDate: 'Effective Date',
    coverageLine: 'Coverage Lines',
    filePrefixName: 'marketing-report-',
    subtitle: 'Review the report, send it to your customer if needed',
    editMode: {
      title: 'Edit Marketing Report',
      disabled: {
        description:
          'A Marketing Report can only be edited when the submission contains markets that have been submitted to.',
      },
    },
    composeMode: {
      title: 'Compose Marketing Report',
      disabled: {
        description:
          'A Marketing Report can only be sent when the submission contains markets that have been submitted to.',
      },
    },
    informationRequests: {
      editMode: {
        title: 'Edit Information Requests Report',
      },
      composeMode: {
        title: 'Compose Information Requests Report',
      },
    },
    informationRequest: {
      title: 'Information Request',
    },
    declination: {
      title: (marketName: string) => `${marketName} Declined`,
    },
    copyButton: 'Copy',
    toastText: 'Copied!',
    openSuggestedNotesPopper: 'Suggested Notes',
    informationRequestedAdditionalInfoPrefix: 'Information Requested:',
    declinationAdditionalInfoPrefix: 'Declination:',
  },
  ConceptualTowerPage: {
    viewMode: {
      title: 'Conceptual Tower',
      subtitle: '',
    },
    generateMode: {
      title: 'Conceptual Tower',
      subtitle: 'Generate a demo of your potential tower',
    },
    sendMode: {
      title: 'Send to Customer',
      subtitle: 'Send the conceptual tower to your customer',
    },
    generateButton: 'Generate Conceptual Tower',
    fileTitle: 'Conceptual Tower',
    sendButton: 'Send conceptual tower',
    save: 'Save',
    edit: 'Edit',
    cancel: 'Cancel',
    columnHeaders: {
      marketName: 'Market',
      layer: 'Layer',
      admitted: 'Admitted',
      premium: 'Premium',
      rpm: 'RPM',
      limit: 'Limit',
      status: 'Status',
    },
    filePrefixName: 'conceptual-tower-',
    info: 'Only layers with selected quotes appear below',
  },
  expiringTowerPage: {
    viewMode: {
      title: 'Expiring Program Tower',
      subtitle: '',
    },
    columnHeaders: {
      marketName: 'Market',
      layer: 'Layer',
      AttachmentPointRetention: 'Attachment Point/Retention',
      premium: 'Premium',
      aggregateLimit: 'Aggregate Limit',
    },
    ShareButtonDisabled: {
      description: 'The Expiring Program can only be shared when there are markets added',
    },
  },
  labelsMenu: {
    labeledByFlow: 'Labeled by Flow',
    addLabelButton: 'Add Label',
    addMarketLabelButton: 'Select Market',
    clearLabel: 'Clear',
    permanentLabelDisabled: 'This label was automatically set by the system and cannot be edited',
  },
  emailLabelsMenu: {
    addLabelText: 'Select Market',
    noMarketsAvailable: 'No markets available',
  },
  emailMenus: {
    agent: 'Agent',
  },
  fileExplorer: {
    title: 'Files',
    lastModified: 'Last modified:',
    today: 'Today',
    searchPlaceHolder: 'Search Files',
    noItemsFound: 'No Items Found',
    loadingFiles: 'Loading Files...',
    cancel: 'Cancel',
    save: 'Save',
    filesSelected: (num: number) => `${num} ${pluralize('File', num)} selected`,
    menu: {
      edit: 'Edit',
      rename: 'Rename',
      editLabel: 'Edit Label',
      addLabel: 'Add Label',
      download: 'Download',
      delete: 'Delete',
      more: 'More',
      emailMarket: 'Email File to Market',
      emailCustomer: 'Email File to Customer',
      addQuote: 'Add File to Quote',
      addQuoteDisabled: "Only files labeled 'Flow Quote' can be added to a quote",
    },
    anyDocsMissing: 'Any Docs Missing?',
    contactCustomer: 'Contact Your Customer',
    iconTooltips: {
      deleteFile: 'Delete File',
      downloadFile: 'Download File',
      searchFiles: 'Search Files',
      filterByLabels: 'Filter By Labels',
      addFiles: 'Add Files',
      addLabel: 'Add Label',
    },
    formFields: {
      name: {
        placeholder: 'Enter a new name',
        label: 'New name',
      },
    },
  },
  filesUploader: {
    emptyState: {
      dragAndDrop: 'Drop files here, or',
      browseComputer: 'Browse computer',
    },
    dragAndDrop: 'Drag and Drop Files Here',
    subtitle: "Loss Runs, Application, Application Renewal, Supplemental, Financial, etc'",
    browseComputer: 'Or Browse Your Computer',
    selectMore: 'Select More Files',
    upload: 'Upload Files',
  },
  containedFileViewerModal: {
    title: 'File Preview',
    cancel: 'Cancel',
    save: 'Save',
    menu: {
      edit: 'Edit',
      rename: 'Rename',
      editLabel: 'Edit Label',
      download: 'Download',
      delete: 'Delete',
    },
  },
  heraldApi: {
    notAllSelectedProductsAreEligibleForSubmission: 'Not all selected products are eligible for receiving api quotes',
    applicationIncompleteStatus:
      'Submission information is incomplete, go to pre-marketing to complete the submission form',
    noHeraldApplicationInCoverageLines: 'Submission coverage lines is not supported with herald products',
    failedToCreateHeraldSubmission:
      'Quotes via API are currently unavailable, the R&D team has been notified. Select emails or portal submissions until resolved.',
    rfqSentViaApi: 'The RFQ was successfully sent via the API.',
    productsNotInApplication: (products: string[]) =>
      `${
        products.length === 1
          ? 'The Following product is not part of the herald application.'
          : `The Following products are not part of the herald application: ${products.join(',')}.`
      } Try re-adding the product's coverage line to the submission`,
  },
  heraldForm: {
    apiAvailable: (active: number, total: number) => `API Available Products (${active}/${total})`,
    failedToUpdateIndustry: 'Failed to updated industry in herald',
    failedToLoadApplication: 'failed to load herald application',
    failedToUpdateApplication: 'failed to update herald application',
    failedToUpdateApplicationAfterUpdateSubmission: 'failed to update herald application after update submission',
    failedToUpdateApplicationDueToValidation: (fieldsError: string) =>
      `Failed to update submission due to a problem in the existing application. please go over the form and fix the following issues:${fieldsError}`,
  },
  incompleteSubmissionDialog: {
    reasons: {
      reason1: "Agent hasn't provided all required details",
      reason2: 'Not suitable for API',
      reason3: 'I want to send it directly for a quick quote',
      reason4: "I'll complete the information later",
    },
    dialogTitle: 'Incomplete Submission Information',
    dialogContent: {
      incompleteText: (
        count: number,
      ) => ` There are ${count} unanswered questions. To submit via API, please complete all required
          information.`,
      skip: 'Need to skip? Let us know why 🤔',
      helperText: 'You can select more than one reason.',
    },
    buttons: {
      submit: 'Continue Anyway',
      back: 'Back to Complete',
    },
  },
  editSubmission: {
    fieldValidationError: 'One or more of the fields are invalid',
    insuredInformation: 'Insured Information',
    coverageParameters: 'Coverage Parameters',
    coverageLines: 'Coverage Lines',
    formTitle: `Submission Information`,
    heraldAPiError: `Herald API Error, please try again`,
  },
  riskAssessmentNote: {
    title: 'Submission Risk Assessment',
    placeholder: 'Add your risk summary notes...',
    explanation: 'This will be surfaced to help you select products.',
    popupTitle: 'FlowAI Risk Assessment 🪄',
  },
  reviewAgent: {
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      title: 'Job Title',
    },
    save: 'Save Contact',
    title: (agencyName: string) => `Add Contact to ${agencyName}`,
    banner: {
      title: 'Unknown Retail Agent',
      subtitle: 'Please add the new retail agent to your agency account to continue processing this submission.',
    },
  },
  submissionModal: {
    submissionUpdated: `Submission Successfully Updated`,
    submissionExists: 'Submission already exists for Insured and Coverage line',
    customer: 'Customer',
    companyName: 'Company Name',
    insuredName: 'Insured Name',
    industry: 'Industry',
    insuredCompanyType: 'Insured Company Type',
    assignee: 'Team Member Assignee',
    customers: 'Customers',
    contacts: 'Contacts',
    type: 'Type',
    insuredAddress: 'Insured Primary Address',
    insuredMailingAddress: 'Insured Mailing Address',
    coverageLines: 'Coverage Lines',
    coverages: 'Coverage lines',
    coveragesSubtitle: '(Please choose 1 or more)',
    limit: 'Proposed Program Limit',
    limitPlaceHolder: '$10,000,000',
    dueDate: 'Effective Date',
    needByDate: 'Need By Date',
    insuredAnnualRevenue: ' Insured Projected Annual Revenue',
    insuredEmployeeCount: 'Insured Employee Count',
    insuredWebsite: 'Insured Website',
    placeholder: {
      coverageLines: 'Search and Select',
    },
  },
  userMarketModal: {
    title: {
      create: 'New Market',
      update: (userMarketName?: string) => (userMarketName ? `Edit Market - ${userMarketName}` : 'Edit Market'),
    },
    description: 'Select a market to add to your submission.',
    submissionBoxSection: 'Submission Box',
    coveragesSection: 'Coverage Lines',
    categorySection: 'Categories',
    marketInfoSection: 'Market Info',
    formFields: {
      label: {
        marketName: 'Market',
        memo: 'Memo',
        riskAppetite: 'Risk Appetite',
        contacts: 'Contacts',
        categories: 'Categories',
        submissionBox: 'Email',
      },
      placeholder: {
        marketName: 'Add Market',
        memo: 'Add Memo',
        riskAppetite: 'Add Risk Appetite',
        contacts: 'Add Contacts',
        submissionBox: 'john@company.com',
        categories: 'Add Label',
      },
    },
    submit: {
      create: 'Save New Market',
      update: 'Update Market',
    },
    cancel: 'Cancel',
    userMarketAdded: (userMarket: string) => `${userMarket} was added successfully.`,
    userMarketUpdated: (userMarket: string) => `${userMarket} was updated successfully.`,
    userMarketAlreadyExist: (marketName: string) => `Market ${marketName} already exist`,
  },
  submissionList: {
    title: 'Team Submissions',
  },
  marketsModal: {
    header: 'Select Markets',
    title: (coverageLine: string) => `My ${coverageLine} Markets`,
    marketsAddedToast: (addedMarkets: number) =>
      `${addedMarkets} ${pluralize('market', addedMarkets)} added successfully.`,
    noFieldData: (field: string) =>
      `No ${field.toLowerCase()} was added. You can add ${field.toLowerCase()} in 'Market Information'`,
    emptyStateTitle: (coverageLine: string) => `No ${coverageLine} Markets just yet.`,
  },
  addMarketsPage: {
    title: 'Select Markets',
    subtitle: {
      primary: 'Add the markets you wish to quote for Primary for this program.',
      excess: 'Add the markets you wish to quote for Excess for this program.',
      general: 'Add the markets you wish to quote for this program.',
    },
  },
  selectProductsPage: {
    submit: 'Next: Compose Acknowledgment',
  },
  acknowledgmentFlow: {
    title: 'Send Acknowledgment Email',
    overviewBanner: {
      title1: 'Send Acknowledgement Overview:',
      content1:
        'Complete submission Information and select relevant products. This ensures efficient generation of your acknowledgement email.',
      title2: 'Please note:',
      content2:
        'Required fields allows for submissions via our API. Relevant unanswered questions would be included in the acknowledgement email.',
    },
    processingBanner: {
      title: 'Submission data is being extraction by AI',
      subTitle: 'If you choose to continue without waiting, extracted results will not be populated.',
    },
  },

  acknowledgmentEmailPage: {
    sendToClient: 'Send to Client',
    successMessage: 'Acknowledgment email sent successfully',
    aiPopupTitle: 'FlowAI Marketing Email 🪄',
  },
  addProductsPage: {
    title: 'Select Products',
    subtitle: 'Send a submission to markets and start quoting',
    search: 'Search',
    comingSoon: 'Coming Soon',
    coverageLinesFilter: {
      title: 'Coverage Lines',
      search: 'Search coverage lines',
    },
    layersFilter: {
      title: 'Layers',
    },
    admittedFilter: {
      title: 'Product Type',
    },
    incumbentTooltip: {
      primary: 'Incumbent, Primary',
      excess: 'Incumbent, Excess',
    },
    columnHeaders: {
      product: 'Product',
      availableVia: 'Available Via',
      match: 'Match',
      notes: 'Notes',
    },
    additionalFilters: {
      isAdmitted: {
        admitted: 'Admitted',
        nonAdmitted: 'Non-Admitted',
      },
      isPrimary: {
        primary: 'Primary',
        excess: 'Excess',
      },
      incumbent: 'Incumbent',
    },
    noMethodsTooltip: "No product methods available. Can't submit to this product.",
    methodsDropdown: {
      email: 'Email',
      portal: 'Portal',
      api: 'API',
      notAvailableYet: 'Not available yet',
    },
    footer: {
      getSelectedText: (number: number) => `${number} Selected`,
      addProducts: 'Add Products',
    },
    productsAddedToast: (addedProducts: number) =>
      `${addedProducts} ${pluralize('product', addedProducts)} added successfully.`,
  },
  renameFileDialog: {
    header: 'Rename File',
    getLabel: (fileName: string) => `Please enter a new name for ${fileName}:`,
  },
  dirtyPageModal: {
    title: 'Unsaved Changes',
    header: 'Are you sure you want to leave?',
    subHeader: 'Looks like you have unsaved changes, would you like to continue editing or discard the changes?',
    discardChanges: 'Discard changes',
    continueEditing: 'Continue editing',
  },
  resetToTemplateModal: {
    title: 'Reset to Template',
    header: 'Are you sure you want to reset?',
    subHeader: 'The content of this email will be reset to the template. All changes will be lost.',
    discardChanges: 'Reset',
  },
  setEndUserDialog: {
    title: 'Are you sure?',
    header: 'Switch to viewing as:',
  },
  quoteStatusModal: {
    bound: {
      title: (layerTitle: string) => `Compare binder and quote for ${layerTitle}`,
      successMessage: (marketName: string) => `${marketName}'s binder added successfully`,
      missMatchMessage: (marketName: string) => `${marketName}'s binder was discarded`,
      titleFormView: 'View Binder',
      changeFile: 'Change Binder Files',
      uploadFileText: 'Upload Binder Files',
      pickFileText: 'Select Binder Files',
      sideBySide: {
        headerItem1: (marketName: string) => `${marketName} Quote`,
        headerItem2: (marketName: string) => `${marketName} Binder`,
        cancelButton: 'Binder Doesn’t Match',
        proceedButton: 'Attach Binder',
      },
      comparisonTitle: 'Quote Binder Comparison',
    },
    binderPolicyIssued: {
      title: (layerTitle: string) => `Compare policy and binder for ${layerTitle}`,
      successMessage: (marketName: string) => `${marketName}'s policy issue was added successfully`,
      missMatchMessage: (marketName: string) => `${marketName}'s policy issue was discarded`,
      titleFormView: 'View Policy',
      changeFile: 'Change Policy Files',
      uploadFileText: 'Upload Policy Files',
      pickFileText: 'Select Policy Files',
      sideBySide: {
        headerItem1: (marketName: string) => `${marketName} Binder`,
        headerItem2: (marketName: string) => `${marketName} Policy`,
        cancelButton: 'Policy Doesn’t Match',
        proceedButton: 'Attach Policy',
      },
      comparisonTitle: 'Binder Policy Comparison',
    },
    quotePolicyIssued: {
      title: (layerTitle: string) => `Compare policy and quote for ${layerTitle}`,
      successMessage: (marketName: string) => `${marketName}'s policy issue was added successfully`,
      missMatchMessage: (marketName: string) => `${marketName}'s policy issue was discarded`,
      titleFormView: 'View Policy',
      changeFile: 'Change Policy Files',
      uploadFileText: 'Upload Policy Files',
      pickFileText: 'Select Policy Files',
      sideBySide: {
        headerItem1: (marketName: string) => `${marketName} Quote`,
        headerItem2: (marketName: string) => `${marketName} Policy`,
        cancelButton: 'Policy Doesn’t Match',
        proceedButton: 'Attach Policy',
      },
      comparisonTitle: 'Quote Policy Comparison',
    },
  },
  submissionStatusChangeDialog: {
    header: 'Submission status update',
    content: (status: SubmissionStatus) =>
      `Would you like to change the submission status to '${status}'? Confirm to proceed with the update.`,
    confirmButton: (status: SubmissionStatus) => `Move to ${status}`,
    cancelButton: 'Don’t move',
  },
  SupportModal: {
    title: 'Support',
    label: 'Support',
    descriptionParagraph2:
      'In case you have questions or suggestions regarding the product, please fill in the form below. In addition, you can reach us at ',
    contactMethods: ' or call ',
    phoneNumber: '(800) 449-0135.',
    recipientName: 'Support',
    getUserContactDetails: (firstName: string, lastName: string, email: string, organizationName: string) =>
      `User contact details: Name: ${firstName} ${lastName}, Email: ${email}, Organization: ${organizationName} <br><br> Message: `,
    sendingSuccessfully: 'Support request sent successfully',
    yourEmailPlaceHolder: 'Your email',
  },
  InviteModal: {
    title: 'Invite Teammates',
    description: 'Give your teammates access to Flow and start collaborating in real time.',
    recipientName: 'Support',
    getUserContactDetails: (
      firstName: string,
      lastName: string,
      email: string,
      organizationName: string,
      teamName = '',
    ) =>
      `User contact details: Name: ${firstName} ${lastName}, Email: ${email}, Organization: ${organizationName}, Team: ${teamName} <br><br> Message: `,
    getInviteMessage: (organizationName: string, name: string, email: string, teamName = '--') =>
      `Hello, please add: Name: ${name}, Email: ${email}, from ${organizationName} to Team: ${teamName} `,
    getInviteEmailSubject: (teamName: string) => `Adding teammate to ${teamName} `,
    sendingSuccessfully: 'Invite request sent successfully to Flow Admin',
    yourEmailPlaceHolder: 'email',
    sendButton: 'send invite',
    teamNamePlaceHolder: 'Team Name',
  },
  SettingsDialog: {
    title: 'Settings',
    personalSettings: 'Personal Settings',
    emailSettings: {
      title: 'Email Settings',
      emailIntegration: {
        title: 'Email Integration',
        description: 'Connect and sync your inbox with Flow to send emails from your own email address.',
        status: {
          authenticated: 'Connected',
          unauthenticated: 'Connect',
          invalidToken: 'Disconnected',
        },
        callout: {
          message: 'To connect talk to your IT admins or',
          contactUs: 'contact us',
        },
      },
    },
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phoneNumber: 'Phone Number',
      note: 'What I Do',
      userTitle: 'Job Title',
      signature: 'Signature',
    },
    savedSuccessfully: 'Saved Successfully',
  },
  sentEmailDialog: {
    title: (date: string | Date) => `Email Sent on ${getDisplayDateWithHoursAmPm(date) ?? ''}`,
    to: 'To',
    from: 'From',
    cc: 'Cc',
    subject: 'Subject',
    date: 'Date',
    getReSubject: (subject: string) => `Re: ${subject}`,
  },
  marketsGeneral: {
    marketInformation: 'Market Information',
    inSubmission: (marketName: string) => `${marketName} is already part of the submission`,
    contacts: 'Contact(s)',
    contactsPlural: 'Contacts',
    riskAppetite: 'Risk appetite',
    riskAppetiteEmptyState: 'No risk appetite added. You can add Risk Appetite via More Information.',
    memo: 'Memo',
    allContactsTitle: 'All',
    markets: 'Markets',
    selected: 'Selected',
  },
  emailEditor: {
    createFollowUpReminder: 'Create Follow-Up Reminder',
    noFollowUpReminder: 'No Follow-Up Reminder',
    tinyTokenCreateFail: 'Network issue. Please retry',
    subject: 'Subject',
    from: 'From',
    to: 'To',
    cc: 'Cc',
    info: 'Every Market will receive a separate email',
    addMarkets: 'Add Markets',
    preview: 'Preview',
    previewEmail: 'Preview Email',
    edit: 'Edit',
    addContact: 'Add New Contact',
    recipients: 'Recipients',
    noneSelected: 'None selected',
    addMarketAbove: 'Add a market above',
    removeMarket: (marketName: string) => `Remove ${marketName}`,
    selectTemplate: 'Select Template',
    resetToTemplate: 'Reset to Template',
    template: 'Template',
    viewAsAriaLabel: 'View As Selector',
    attachFiles: 'Attach Files',
    attachedFilesTitle: (attachmentsCount: number) => `Attachments (${attachmentsCount})`,
    attachTooltip: 'Attach Uploaded File',
    downloadAllAttachments: 'Download All Attachments',
    failedToUploadArchive: 'There was a problem with sending the attached files, the email could not be sent',
    sourceImageInfo: 'For an optimal experience, only use images that have been uploaded from your computer',
    sendEmailDisabledForBackofficeToolTip: 'Unavailable for back office users',
    sendEmailDisabledForUnauthenticatedUser: 'Please connect your email to send emails',
    sendEmailDisabledTooltip: 'Please fill in required fields (such as: Markets, Subject, Message) before sending',
    sendEmailDefaultDisabledTooltip: 'Please fill in all required field before sending',
    sendEmailDisabledTooltipUnresolvedVariables: `Please resolve the following dynamic objects before sending:`,
    emailAttachmentsVariable: 'The following documents are attached for your review:',
    detachForMarket: 'Personalize message for market',
    editForAll: 'Edit email for all markets',
    cancel: 'Cancel',
    discard: 'Discard',
    saveTemplate: 'Save Changes',
    changesSaved: 'Changes Saved',
    getSendButton: (number: number) => `Send to ${number || ''} Markets`,
    sendMessagesGuards: {
      alreadySubmittedMarkets: (number: number) =>
        number > 1
          ? `${number} markets have previously been submitted to during this submission`
          : `1 market has previously been submitted to during this submission`,
      listNoteHeader: 'Before sending please note that:',
      nonProductionSendingConfirmation: (env: string) => `Are you sure you want to send an email from ${env}?`,
      recipientsConfirmationTitle: 'Are you sure?',
      attachmentsLimitSendingConfirmationMessage: (sizeLimit: number) =>
        `Your files are larger than ${sizeLimit}MB. They will be sent as a link.`,
      generalConfirmationMessage: (retailerName: string) => `You are about to send an email to ${retailerName}`,
      generalMarketsNumberConfirmationMessage: (number: number) =>
        number > 1 ? `${number} markets will receive your email` : `1 market will receive your email`,
      noAttachmentsTitle: 'Forgot to add attachments?',
      brokenAttachmentsTitle: 'Failed to attach file',
      filesSizeAlertTitle: 'File size exceeds supported limit',
      noAttachmentsUnderwriters: 'Submission emails usually require sending relevant documents to underwriters',
      noAttachmentsCustomers: 'Sending a message to customers, usually require attaching relevant documents',
      brokenAttachments: (filesCount: number) =>
        `The following ${pluralize('file', filesCount)} seems to be broken, please remove before sending:`,
      sendAnyway: 'Send Anyway',
    },
    sentMessagesScreen: {
      sendingMessages: (amount: number) => `Sending ${amount} ${amount === 1 ? 'message' : 'messages'}:`,
      messagesSent: (amount: number) => `${amount} ${amount === 1 ? 'message' : 'messages'} sent`,
      uploadingFiles: 'Building Zip File',
      toSubmissionWorkspace: (insuredName: string) => `Enter ${insuredName} Workspace`,
      toMySubmissions: 'Back to "Team Submissions"',
      nextMessage: 'Next Message',
      or: 'or',
      cc: 'A copy has also been sent to',
    },
    newEmailTemplateName: 'New Email',
    infoTooltip: {
      title: 'Attach Files',
      message: 'Select the files to attach to your email.',
      secondaryMessage: 'The files will be added to all your selected markets.',
    },
  },
  emailEditorModal: {
    title: 'Send Messages',
  },
  maxRows: {
    more: 'More...',
  },
  formMessages: {
    invalidDomain: 'Invalid domain',
    requiredField: 'This field is required',
    oneContactIsRequired: 'At least one contact or submission box is required',
    uniqueEmail: 'Email must be unique',
    uniqueCoverage: 'Coverage must be unique',
    requiredFieldShort: 'Required',
    invalidDate: 'Invalid date',
    valueShouldNotBeOneOf: 'The value should not be one of',
    invalidEmail: 'Invalid email',
    positiveNumberOnly: 'This field must be a positive number',
    exceedingAggregateLimit: (number: string) => `Exceeds limit (${number})`,
    maxNumberAllowed: (number: string) => `Max ${number}`,
    positiveOnly: (fieldName: string) => `${fieldName} must be a positive number`,
    minimumLimit: (fieldName: string, number: string) => `${fieldName} minimum number is: ${number}`,
    maximumLimit: (fieldName: string, number: string) => `${fieldName} maximum number is: ${number}`,
    noDecimalAllowed: `Decimal number not allowed`,
    needBySmallerThanDue: 'Need by date must be prior to the effective date',
    invalidURL: 'Invalid URL',
    noSlashAllowedInFile: "File name must not include '/' or '\\'",
    noEmptyStringAllowedInFile: 'File name must not be empty',
    undeliverableEmail: 'Undeliverable email address',
  },
  sublimits: {
    title: 'Sub-limits',
  },
  subjectivities: {
    title: 'Subjectivities',
    addButton: 'Add Subjectivities',
    removeButton: ' Remove Subjectivities',
  },
  quoteForm: {
    scanningBanner: {
      title: 'Scanning Documents',
      description: 'Capitola Co-Pilot is looking for data to auto-fill your quote details',
    },
    tabs: {
      details: 'Details',
      coverages: 'Coverages',
      subjectivities: 'Subjectivities',
    },
  },
  quoteComparisonPage: {
    title: 'Compare Quotes',
    subTitle: 'Generate quote comparison',
    buttons: {
      nextGeneratePDF: 'Next: Generate PDF',
      savePDF: 'Save PDF',
      sideBySide: 'Review Quotes Side by Side',
    },
    fieldLabels: {
      flowInsight: 'Flow Insight',
    },
    fieldPlaceholders: {
      flowInsight: 'Flow Insight',
    },
    compose: {
      title: 'Quote Comparison',
      filesListTitle: 'Files',
      expiringPolicy: 'Expiring Policy',
      loading: 'Generating Insights...',
      loadingQuotesList: 'Loading Quotes...',
      quoteComparisonInsights: {
        popupTitle: 'FlowAI Quote Comparison Insights 🪄',
        generatingQuoteComparisonInsights: 'Generating quote comparison insights...',
      },
    },
    upload: {
      quoteComparisonError: {
        title: 'Error Creating Quote Comparison',
        defaultDescription:
          'We have a technical issue with generating the Quote Comparison PDF.\n' +
          'Please get in touch with the DEV team.\n',
      },
      quoteComparisonPdfTitle: 'Quote Comparison',
    },
  },
  addQuotePage: {
    extractingData: 'Extracting Data',
    attach: 'Attach',
    update: 'Update',
    view: 'View',
    coverage: 'Coverage',
    coverages: 'Coverages',
    attachQuote: 'Attach Quote',
    attachQuoteCreateSubmission: 'Attach Quote & Create Submission',
    updateQuote: 'Update Quote',
    done: 'Done',
    attachQuoteTo: 'Attach Quote to',
    updateQuoteTo: 'Update Quote for',
    viewQuote: 'View Quote for',
    attachBinder: 'Attach Binder',
    updateBinder: 'Update Binder',
    attachPolicy: 'Attach Policy',
    updatePolicy: 'Update Policy',
    changeFile: 'Change files',
    noMarkets: 'No markets available',
    noCoveragesAdded: 'No coverages added',
    noFilesSelectedTooltip: 'No files selected',
    missingMarketQuoteFileLabel: 'Label the market quote file',
    selectDifferentFiles: 'Select different files',
    subtitle: 'Add quote details and select file',
    excess: 'Excess',
    primary: 'Primary',
    admitted: 'Admitted',
    nonAdmitted: 'Non-Admitted',
    coveragesConfirmation:
      'Please review these suggested coverages and confirm that they are correct and match the quote.',
    flowQuoteTitle: 'Flow Quote',
    flowQuoteError: {
      title: 'Error Creating Flow Quote',
      getEncryptedFileError: (fileName: string) => `The document ${fileName} is encrypted.
Please remove the encrypted document from the quote files, upload an unprotected file, and regenerate Flow Quote.`,
      defaultDescription:
        'We have a technical issue with generating the Flow quote PDF.\n' +
        'Please get in touch with the DEV team.\n' +
        'To workaround, please manually upload a flow quote',
    },
    quoteInsights: {
      popupTitle: 'FlowAI Quote Insights 🪄',
      title: 'Quote Insights',
      generatingQuoteInsights: 'Generating quote insights...',
      alertBanner: {
        missingMarketQuoteLabel: {
          title: 'Flow AI Data Extraction and Insights Unavailable',
          subtitle: 'Label the market quote file as "Market Quote" to enable data extraction and generate insights.',
        },
        missingPolicySpecimenLabel: {
          title: 'Flow AI is missing files for improved insights',
          subtitle: 'Upload the Specimen file for this quote and label it. Regenerate to update the insights.',
        },
        missingMarketBinderLabel: {
          title: 'Flow AI is missing files for improved insights',
          subtitle: 'Upload the market binder file as "Market Binder".',
        },
        missingMarketPolicyLabel: {
          title: 'Flow AI is missing files for improved insights',
          subtitle: 'Upload the market policy file as "Market Policy" to enable data extraction.',
        },
      },
    },
    marketQuote: {
      viewSubtitle: 'View quote details and selected files',
      topHeaderTitle: 'Quote',
      topHeaderSubtitle: 'Add quote details and select files',
      headerTitle: 'Quote File & Details',
      headerSubtitle:
        'Files will be appended to Flow Quote in the following order. Drag the files to change their order.',
      attachFileButton: 'Attach File',
      infoTooltip: {
        title: 'Attach Quote',
        message: (marketName?: string) =>
          marketName
            ? `Select the quote and additional files from ${marketName}`
            : 'Select the quote and additional files',
      },
    },
    binder: {
      topHeaderTitle: 'Binder',
      topHeaderSubtitle: 'Select binder files and view quote details',
      headerTitle: 'Binder Files & Details',
      headerSubtitle: 'Select the binder files from the files panel',
      attachFileButton: 'Attach Binder',
      policyEffectiveDate: 'Policy Effective Date',
      policyExpirationDate: 'Policy Expiration Date',
      infoTooltip: {
        title: 'Attach Binder',
        message: (marketName?: string) => (marketName ? `Select the binder from ${marketName}` : 'Select the binder'),
      },
    },
    policy: {
      topHeaderTitle: 'Policy',
      topHeaderSubtitle: 'Select policy files and view quote details',
      headerTitle: 'Policy Files & Details',
      headerSubtitle: 'Select the policy files from the files panel',
      attachFileButton: 'Attach Policy',
      infoTooltip: {
        title: 'Attach Policy',
        message: (marketName?: string) => (marketName ? `Select the policy from ${marketName}` : 'Select the policy'),
      },
    },
    details: {
      title: 'Quote File & Details',
      subTitle: 'Select the quote files from the files panel and fill in the relevant details',
    },
    buttons: {
      details: 'Next: Coverages',
      coverages: 'Next: Subjectivities',
      attachPrimary: 'Attach Primary Market Quote',
      attachXs: 'Attach Market Quote',
      updatePrimary: 'Update Primary Market Quote',
      updateXs: 'Update Market Quote',
      nextCreateInsights: 'Next: Create Insights',
      nextFlowQuote: 'Next: Review Flow Quote',
      saveFlowQuote: 'Save Flow Quote',
      bind: 'Attach Binder',
      compareBinderToQuote: 'Compare Binder to Quote',
      policy: 'Attach Policy',
    },
    selectMarketGroups: {
      inSubmission: 'In Submission',
      notInSubmission: 'Not in Submission',
    },
    selectProductGroups: {
      inSubmission: 'In Submission',
      notInSubmission: 'Not in Submission',
    },
    comments: {
      addButton: 'Add Comments',
      description:
        'Comments serve as the insights, considerations and recommendations for clients regarding the attached quote. Positioned ahead of the detailed quote information, these comments are featured in both the quote email template and the retailer platform view.',
    },
    fieldLabels: {
      programSummary: 'Program Summary',
      file: 'File',
      policyDetails: 'Policy Details',
      coverage: 'Coverage',
      coverageRestrictions: 'Coverage Notes',
      primaryQuoteId: 'Please select the underlying primary quote',
      market: 'Market',
      product: 'Product',
      issuingCompany: 'Issuing Company',
      licensing: 'Licensing',
      attachmentPoint: 'Attachment point',
      retention: 'Retention',
      premium: 'Premium',
      agentCommission: 'Agent Commission',
      limit: 'Aggregate Limit',
      perClaimLimit: 'Per Claim Limit',
      admittedNonAdmitted: 'License Status',
      effectiveFrom: 'Retroactive Date',
      brokerCommission: 'Commission',
      coverageLines: 'Coverage Lines',
      coverages: 'Coverages',
      comments: 'Comments',
      quoteDetails: 'Quote Details',
      mainDetails: 'Main Details',
      expiresAt: 'Quote Expiration',
      policyNumber: 'Policy Id',
      policyEffectiveDate: 'Effective From',
      policyExpirationDate: 'To',
      flowCommission: 'Flow Commission',
      date: 'Quote Date',
      binderDate: 'Binder Date',
      subLimits: 'Limits & Deductibles',
      triaPremium: 'TRIA Premium',
      carrierFee: 'Carrier Fee',
      flowBrokerFee: 'Flow Broker Fee',
      surplusLineTax: 'Surplus Line Tax',
      surplusStampingFee: 'Surplus Stamping Fee',
      subjectivities: 'Subjectivities',
      binderSubjectivities: 'Binder Subjectivities',
      stateCompliance: 'State compliance',
      binderStateCompliance: 'Binder State compliance',
      limitsAndDeductibles: 'Limits & Deductibles',
      taxesAndFees: 'Taxes & Fees',
      flowInsight: 'Flow Insight',
    },
    fieldPlaceholders: {
      subLimits: 'Type your text here',
      primaryQuoteId: 'Select Primary Quote',
      stateCompliance: 'State compliance...',
      binderStateCompliance: 'Binder State compliance...',
      coverageLines: 'Search and Select',
      coverages: {
        type: 'Search or Add',
        typeOptional: 'Search or Add (Optional)',
        limit: '$5,000,000',
        retention: '$5,000,000',
        follow: 'Yes',
      },
      market: 'Market',
      product: 'Product',
      issuingCompany: 'Search or Add',
      attachmentPoint: 'Attachment point',
      premium: '$150,000',
      agentCommission: 'Please enter percentage',
      limit: '$5,000,000',
      admittedNonAdmitted: 'Select',
      effectiveFrom: 'Full prior acts or enter a date',
      brokerCommission: '16%',
      flowInsight: 'Flow Insight',
    },

    coveragesGridTitles: {
      coverage: 'Coverage',
      limit: 'Limit',
      retention: 'Retention',
      followForm: 'Follow form',
    },
    subjectivities: {
      title: 'Subjectivities',
      searchPlaceHolder: 'Search Subjectivities',
      selectedItems: (number: number) => `${number} selected`,
    },
    newAttachmentPoint: 'New Attachment Point',
    addAttachmentPoint: 'Add Attachment Point',
    selectNewMarkets: 'Select New Market',
    addQuoteToastMessage: (attachmentPointText: string, marketName?: string) =>
      `${marketName}'s quote has been attached at ${attachmentPointText}`,
    updateQuoteToastMessage: (marketName: string) => `${marketName}'s quote has been updated successfully.`,
  },
  compareQuotesModal: {
    title: (layerTitle: string) => `Compare Quotes for ${layerTitle}`,
    successMessage: (marketName: string, attachmentPointText: string) =>
      `${marketName}'s quote selected for ${attachmentPointText}`,
    listSelect: {
      header: 'Select 2 quotes to compare',
      cancelButton: 'Cancel',
      proceedButton: 'Compare',
    },
    sideBySide: {
      cancelButton: 'Cancel',
      quoteComparison: 'Quote Comparison',

      proceedButton: (marketName?: string) => (marketName ? `Select ${marketName}'s Quote` : `Select Quote`),
    },
    subMenu: {
      title: 'Select Quotes to compare',
      subTitle: 'You can compare only 2 quotes',
    },
  },
  createCustomerModal: {
    getTitle: (retailer: boolean) => `Add New ${retailer ? 'Contact' : 'Customer'}`,
    getFormHeader: (retailer: boolean) => `${retailer ? 'Contact' : 'Customer'} Details`,
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phoneNumber: 'Phone Number',
      companyName: 'Company Name',
      customerType: 'Customer Type',
      title: 'Job Title',
    },
    placeholders: {
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@company.com',
      phoneNumber: '(555) 555 5555',
      organizationId: 'Select',
      title: 'Job Title',
    },
    getProceedText: (retailer: boolean) => `Add New ${retailer ? 'Contact' : 'Customer'}`,
    cancel: 'Cancel',
    retailer: 'Retailer',
    insured: 'Insured',
  },
  addContactToMarketModal: {
    addTitle: (marketName: string) => `Add Contact to ${marketName}`,
    updateTitle: (marketName: string) => `Update Contact in ${marketName}`,
    formHeader: 'Contact Details',
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phoneNumber: 'Phone Number',
      products: 'Products',
      contactType: 'Contact Type',
      title: 'Job Title',
      contactRiskAppetite: 'Contact Risk Appetite',
      riskAppetite: 'Risk Appetite',
    },
    placeholders: {
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@company.com',
      phoneNumber: '(555) 555 5555',
      products: 'Search and Select',
      title: 'Job Title',
      riskAppetite: 'Add Risk Appetite',
    },
    addContact: 'Add Contact',
    updateContact: 'Update Contact',
    cancel: 'Cancel',
  },
  formAutoComplete: {
    addItem: (item: string) => `Add '${item}'`,
  },
  declinationPage: {
    title: (marketName: string) => `${marketName} Declined`,
    subtitle: 'Add a declination reason',
    declinationContentTitle: 'Declination Reason:',
    successMessage: (marketName: string, insuredName?: string) =>
      `${marketName} declined ${insuredName ? `${insuredName}'s` : ''} submission`,
  },
  informationRequestPage: {
    title: 'Add Information Request',
    subtitle: 'Keep a record of the requested information, send it to your customer if needed',
    otherPlaceholder: 'Type or paste here',
    otherLabel: 'Fill In The Requested Information',
    checkboxListLabel: 'Or Select From The List',
    successMessage: (marketName: string) => `More information requested by ${marketName}`,
  },
  submissionMarketToolbox: {
    contacts: {
      updated: 'Contact information updated',
      newContact: 'New Contact',
      atLeastOneContact: 'At least one contact is needed per submission',
      contactRemoved: 'Contact was removed',
      labels: {
        riskAppetite: 'Risk Appetite',
      },
      placeholders: {
        search: 'Search Contacts',
        email: 'Email',
        coverageLines: 'Coverage Lines',
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Phone Number',
        riskAppetite: 'Add Risk Appetite',
        title: 'Job Title',
      },
      addNewContact: 'Add New Contact',
      searchContacts: 'Search Contacts',
    },
  },
  quoteItemLabels: {
    premium: 'Premium',
    retention: 'Retention',
    sublimits: 'Sublimits',
    subjectivities: 'Subjectivities',
    none: 'None',
    limit: 'Limit',
    attachmentPoint: 'Attachment Point',
  },
  quoteDisplayLabels: {
    premium: 'Premium',
    underlyingPrimaryQuote: 'Underlying primary quote',
    programInfo: 'Program Info',
    subjectivities: 'Subjectivities',
    coveragesNotes: 'Coverages Notes',
    programInfoColumns: {
      coverage: 'Coverage',
      followForm: 'Follow Form',
      limit: 'Limit',
      retention: 'Retention',
    },
  },
  expiringProgramTab: {
    priorActsCoverageLabels: {
      fullPriorActs: 'Full prior acts',
      retroactiveDate: 'Date',
      none: 'None',
    },
  },
  contactsMenu: {
    newContact: 'New Contact',
  },
  iconTooltips: {
    add: 'Add',
    edit: 'Edit',
    search: 'Search',
    filter: 'Filter',
  },
  activityLogTable: {
    columns: {
      date: 'Date',
      time: 'Time',
      activity: 'Activity',
      performedBy: 'Performed By',
    },
    filePrefixName: 'activity-log-',
  },
  submissionMarketActivityLog: {
    on: 'On',
    unknownUser: 'Unknown User',
    admission: 'Admission',
    sentEmail: 'View, Follow-up or Duplicate Email',
    activitiesTitles: {
      [ActivityType.BinderAttached]: 'Binder Attached',
      [ActivityType.BinderUpdated]: 'Binder Updated',
      [ActivityType.BinderRemoved]: 'Binder Removed',
      [ActivityType.AddedManually]: 'Market Added',
      [ActivityType.AddedAsIncumbent]: 'Added As Incumbent',
      [ActivityType.MarketDeclined]: 'Market Declined',
      [ActivityType.QuoteIndication]: 'Quote Indication',
      [ActivityType.InformationRequested]: 'Information Request Received',
      [ActivityType.PolicyIssued]: 'Policy Issued',
      [ActivityType.PolicyRemoved]: 'Policy Removed',
      [ActivityType.PolicyUpdated]: 'Policy Updated',
      [ActivityType.QuoteAttached]: 'Quote Attached',
      [ActivityType.QuoteNotAccepted]: 'Quote Not Accepted',
      [ActivityType.QuoteRemoved]: 'Quote Removed',
      [ActivityType.QuoteUpdated]: 'Quote Updated',
      [ActivityType.RequestForQuote]: 'Request For Quote Sent',
      [ActivityType.BinderCorrection]: 'Binder Correction Request Sent',
      [ActivityType.PolicyCorrection]: 'Policy Correction Request Sent',
      [ActivityType.BindRequest]: 'Bind Request Sent',
      [ActivityType.PolicyRequested]: 'Policy Request Sent',
      [ActivityType.MarketEmailSent]: 'Email Sent To Market',
      [ActivityType.MarketEmailReceived]: (activityLog: ActivityLog) =>
        `Email has been received from ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
    },
  },
  submissionActivityLog: {
    on: 'On',
    admission: 'Admission',
    sentEmail: 'View, Follow-up or Duplicate Email',
    activitiesTitles: {
      [ActivityType.RequestForQuote]: (activityLog: ActivityLog) => {
        const groupedActivityCount = activityLog.groupedActivities?.length ?? 1;
        return `Marketing Email has been sent to ${
          groupedActivityCount === 1
            ? activityLog.submissionMarket?.marketName ?? 'a market'
            : `${groupedActivityCount} markets`
        }`;
      },
      [ActivityType.SubmissionStatusUpdated]: (oldValue: string, newValue: string) =>
        `Status changed from ${oldValue} to ${newValue}`,
      [ActivityType.MarketEmailSent]: (activityLog: ActivityLog) => {
        const groupedActivityCount = activityLog.groupedActivities?.length ?? 1;
        return `Email has been sent to ${
          groupedActivityCount === 1
            ? activityLog.submissionMarket?.marketName ?? 'a market'
            : `${groupedActivityCount} markets`
        }`;
      },
      [ActivityType.AddedManually]: (activityLog: ActivityLog) => {
        const groupedActivityCount = activityLog.groupedActivities?.length ?? 1;
        return groupedActivityCount === 1
          ? `${activityLog.submissionMarket?.marketName ?? 'A market'} has been added to the Submission Workspace`
          : `${groupedActivityCount} markets have been added to the Submission Workspace`;
      },
      [ActivityType.AddedAsIncumbent]: (activityLog: ActivityLog) => {
        const groupedActivityCount = activityLog.groupedActivities?.length ?? 1;
        return groupedActivityCount === 1
          ? `${
              activityLog.submissionMarket?.marketName ?? 'A market'
            } has been added as incumbent to the Submission Workspace`
          : `${groupedActivityCount} incumbent markets have been added to the Submission Workspace`;
      },
      [ActivityType.BinderAttached]: (activityLog: ActivityLog) =>
        `Binder attached for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.BinderUpdated]: (activityLog: ActivityLog) =>
        `Binder updated for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.BinderRemoved]: (activityLog: ActivityLog) =>
        `Binder removed for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.MarketDeclined]: (activityLog: ActivityLog) =>
        `${activityLog.submissionMarket?.marketName ?? 'a market'} has declined`,
      [ActivityType.QuoteIndication]: (activityLog: ActivityLog) =>
        `Quote Indication from market ${activityLog.submissionMarket?.marketName}`,
      [ActivityType.InformationRequested]: (activityLog: ActivityLog) =>
        `Information Request received for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.PolicyIssued]: (activityLog: ActivityLog) =>
        `Policy issued for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.PolicyRemoved]: (activityLog: ActivityLog) =>
        `Policy removed for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.PolicyUpdated]: (activityLog: ActivityLog) =>
        `Policy updated for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.QuoteAttached]: (activityLog: ActivityLog) =>
        `Quote attached for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.QuoteNotAccepted]: (activityLog: ActivityLog) =>
        `Quote not accepted for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.QuoteRemoved]: (activityLog: ActivityLog) =>
        `Quote removed for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.QuoteUpdated]: (activityLog: ActivityLog) =>
        `Quote updated for ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.FilesUploaded]: (activityLog: ActivityLog) => {
        const count = activityLog.metadata?.fileIds?.length ?? 1;
        return `${count} ${pluralize('file', count)} ${pluralize('was', count)} added to the Submission Workspace`;
      },
      [ActivityType.CustomerEmailSent]: 'Email Sent To Customer',
      [ActivityType.ProposalSent]: 'Proposal has been sent to the customer',
      [ActivityType.ConceptualTowerCopiedToClipboard]: 'A Conceptual Tower Report has been copied to clipboard',
      [ActivityType.ConceptualTowerDownloaded]: 'A Conceptual Tower Report has been downloaded',
      [ActivityType.ConceptualTowerSent]: 'A Conceptual Tower Report has been sent to the customer',
      [ActivityType.ExpiringTowerCopiedToClipboard]: 'An Expiring Tower has been copied to clipboard',
      [ActivityType.MarketingReportCopiedToClipboard]: 'A Marketing Report has been copied to clipboard',
      [ActivityType.MarketingReportDownloaded]: 'A Marketing Report has been downloaded',
      [ActivityType.MarketingReportSent]: 'A Marketing Report has been sent to the customer',
      [ActivityType.InformationRequestCopiedToClipboard]: 'An Information Request has been copied to clipboard',
      [ActivityType.InformationRequestDownloaded]: 'An Information Request has been downloaded',
      [ActivityType.InformationRequestSent]: 'An Information Request has been sent to the customer',
      [ActivityType.SubmissionCreated]: 'Submission Workspace has been created',
      [ActivityType.SubmissionUpdated]: 'Submission Workspace has been edited',
      [ActivityType.ProposalUpdated]: 'Proposal has been edited',
      [ActivityType.IncumbentInfoEdited]: 'Expiring program has been updated',
      [ActivityType.CustomerEmailReceived]: 'Customer Email has been received',
      [ActivityType.ArfSent]: 'Binders sent to accounting',
      [ActivityType.ArfRequest]: 'Processing binders to be sent to accounting',
      [ActivityType.MarketEmailReceived]: (activityLog: ActivityLog) =>
        `Email has been received from ${activityLog.submissionMarket?.marketName ?? 'a market'}`,
      [ActivityType.CopilotEscalationEmailSent]: `Co-Pilot escalation email has been sent to the submission assignee`,
      [ActivityType.SubmissionCopilotEnabled]: `Co-Pilot has been enabled`,
      [ActivityType.SubmissionCopilotDisabled]: `Co-Pilot has been disabled`,
    },
    markets: 'markets',
    today: 'Today',
  },
  pagination: {
    rowsPerPage: 'Rows Per Page:',
  },
  box: {
    ...boxEn,
    updateLabelError: 'Failed to Update Label',
    fileWithNameAlreadyExists: 'A file with this name already exists, please choose a different name',
  },
  recipientGroups: {
    contactsCount: (amount: number) => `${amount} ${pluralize('contact', amount)}`,
  },
  yoyReport: {
    expiringProgramBarName: 'Expiring Program',
    optionName: 'Renewal Option',
    annualPremium: 'Annual Premium',
    NA: 'N/A',
    totalPremium: 'Total Premium',
    missingQuoteData: 'Missing Quote Data',
    attachQuote: 'Attach a quote',
    forThisAttachmentPoint: 'for this attachment point.',
    aggregateLimit: 'Aggregate limit',
    yoyReportTitle: 'Year Over Year Comparison Report',
    fileName: 'yoy-report',
    missingExpiringProgramData: 'Missing Data',
    expiringProgram: 'Expiring Program Report',
    forExpiringProgramChart: "is required to populate this chart's data.",
  },

  teammatesGroup: (amount: number) => `${amount} ${pluralize('teammate', amount)} selected`,
  templateVariables: {
    declination: (reason: string, details?: string) => `${reason}${details ? `:\n${details}` : ''}`,
    informationRequested(type: string, details?: string) {
      const typeAndDetailsSeparator = type.endsWith('?') ? '' : ':';
      return `${type}${details ? `${typeAndDetailsSeparator}\n${details}` : ''}`;
    },
    missingInformationRequest: {
      missingFilesTitle: 'Missing files:',
      missingSubmissionDetailsTitle: 'Missing submission details:',
    },
    fullPriorActs: 'full prior acts',
  },
  declinationConfig: {
    autoIndustryBasedReason: 'Industry is an automatic decline based on underwriting guidelines',
    autoIndustryBasedPlaceholder: "State the applicant's specific risk that is outside of the carrier's guidelines",
    openClaimHistoryReason: 'Loss history - open claim',
    openClaimHistoryPlaceholder: 'Add name of claim(s)',
    priorClaimHistoryReason: 'Loss history - prior claims without adequate controls to prevent a similar event',
    priorClaimHistoryPlaceholder: 'Add name of claim(s)',
    riskReason: 'Risk does not meet underwriting guidelines',
    riskPlaceholder: 'Describe the criteria',
    attachmentPointTooLowReason: 'Attachment point is too low for this risk/industry',
    attachmentPointTooLowPlaceholder: 'Add context about the minimum attachment point',
    underlyingPricingReason: 'Inadequate underlying pricing',
    underlyingPricingPlaceholder: "Describe the carrier's minimal pricing per the layer",
    legalEnvironmentReason: 'Legal / Regulatory environment for this risk is outside of underwriting guidelines',
    legalEnvironmentPlaceholder: 'Refer to the new legislation',
    otherReason: 'Other, please specify',
    cannotCompeteReason: 'Cannot compete with the premium price',
    cannotCompetePlaceholder: 'Add more details',
    marketBlockedReason: 'Market is blocked',
    marketBlockedPlaceholder: 'Add more details',
  },
  templateTypeToDescription: {
    [EmailTemplateType.InformationRequestedRetailer]:
      'Used for asking the customer for more submission information as it has been asked by a market',
    [EmailTemplateType.BindOrder]: 'Used for asking the market to send a binder of their quote',
    [EmailTemplateType.PolicyOrder]: 'Used for asking the market to send a policy of their quote',
    [EmailTemplateType.BinderCorrection]:
      "Used to ask the market to send a new binder because it doesn't match the quote",
    [EmailTemplateType.LayerBinder]: 'Used for sharing a binder with the customer',
    [EmailTemplateType.LayerPolicy]: 'Used for sharing a policy with the customer',
    [EmailTemplateType.MarketDeclined]: 'Used for sharing with the customer that a market has declined the submission',
    [EmailTemplateType.MarketingReport]: 'Used for sharing a marketing report with the customer',
    [EmailTemplateType.RfqGeneral]: 'Used for sharing a general opportunity (i.e., submission) with markets',
    [EmailTemplateType.PrimaryMarketing]: 'Used for sharing a primary opportunity (i.e., submission) with markets',
    [EmailTemplateType.ExcessMarketing]: 'Used for sharing an excess opportunity (i.e., submission) with markets',
    [EmailTemplateType.PolicyCorrection]:
      "Used to ask the market to send a new policy because it doesn't match the binder",
    [EmailTemplateType.RfqAttachmentPoint]: 'Used for sharing a specific attachment point within a tower with markets',
    [EmailTemplateType.ConceptualTower]: 'Used for sharing the current state of the conceptual tower with the customer',
    [EmailTemplateType.SubmissionUpdate]:
      'Used for sharing an update about a submission (usually underlying quotes) with potential overlying prospects markets that the wholesaler is waiting for their quote',
    [EmailTemplateType.NewBusinessProposal]: 'Used for sharing the new business proposal with the customer',
    [EmailTemplateType.MissingInformationRequest]:
      'Used for requesting missing information and files from the customer',
    [EmailTemplateType.UserNotification]: 'Used for notifying users',
    [EmailTemplateType.EscalationPolicy]: 'Used for escalating a Co-Pilot submission',
    [EmailTemplateType.MarketEmailFollowup]: 'Used for following up on an email sent to a market',
    [EmailTemplateType.CustomerEmailFollowup]: 'Used for following up on an email sent to the customer',
    [EmailTemplateType.CannotPursue]: 'Used for informing the customer that we can not place this submission',
    [EmailTemplateType.PositiveExpectation]:
      'Used for informing the customer that we have positive expectation for placing this submission',
    [EmailTemplateType.NewQuote]: 'Used for sharing a newly received quote with the customer',
    [EmailTemplateType.SubmissionReceived]: 'Used for notifying the customer that we have received their submission',
    [EmailTemplateType.SubmissionAssigned]:
      'Used for notifying Flow brokers that a submission has been assigned to them',
    [EmailTemplateType.Compose]: 'Used for composing a new email for Customer or Market',
    [EmailTemplateType.SalesDemoAcknowledgment]:
      'Used for acknowledging receipt of a submission in a sales demo workflow',
    [EmailTemplateType.SalesDemoProposal]: 'Used for sending a proposal in a sales demo workflow',
    [EmailTemplateType.SalesDemoDnOAcknowledgment]:
      'Used for acknowledging receipt of a submission in a sales demo D&O workflow',
    [EmailTemplateType.SalesDemoDnOProposal]: 'Used for sending a proposal in a sales demo D&O workflow',
  },
  utils: {
    and: 'and',
  },
  contactRole: {
    [ContactRole.Underwriter]: 'Underwriter',
    [ContactRole.SubmissionBox]: 'Submission Box',
    [ContactRole.Retailer]: 'Retailer',
    [ContactRole.Insured]: 'Insured',
    [ContactRole.Broker]: 'Wholesaler',
  },
  errorPage: {
    title: 'Oops, something went wrong...',
    description: 'Whatever happened, it was probably our fault. Please try again.',
    refreshPage: 'Refresh Page',
  },
  quoteReview: {
    reviewQuote: 'Review Quote',
    coverageNotes: 'Coverage Notes',
    files: 'Files',
    attachmentPoint: 'Att. Point',
    premium: 'Premium',
    retention: 'Retention',
    coverage: 'Coverage',
    limit: 'Limit',
    na: 'N/A',
  },
  regeneratePopup: {
    title: 'Restart Conversation?',
    description:
      'This will restart our chat and the previous conversation will be deleted. Don’t worry, you’ll get new insights!',
    areYouSure: 'Are you sure you want to start fresh?',
    yesRegenerate: 'Yes, Regenerate',
  },
  notifications: {
    cta: {
      proposal: 'Compose Proposal',
      marketingUpdate: 'Compose Marketing Update',
      acknowledgement: 'Compose acknowledgement',
      unrepliedEmail: 'Send Follow up',
    },
  },
};
