import { messages } from 'i18n';
import { CoverageLayer } from 'types';

const {
  insuranceProductUtils: { primary, excess, primaryAndExcess },
} = messages;

export function getCoverageLayerLabel(coverageLayer?: CoverageLayer) {
  if (!coverageLayer) {
    return primary;
  }
  if (coverageLayer.isPrimary && coverageLayer.isExcess) {
    return primaryAndExcess;
  }
  if (coverageLayer.isExcess) {
    return excess;
  }
  return primary;
}

export function isPrimaryCoverageLayer(coverageLayer?: CoverageLayer) {
  return !coverageLayer || coverageLayer.isPrimary || !coverageLayer.isExcess;
}
