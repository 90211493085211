import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import Content from './Content';

export default function FlowBinder() {
  const { submission } = useSubmissionsWorkspace();

  if (!submission) {
    return null;
  }
  return (
    <WithRouteLocationState routeKey={RouteKey.FlowBinder} isStateRequired>
      {(locationState) => <Content locationState={locationState} submission={submission} />}
    </WithRouteLocationState>
  );
}
