import { Stack } from '@common-components';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { DynamicObjectFieldsComponentProps } from './DynamicObjectWrapper';

export default function HeraldClaimEvent({ onBlur, ownBuildName }: DynamicObjectFieldsComponentProps) {
  return (
    <>
      <FormTextField enhancedRequired onBlur={onBlur} {...ownBuildName('description', 'Description')} />
      <Stack direction="row" gap={3}>
        <FormDatePicker enhancedRequired onBlur={onBlur} {...ownBuildName('date', 'YYYY-MM-DD')} />
        <FormTextField
          onBlur={onBlur}
          {...ownBuildName('amount', '$10,000')}
          isNumberFormat
          numberFormatProps={{ decimalScale: 0 }}
          enhancedRequired
        />
      </Stack>
    </>
  );
}
