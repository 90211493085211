import {
  DisplayDateFormat,
  DisplayDateFormatAlt,
  DisplayDateWithHoursFormat,
  DisplayHoursFormat,
} from '@common/utils/date-utils';
import moment from 'moment';

export * from '@common/utils/date-utils';

// Convert the date according to the selected format
// Note that the date is converted to the local timezone of the user
export function getDisplayDate(date: string | Date, format = DisplayDateFormat): string {
  return moment(date).format(format);
}

export function getAltDisplayDate(date: string | Date): string {
  return getDisplayDate(date, DisplayDateFormatAlt);
}

export function getDisplayDateWithHoursAmPm(date: string | Date): string {
  return getDisplayDate(date, DisplayDateWithHoursFormat);
}

export function getTimeDisplay(date: Date) {
  return getDisplayDate(date, DisplayHoursFormat);
}

export function isMoreThan24HoursAgo(date: string | Date): boolean {
  // Convert the input date to a moment object
  const givenDate = moment(date);
  // Get the current date and time
  const now = moment();

  // Calculate the difference in hours
  const hoursDiff = now.diff(givenDate, 'hours');

  // Return true if the difference is more than 24 hours
  return hoursDiff >= 24;
}
export function timeSince(date: string | Date) {
  // Convert the input date to a moment object
  const givenDate = moment(date);
  // Get the current date and time
  const now = moment();

  // Calculate the difference in hours and minutes
  const hoursDiff = now.diff(givenDate, 'hours');
  const minutesDiff = now.diff(givenDate, 'minutes') % 60; // Get remaining minutes after hours are accounted for

  // Check if the difference is more than 72 hours
  if (hoursDiff > 72) {
    return `+72h`;
  }
  if (hoursDiff > 24) {
    return `${hoursDiff}h`;
  }
  return `${hoursDiff}h ${minutesDiff}m`;
}

export const calculateDaysDiff = (date: Date) => moment(date).diff(moment().startOf('day'), 'days');

export function getTodayDisplayDate() {
  return getDisplayDate(new Date());
}

export function getOneYearFromDate(date: string | Date) {
  return moment(date).add(1, 'year');
}

export function formatDistanceToNowStrict(date: string | Date) {
  return `${moment(date).fromNow(true)} ago`; // 'true' for without suffix
}

export function isInFutureDaysRange(date: Date, numberOfDays: number) {
  return moment(date).isBetween(moment().startOf('day'), moment().add(numberOfDays, 'days'), null, '[]');
}

export function isInThisAndMonthsRange(date: Date, numberOfMonths: number) {
  return moment(date).isBetween(
    moment().startOf('month'),
    moment().add(numberOfMonths, 'months').endOf('month'),
    null,
    '[]',
  );
}

export function getTimestamp(date: string | Date) {
  return moment(date).valueOf();
}

export const datePickerDateFormat = DisplayDateFormat;
