import Markdown from 'markdown-to-jsx';
import { useRef } from 'react';
import { AIIconIndicator, alpha, Box, Chip, Stack, Typography } from '@common-components';
import { AutoAwesomeOutlined, Close as CloseButton } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { timeSince, truncateMultiLineText } from 'utils';
import AIPopup from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIPopup';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface MarketSummaryProps {
  marketRequest: ExtendedMarketRequest;
}

export default function MarketSummary({ marketRequest }: MarketSummaryProps) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu, setAnchorEl } = useOptionsMenu();

  const { updateSubmissionMarketRequest } = useSubmissionsWorkspace();

  const { threadId } = useAIContext();

  const updatedDate = marketRequest.summary
    ? `${messages.general.updated}: ${timeSince(marketRequest.summary.triggeredAt)}`
    : '';

  const boxRef = useRef<HTMLElement | null>(null);

  const hideSummaryFeature = false;

  const emptyStateText = hideSummaryFeature ? 'Coming Soon...' : messages.general.noSummary;

  const hasResponse = !!(marketRequest.summary?.response && !hideSummaryFeature);

  return (
    <>
      <Stack direction="row" height={1} alignItems="flex-start" gap={0.5}>
        <Box
          sx={{
            transform: 'translateY(-4px)',
          }}
          onClick={hasResponse ? openMenu : undefined}
          ref={boxRef}
        >
          <AIIconIndicator size="extraSmall" disabled={!hasResponse} />
        </Box>

        <Stack
          height={1}
          position="relative"
          sx={{
            '&:hover .hover-content': {
              opacity: hasResponse ? 1 : 0,
              visibility: 'visible',
            },
            '& .hover-content': {
              opacity: 0,
              visibility: 'hidden',
              transition: 'opacity 0.3s ease, visibility 0.3s ease',
            },
          }}
        >
          <Stack flex={1}>
            <Typography
              maxWidth={750}
              variant="body2"
              color={hasResponse ? 'text.secondary' : 'text.disabled'}
              sx={{
                ...truncateMultiLineText(4),
                '& p:first-of-type': {
                  marginBlockStart: 0, // Remove the margin at the top of the first paragraph
                },
              }}
            >
              {hasResponse ? <Markdown>{marketRequest.summary?.response!}</Markdown> : emptyStateText}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={0.5}
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            alignItems="center"
            pt={1}
            className="hover-content"
            sx={{
              backdropFilter: 'blur(2px)',
              bgcolor: (theme) => alpha(theme.palette.common.white, 0.5),
            }}
          >
            <Stack direction="row" gap={0.5} flex={1}>
              <Chip
                label={messages.general.openSummeryAndChat}
                onClick={() => {
                  if (boxRef.current) {
                    setAnchorEl(boxRef.current);
                  }
                }}
                size="small"
                startIcon={AutoAwesomeOutlined}
                variant="contained"
                color="green.200"
              />
              <Chip
                label={messages.general.clearSummery}
                onClick={async () => {
                  await updateSubmissionMarketRequest(marketRequest.id, {
                    summaryId: null,
                  });
                }}
                size="small"
                startIcon={CloseButton}
                variant="contained"
                color="secondary"
              />
            </Stack>
            {updatedDate && (
              <Typography variant="caption" color="text.secondary" noWrap title={updatedDate}>
                {updatedDate}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {isMenuOpen && (
        <AIPopup
          popupTitle={`${marketRequest.marketName} - ${messages.general.marketAiSummary}`}
          withFileLinks
          isRichTextResponse
          anchorEl={anchorEl}
          isMenuOpen={isMenuOpen}
          closeMenu={closeMenu}
          popupMaxHeight="50vh"
          popupMinHeight={400}
          popupWidth={620}
          threadId={threadId}
          openMenu={openMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
    </>
  );
}
