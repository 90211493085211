import { AgencyClient } from 'clients/capitola/agency';
import { QueryKeys } from 'enums';
import { Agency, AgencyCreate, AgencyCreateByName, AgencySearch } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchAgency(props?: UseQueryBaseSearchOptions<Agency, AgencySearch>) {
  const { filter, queryOptions, enabled, sorter } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Agency,
    clientClass: AgencyClient,
    filter,
    enabled,
    sorter,
    queryOptions: {
      // Default agency search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveAgency(props: UseQueryBaseRetrieveOptions<Agency>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Agency,
    clientClass: AgencyClient,
    id,
    enabled,
    queryOptions: {
      // Default agency retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateAgency() {
  const { create: createAgency, update: updateAgency } = useMutate<Agency, Agency, AgencyCreate, AgencyClient>({
    queryKey: QueryKeys.Agency,
    clientClass: AgencyClient,
    updateOptions: { throwOnError: true },
  });

  const { create: createAgencyByName } = useMutate<Agency, Agency, AgencyCreateByName, AgencyClient>({
    queryKey: QueryKeys.Agency,
    clientClass: AgencyClient,
    createOptions: {
      subPath: 'by-name',
      throwOnError: true,
    },
  });

  return { createAgencyByName, createAgency, updateAgency };
}
