import { Divider, Paper, Stack } from '@common-components';
import { messages } from 'i18n';
import { Quote, SubmissionMarket } from 'types';

import { formatPriceOrNA } from 'utils';
import QuoteField from 'broker/components/common/QuotesAndPolicies/QuoteField';
import QuoteHeader from './QuoteHeader';
import { QuoteFileKey } from './quotesAndPoliciesConfig';

interface QuotesAndPoliciesItemProps {
  quote: Quote;
  fileType: QuoteFileKey;
  market?: SubmissionMarket;
  setSelectedQuote?: (quote: Quote) => void;
}

export default function QuotesAndPoliciesItem({ quote, market, fileType }: QuotesAndPoliciesItemProps) {
  return (
    <Paper sx={{ borderRadius: 2 }}>
      <QuoteHeader fileType={fileType} quote={quote} market={market} />
      <Divider />
      <Stack gap={2} sx={{ px: 2, py: 3 }} direction="row" justifyContent="space-between">
        <QuoteField title={messages.quoteItemLabels.premium} data={`$${formatPriceOrNA(quote.premium)}`} />
      </Stack>
    </Paper>
  );
}
