import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Stack } from '@common-components';
import { FormMode, QuoteFileIdType, SubmissionStatus } from 'enums';
import { messages } from 'i18n';
import { getFirstErrorId, scrollToElement } from 'utils';
import { QuoteFormRoutes } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate, useNavigateToStep } from 'broker/hooks';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { Subjectivity } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { FlowGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/types';
import { useStatusChangeDialog } from 'broker/pages/SubmissionWorkspacePage/dialogs/SubmissionStatusChangeDialog/useStatusChangeDialog';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import FooterStepper from './FooterStepper';
import { QuoteFormProps } from './types';
import useSubmitQuoteForm from './useSubmitQuoteForm';

export default function QuoteFooter(quoteFormProps: QuoteFormProps) {
  const {
    flowQuoteIds,
    mode,
    onClose,
    fileType,
    selectedQuote,
    currentStep,
    flowGenerationStatus,
    hasMarketQuoteFileLabel,
    quoteConfig,
  } = quoteFormProps;

  const { partialSubmission } = useSubmissionsWorkspace();
  const { showStatusChangeDialog } = useStatusChangeDialog();

  const navigateToStep = useNavigateToStep();

  const { quoteStatusUrl } = useWorkspaceUrls();

  const navigate = useNavigate();

  const { getValues, trigger, formState } = useFormContext();

  const [activeStep, setActiveStep] = useState(QuoteFormRoutes.Upload);

  const { filesExplorer } = useUiStoreState();

  const quoteFormSteps = quoteConfig.formSteps;

  const handleChangeStep = (step: QuoteFormRoutes, direction: 'next' | 'prev') => {
    navigateToStep(quoteFormSteps[quoteFormSteps.indexOf(step) + (direction === 'next' ? 1 : -1)], {
      routeKey: RouteKey.Quote,
    });
  };

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  const { submitForm, quoteSubmitting } = useSubmitQuoteForm({
    ...quoteFormProps,
  });

  const submitFormWrapper = async (generateFlowQuote = false) => {
    const quoteId = await submitForm(generateFlowQuote);
    if (quoteId) {
      showStatusChangeDialog({
        current: partialSubmission!.status,
        newStatus: SubmissionStatus.Quoted,
      });
      onClose();
    }
  };
  const submitStatusFiles = () => {
    navigate(quoteStatusUrl, {
      routeKey: RouteKey.QuoteStatus,
      state: {
        selectedQuote: selectedQuote!,
        uploadedFileIds: filesExplorer.selectedFiles.map((selectedFile) => selectedFile.id),
        policyDetails: {
          policyNumber: getValues(DetailsFieldsNames.PolicyNumber),
          // convert to string because we can't set a moment object in the location state
          effectiveDate: getValues(DetailsFieldsNames.PolicyEffectiveDate)?.toString(),
          expirationDate: getValues(DetailsFieldsNames.PolicyExpirationDate)?.toString(),
        },
        flowCommission: getValues(DetailsFieldsNames.FlowCommission)
          ? parseFloat(getValues(DetailsFieldsNames.FlowCommission))
          : undefined,
        fileType,
        binderSubjectivities: getValues(DetailsFieldsNames.BinderSubjectivities)?.map(
          (subjectivity: Subjectivity) => subjectivity.value,
        ),
        binderStateCompliance: getValues(DetailsFieldsNames.BinderStateCompliance),
        binderDate: getValues(DetailsFieldsNames.BinderDate)?.toString(),
      },
      replace: true,
    });
  };

  const withFormValidation = async (callback: () => void) => {
    const response = await trigger();
    if (response) {
      callback();
    } else {
      scrollToElement(getFirstErrorId(formState.errors));
    }
  };

  const noSelectedFiles = filesExplorer.selectedFiles.length === 0;
  const isFinalStep = activeStep === QuoteFormRoutes.FlowQuote;

  // if undefined button should be enabled
  const disabledButtonText = useMemo(() => {
    if (
      quoteSubmitting ||
      (activeStep === QuoteFormRoutes.FlowQuote &&
        [FlowGenerationStatus.Loading, FlowGenerationStatus.Error].includes(flowGenerationStatus))
    ) {
      return '';
    }
    if (noSelectedFiles) {
      return messages.addQuotePage.noFilesSelectedTooltip;
    }
    if (activeStep === QuoteFormRoutes.Details && !hasMarketQuoteFileLabel) {
      return messages.addQuotePage.missingMarketQuoteFileLabel;
    }
    return undefined;
  }, [activeStep, flowGenerationStatus, hasMarketQuoteFileLabel, noSelectedFiles, quoteSubmitting]);

  const getQuoteSubmitButtonText = () => {
    if (isFinalStep) {
      return messages.addQuotePage.buttons.saveFlowQuote;
    }
    const nextStep = quoteFormSteps[quoteFormSteps.indexOf(activeStep) + 1];
    switch (nextStep) {
      case QuoteFormRoutes.Insights:
        return messages.addQuotePage.buttons.nextCreateInsights;
      case QuoteFormRoutes.FlowQuote:
        return messages.addQuotePage.buttons.nextFlowQuote;
      default:
        return '';
    }
  };

  return (
    <Stack direction="row" gap={1} justifyContent="center">
      <Box flex={1} />
      <Stack flex={1} alignItems="center">
        <FooterStepper
          handleChangeStep={handleChangeStep}
          activeStep={activeStep}
          mode={mode}
          quoteConfig={quoteConfig}
        />
      </Stack>
      <Stack flex={1} direction="row" gap={1} justifyContent="flex-end" alignItems="center">
        {/* Binder mode */}
        {fileType === QuoteFileIdType.Binder && mode !== FormMode.view && (
          <Button
            variant="contained"
            loading={quoteSubmitting}
            onClick={() => withFormValidation(submitStatusFiles)}
            disabled={
              filesExplorer.selectedFiles.length === 0 ||
              isEqual(filesExplorer.selectedFiles, filesExplorer.preSelectedFileIds)
            }
            disabledTooltipContent={
              isEqual(filesExplorer.selectedFiles, filesExplorer.preSelectedFileIds)
                ? messages.addQuotePage.selectDifferentFiles
                : messages.addQuotePage.noFilesSelectedTooltip
            }
          >
            {messages.addQuotePage.buttons.compareBinderToQuote}
          </Button>
        )}
        {/* Policy mode */}
        {fileType === QuoteFileIdType.Policy && mode !== FormMode.view && (
          <Button
            disabledTooltipContent={
              isEqual(filesExplorer.selectedFiles, filesExplorer.preSelectedFileIds)
                ? messages.addQuotePage.selectDifferentFiles
                : messages.addQuotePage.noFilesSelectedTooltip
            }
            variant="contained"
            loading={quoteSubmitting}
            onClick={() => withFormValidation(submitStatusFiles)}
            disabled={
              filesExplorer.selectedFiles.length === 0 ||
              isEqual(filesExplorer.selectedFiles, filesExplorer.preSelectedFileIds)
            }
          >
            {messages.addQuotePage.buttons.policy}
          </Button>
        )}
        {/* Quote mode */}
        {fileType === QuoteFileIdType.MarketQuote && mode !== FormMode.view && (
          <Stack direction="row" gap={1}>
            {!isFinalStep && (
              <Button
                variant="outlined"
                // generate flow quote in save for later only if there was flow quote files before
                onClick={() => submitFormWrapper(flowQuoteIds.length > 0)}
                loading={quoteSubmitting}
                disabled={disabledButtonText !== undefined}
                disabledTooltipContent={disabledButtonText}
              >
                {messages.buttons.saveForLater}
              </Button>
            )}
            <Button
              variant="contained"
              loading={isFinalStep && quoteSubmitting}
              disabled={disabledButtonText !== undefined}
              disabledTooltipContent={disabledButtonText}
              onClick={async () => {
                switch (activeStep) {
                  case QuoteFormRoutes.FlowQuote:
                    submitFormWrapper();
                    break;
                  case QuoteFormRoutes.Details: {
                    await withFormValidation(() => handleChangeStep(activeStep, 'next'));
                    break;
                  }
                  default:
                    handleChangeStep(activeStep, 'next');
                    break;
                }
              }}
            >
              {getQuoteSubmitButtonText()}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
