import { StateCompliance } from '@common/types';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormMode, UserRole } from 'enums';
import { PartialSubmission, Quote, SelectProduct } from 'types';
import { htmlToPlain, logger } from 'utils';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

interface AutoInsertStateComplianceProps {
  selectedQuote?: Quote;
  stateCompliance?: StateCompliance;
  mode: FormMode;
  isStateComplianceShown: boolean;
  selectedProduct?: SelectProduct;
  submission: PartialSubmission;
}

/*
  This hook is used to auto insert the state compliance value when:
  1. the user enters the quote form for the first time for a non-admitted product
  2. the selected product changes from an admitted to a non-admitted product

  In addition, it cleans the state compliance value when the field is not shown anymore.
*/
export function useAutoInsertStateCompliance({
  selectedQuote,
  stateCompliance,
  mode,
  isStateComplianceShown,
  selectedProduct,
  submission,
}: AutoInsertStateComplianceProps) {
  const lastSelectedProduct = useRef(selectedProduct);
  const { watch, setValue } = useFormContext();
  const stateComplianceValue = watch(detailsFormFieldsConfig[DetailsFieldsNames.StateCompliance].name);
  const checkedAutoInsertForFirstEditOnce = useRef(false);
  const quoteLastUpdatedByMachine = selectedQuote?.updatedByUserDetails?.role === UserRole.Machine;

  const isNonAdmittedProduct = selectedProduct?.isAdmitted === false;

  const shouldAutoInsertForFirstEdit =
    isNonAdmittedProduct &&
    (mode === FormMode.create || (mode === FormMode.edit && quoteLastUpdatedByMachine)) &&
    !!stateCompliance?.quoteLetter;

  useEffect(
    () => {
      logger.log('info', {
        message: `should auto insert state compliance in submission ${submission.id} for quote ${selectedQuote?.id}`,
        wasAutoInserted:
          shouldAutoInsertForFirstEdit &&
          (!stateComplianceValue || !htmlToPlain(stateComplianceValue).trim()) &&
          !checkedAutoInsertForFirstEditOnce.current,
        shouldAutoInsertForFirstEdit,
        checkedAutoInsertForFirstEditOnce: checkedAutoInsertForFirstEditOnce.current,
        stateComplianceValueTrimmed: htmlToPlain(stateComplianceValue).trim(),
        stateComplianceQuoteLetter: stateCompliance?.quoteLetter,
        stateComplianceValueRaw: stateComplianceValue,
        stateComplianceValueJson: JSON.stringify(stateComplianceValue),
        isNonAdmittedProduct,
        mode,
        quoteLastUpdatedByMachine,
        selectedProductId: selectedProduct?.id,
      });
    },
    // don't want to add stateComplianceValue to the dependencies because i don't want to trigger the log every time the user types in the field
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isNonAdmittedProduct,
      mode,
      selectedProduct?.id,
      quoteLastUpdatedByMachine,
      selectedQuote?.id,
      shouldAutoInsertForFirstEdit,
      stateCompliance?.quoteLetter,
      submission.id,
    ],
  );

  // auto insert state compliance value for the first edit only one time for non admitted product
  useEffect(() => {
    if (shouldAutoInsertForFirstEdit) {
      if (
        (!stateComplianceValue || !htmlToPlain(stateComplianceValue).trim()) &&
        !checkedAutoInsertForFirstEditOnce.current
      ) {
        setValue(detailsFormFieldsConfig[DetailsFieldsNames.StateCompliance].name, stateCompliance?.quoteLetter);
      }
      checkedAutoInsertForFirstEditOnce.current = true;
    }
  }, [stateComplianceValue, setValue, shouldAutoInsertForFirstEdit, stateCompliance?.quoteLetter]);

  // auto insert state compliance value when the product changes from an admitted to a non-admitted product
  useEffect(() => {
    if (selectedProduct && lastSelectedProduct.current && selectedProduct.id !== lastSelectedProduct.current.id) {
      const isLastSelectedProductAdmitted = lastSelectedProduct.current.isAdmitted !== false;
      if (isLastSelectedProductAdmitted && isNonAdmittedProduct) {
        setValue(detailsFormFieldsConfig[DetailsFieldsNames.StateCompliance].name, stateCompliance?.quoteLetter);
      }
    }

    if (selectedProduct?.id !== lastSelectedProduct.current?.id) {
      lastSelectedProduct.current = selectedProduct;
    }
  }, [selectedProduct, isNonAdmittedProduct, stateComplianceValue, setValue, stateCompliance?.quoteLetter]);

  // if state compliance is not shown anymore, and it had a value, clear it
  useEffect(() => {
    if (!isStateComplianceShown && stateComplianceValue) {
      setValue(detailsFormFieldsConfig[DetailsFieldsNames.StateCompliance].name, '');
      // reset the flag to allow auto-inserting the state compliance value again if the field is shown again in the future
      checkedAutoInsertForFirstEditOnce.current = false;
    }
  }, [isStateComplianceShown, stateComplianceValue, setValue]);
}
