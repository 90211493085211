import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Divider, Stack, Typography } from '@common-components';
import { FormMode } from 'enums';
import { messages } from 'i18n';
import { Suggestion } from 'types';
import { getTimezoneLessDisplayDate, getTodayDisplayDate } from 'utils';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormEditor from 'components/hookFormComponents/FormEditor';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { CommissionTextField } from './CommissionTextField';
import SubjectivitiesArrayFields from './SubjectivitiesArray';
import { useSetPolicyExpirationDefaultValue } from './useSetPolicyExpirationDefaultValue';

const defaultBinderSubjectivitiesArray = [{ value: '' }];

interface BinderDetailsProps {
  mode: FormMode;
  extractedDataSuggestions?: Record<string, Suggestion>;
  isStateComplianceShown?: boolean;
}
export default function BinderDetails({
  mode,
  isStateComplianceShown,
  extractedDataSuggestions = {},
}: BinderDetailsProps) {
  const isDisabled = mode === FormMode.view;
  const isCreateMode = mode === FormMode.create;
  const { formState, watch } = useFormContext();
  const binderDate: Date | undefined = watch(detailsFormFieldsConfig[DetailsFieldsNames.BinderDate].id);
  const today = getTodayDisplayDate();

  useSetPolicyExpirationDefaultValue();

  const defaultBinderSubjectivities = !isEmpty(
    formState.defaultValues?.[detailsFormFieldsConfig[DetailsFieldsNames.BinderSubjectivities].name],
  )
    ? formState.defaultValues?.[detailsFormFieldsConfig[DetailsFieldsNames.BinderSubjectivities].name]
    : defaultBinderSubjectivitiesArray;

  const defaultSubjectivitiesValue = useMemo(
    () => (isCreateMode ? defaultBinderSubjectivities : defaultBinderSubjectivitiesArray),
    [defaultBinderSubjectivities, isCreateMode],
  );

  return (
    <Stack gap={1}>
      <FormTextField
        disabled={isDisabled}
        fullWidth
        {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyNumber]}
        optional
      />
      <Stack direction="row" gap={2}>
        <FormDatePicker
          optional
          disabled={isDisabled}
          fullWidth
          defaultValueOverlayText={binderDate && getTimezoneLessDisplayDate(binderDate) === today ? today : undefined}
          {...detailsFormFieldsConfig[DetailsFieldsNames.BinderDate]}
        />
        <CommissionTextField isDisabled={isDisabled} />
      </Stack>
      <Stack direction="row" gap={2}>
        <FormDatePicker
          disabled={isDisabled}
          fullWidth
          {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyEffectiveDate]}
          label={messages.addQuotePage.binder.policyEffectiveDate}
        />
        <FormDatePicker
          disabled={isDisabled}
          fullWidth
          {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyExpirationDate]}
          label={messages.addQuotePage.binder.policyExpirationDate}
          suggestion={extractedDataSuggestions[DetailsFieldsNames.PolicyExpirationDate]}
        />
      </Stack>
      <Divider orientation="horizontal" sx={{ mb: 3 }} flexItem variant="fullWidth" />
      <Stack>
        <Typography variant="h6" my={1}>
          {messages.addQuotePage.fieldLabels.binderSubjectivities}
        </Typography>
        <FormArrayFields
          key={detailsFormFieldsConfig[DetailsFieldsNames.BinderSubjectivities].name}
          name={detailsFormFieldsConfig[DetailsFieldsNames.BinderSubjectivities].name}
          FormArrayFieldComponent={SubjectivitiesArrayFields}
          disabled={isDisabled}
          defaultValue={defaultSubjectivitiesValue}
          deleteButtonMargin={0}
        />
        {isStateComplianceShown && (
          <Box position="relative" height={300}>
            <FormEditor
              optional
              disabled={isDisabled}
              {...(isCreateMode && { defaultValue: formState.defaultValues?.[DetailsFieldsNames.StateCompliance] })}
              {...detailsFormFieldsConfig[DetailsFieldsNames.BinderStateCompliance]}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
