export enum ActivityType {
    RequestForQuote = 'RequestForQuote',
    InformationRequested = 'InformationRequested',
    AddedManually = 'AddedManually',
    AddedAsIncumbent = 'AddedAsIncumbent',
    MarketDeclined = 'MarketDeclined',
    QuoteNotAccepted = 'QuoteNotAccepted',
    QuoteAttached = 'QuoteAttached',
    BinderAttached = 'BinderAttached',
    PolicyIssued = 'PolicyIssued',
    QuoteRemoved = 'QuoteRemoved',
    QuoteUpdated = 'QuoteUpdated',
    BinderRemoved = 'BinderRemoved',
    BinderUpdated = 'BinderUpdated',
    PolicyRemoved = 'PolicyRemoved',
    PolicyUpdated = 'PolicyUpdated',
    BinderCorrection = 'BinderCorrection',
    PolicyCorrection = 'PolicyCorrection',
    BindRequest = 'BindRequest',
    PolicyRequested = 'PolicyRequested',
    MarketEmailSent = 'MarketEmailSent',
    FilesUploaded = 'FilesUploaded',
    CustomerEmailSent = 'CustomerEmailSent',
    ProposalSent = 'ProposalSent',
    ConceptualTowerCopiedToClipboard = 'ConceptualTowerCopiedToClipboard',
    ConceptualTowerDownloaded = 'ConceptualTowerDownloaded',
    ConceptualTowerSent = 'ConceptualTowerSent',
    ExpiringTowerCopiedToClipboard = 'ExpiringTowerCopiedToClipboard',
    MarketingReportCopiedToClipboard = 'MarketingReportCopiedToClipboard',
    MarketingReportDownloaded = 'MarketingReportDownloaded',
    MarketingReportSent = 'MarketingReportSent',
    InformationRequestCopiedToClipboard = 'InformationRequestCopiedToClipboard',
    InformationRequestSent = 'InformationRequestSent',
    InformationRequestDownloaded = 'InformationRequestDownloaded',
    SubmissionCreated = 'SubmissionCreated',
    SubmissionUpdated = 'SubmissionUpdated',
    ProposalUpdated = 'ProposalUpdated',
    IncumbentInfoEdited = 'IncumbentInfoEdited',
    CustomerEmailReceived = 'CustomerEmailReceived',
    ArfRequest = 'ArfRequest',
    ArfSent = 'ArfSent',
    MarketEmailReceived = 'MarketEmailReceived',
    CopilotEscalationEmailSent = 'CopilotEscalationEmailSent',
    SubmissionCopilotEnabled = 'SubmissionCopilotEnabled',
    SubmissionCopilotDisabled = 'SubmissionCopilotDisabled',
    TaskStatusChanged = 'TaskStatusChanged',
    SubmissionStatusUpdated = 'SubmissionStatusUpdated',
    QuoteIndication = 'QuoteIndication',
}
